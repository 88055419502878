/* eslint-disable no-nested-ternary */
import React from 'react';

const AvailabilityButtons = ({ data, setData, i18n, availabilityOptions, disabledIndices }) => {
  const buttonStyle = {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    padding: '7px 8px',
    border: 'none',
    height: '44px',
    flex: '1',
    cursor: 'pointer',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    minHeight: '44px',
    transition: 'background-color 0.2s, color 0.2s',
  };

  const buttons = [
    {
      text: i18n.t('itemsAvailable'),
      value: availabilityOptions.all,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
    {
      text: 'Terminal',
      value: availabilityOptions.terminal,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
    {
      text: 'QR',
      value: availabilityOptions.qr,
      activeColor: '#e6e3fa',
      activeTextColor: '#7268cd',
      inactiveTextColor: '#7268cdc2',
    },
    {
      text: i18n.t('itemsUnavailable'),
      value: availabilityOptions.not,
      activeColor: '#f7c0bf',
      activeTextColor: '#db2828',
      inactiveTextColor: '#7268cdc2',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid #d7d3f6',
        borderRadius: '10px',
        overflow: 'hidden',
        marginBottom: '8px',
        width: '100%',
      }}
    >
      {buttons.map((button, index) => (
        <button
          disabled={disabledIndices.includes(index)}
          type="button"
          key={button.value}
          style={{
            ...buttonStyle,
            borderRight: index < buttons.length - 1 ? '1px solid #d7d3f6' : 'none',
            backgroundColor: disabledIndices.includes(index)
              ? '#eee'
              : data.item.availability === button.value
              ? button.activeColor
              : 'white',
            fontWeight: 700,
            color: disabledIndices.includes(index)
              ? '#aaa'
              : data.item.availability === button.value
              ? button.activeTextColor
              : button.inactiveTextColor,
          }}
          onClick={() => {
            if (disabledIndices.includes(index) === false) {
              setData({
                item: { ...data.item, availability: button.value },
              });
            }
          }}
        >
          {button.text}
        </button>
      ))}
    </div>
  );
};

export default AvailabilityButtons;
