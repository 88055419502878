/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Accordion, Dropdown, Loader } from 'semantic-ui-react';
import i18n from '../../../i18n/config';
import { useStateValue } from '../../../core/context/StateProvider';
import { getShops } from '../../../api/FranchiseAPI';
import './FranchiseComparisonScreen.css';
import { asCurrency } from '../../../utils/NumberFormatter';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const FranchiseComparisonScreen = (props) => {
  const [{ token, user }] = useStateValue();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState([]);
  const [shops, setShops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  useEffect(() => {
    if (dropdownOptions.length === 0) {
      getFranchiseShops();
    }
  }, []);

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const getFranchiseShops = async () => {
    try {
      setIsLoading(true);
      const res = await getShops(token);
      if (res && res.success) {
        const shops = res.shops || [];
        setShops(shops);
        setDropdownOptions([
          ...shops.map((shop) => ({ key: shop._id, text: shop.name, value: shop._id })),
        ]);
        setIsLoading(false);
      } else {
        setErrorWarningModalOptions({ open: true, message: i18n.t('franchiseShopsError') });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('franchiseShopsError') });
    }
  };

  const parseShop = (shopId) => {
    const shop = shops.find((shop) => shop._id === shopId);
    if (shop) {
      return (
        <>
          <div
            style={{
              width: '100%',
              minHeight: 60,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              alignItems: 'center',
              fontSize: 17,
              fontWeight: 700,
              borderRadius: 14,
              boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
            }}
          >
            <p>{shop.name}</p>
          </div>
          <div
            style={{
              flex: 1,
              overflowY: 'scroll',
              padding: 2,
              overscrollBehavior: 'auto',
              paddingBottom: 200,
              height: matches ? 'unset' : 'calc(-10px + 100vh)',
            }}
            className="no-scrollbar"
          >
            <div style={{ padding: 4, height: 1 }} />
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                defaultActiveIndex={0}
                panels={[
                  {
                    key: 'general-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                          {i18n.t('general')}
                        </p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('shopAddressTitle')}</p>
                            <div style={{ textAlign: 'right' }}>
                              <p className="accordion-value" style={{ marginBottom: 4 }}>
                                {shop.address.street}
                              </p>
                              <p className="accordion-value">
                                {`${shop.address.zip} ${shop.address.city}`}
                              </p>
                            </div>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('bankAccount')}</p>
                            <div style={{ textAlign: 'right' }}>
                              <p className="accordion-value" style={{ marginBottom: 4 }}>
                                {shop.bankAccount.name}
                              </p>
                              <p className="accordion-value">{shop.bankAccount.iban}</p>
                            </div>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('pricing')}</p>
                            <div style={{ textAlign: 'right' }}>
                              <p className="accordion-value" style={{ marginBottom: 4 }}>
                                {`${i18n.t('yearly')} ${asCurrency(shop.pricing.yearly / 100)}€`}
                              </p>
                              <p className="accordion-value">
                                {`${i18n.t('monthly')} ${asCurrency(shop.pricing.monthly / 100)}€`}
                              </p>
                            </div>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">SEPA</p>
                            <p className="accordion-value">
                              {shop.hasSepa
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('vatNo')}</p>
                            <p className="accordion-value">{shop.taxId}</p>
                          </div>
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                panels={[
                  {
                    key: 'owner-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                          {i18n.t('owner')}
                        </p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('owner')}</p>
                            <p className="accordion-value">{shop.shopOwner.name}</p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('ownerMail')}</p>
                            <p className="accordion-value">{shop.shopOwner.email}</p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('ownerPhone')}</p>
                            <p className="accordion-value">{shop.shopOwner.phoneNumber}</p>
                          </div>
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                panels={[
                  {
                    key: 'settings-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                          {i18n.t('settingsHeading')}
                        </p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('dailySummaryCashBook')}</p>
                            <p className="accordion-value">
                              {shop.allowCashBook
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('pinLock')}</p>
                            <p className="accordion-value">
                              {shop.isPinRequired
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('tips')}</p>
                            <p className="accordion-value">{i18n.t(shop.tipOption)}</p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">In-House</p>
                            <p className="accordion-value">
                              {shop.isInHouseTableAllowed
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">Take-Away</p>
                            <p className="accordion-value">
                              {shop.isTakeAwayTableAllowed
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('diverseProducts')}</p>
                            <p className="accordion-value">
                              {shop.allowDiverseProducts
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('discounting')}</p>
                            <p className="accordion-value">
                              {shop.allowDiscounts
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                panels={[
                  {
                    key: 'printer-scanner-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                          {i18n.t('printerAndScanner')}
                        </p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('printersTitle')}</p>
                            <p className="accordion-value">{shop.printers}</p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('printerPrintPerCategory')}</p>
                            <p className="accordion-value">
                              {shop.printPerCategory
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('printerAutoPrint')}</p>
                            <p className="accordion-value">
                              {shop.autoPrint
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('kBonAutoPrint')}</p>
                            <p className="accordion-value">
                              {shop.autoPrintCustomer
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">Barcode Scanner</p>
                            <p className="accordion-value">
                              {shop.hasBarcodeScanner
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                panels={[
                  {
                    key: 'employeesTitle-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                          {i18n.t('employeesTitle')}
                        </p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          {shop.employees.map((employee, index) => (
                            <Fragment key={employee.name + index}>
                              <div className="accordion-sub-container">
                                <p className="accordion-key">{i18n.t('newEmployeeRole')}</p>
                                <p className="accordion-value">
                                  {employee.role === 'manager'
                                    ? i18n.t('newManagerDropDown')
                                    : employee.role === 'owner'
                                    ? i18n.t('owner')
                                    : employee.role === 'employee'
                                    ? i18n.t('employee')
                                    : employee.role === 'tax'
                                    ? i18n.t('newTaxDropDown')
                                    : ''}
                                </p>
                              </div>
                              <div className="accordion-sub-container">
                                <p className="accordion-key">{i18n.t('ownerName')}</p>
                                <p className="accordion-value">{employee.name}</p>
                              </div>
                              <div className="accordion-sub-container">
                                <p className="accordion-key">{i18n.t('newEmployeeMail')}</p>
                                <p className="accordion-value">{employee.email}</p>
                              </div>
                              {index !== shop.employees.length - 1 ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <div
                                    style={{
                                      height: 1,
                                      width: '90%',
                                      borderBottom: '1px solid #eeecfc',
                                      marginBottom: 10,
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          ))}
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
            <div
              style={{
                borderRadius: 14,
                width: '100%',
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
              }}
            >
              <Accordion
                panels={[
                  {
                    key: 'qr-app-panel',
                    title: {
                      content: (
                        <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>QR-App</p>
                      ),
                    },
                    content: {
                      content: (
                        <>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('paymentCreditCard')}</p>
                            <p className="accordion-value">
                              {shop.allowedPaymentMethods.card
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">{i18n.t('paymentCashPayment')}</p>
                            <p className="accordion-value">
                              {shop.allowedPaymentMethods.cash
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                          <div className="accordion-sub-container">
                            <p className="accordion-key">Dark Mode</p>
                            <p className="accordion-value">
                              {shop.darkMode
                                ? i18n.t('bookingEditBookingWarningYes')
                                : i18n.t('bookingEditBookingWarningNo')}
                            </p>
                          </div>
                        </>
                      ),
                    },
                  },
                ]}
              />
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <div
        className="cockpit-title"
        style={{ position: matches ? '' : 'absolute', marginTop: matches ? 0 : 11 }}
      >
        <p className="cockpit-title">{i18n.t('comparisonTitle')}</p>
        <p className="cockpit-subtitle">{i18n.t('comparisonSubTitle')}</p>
      </div>
      <div style={{ display: 'flex', marginTop: matches ? 0 : 16 }}>
        {isLoading ? (
          <div
            style={{
              margin: `${matches ? '22px' : '38px'} 44px 0px 48px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
              width: '100%',
              flexDirection: 'column',
              gap: 12,
            }}
          >
            <Loader
              active={isLoading}
              inline
              inverted
              className="bookings-create-loader"
              size="huge"
            />
            <p>{i18n.t('loadingShops')}</p>
          </div>
        ) : (
          <div
            className="no-scrollbar"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              margin: `${matches ? '22px' : '38px'} 44px 0px ${matches ? '48' : '18'}px`,
              overflowX: 'scroll',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 16,
                width: '100%',
              }}
            >
              {selectedDropdownOptions.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                    height: 'calc(100vh - 110px)',
                    minWidth: 'fit-content',
                  }}
                >
                  {selectedDropdownOptions.map((option) => (
                    <div
                      key={option.value}
                      style={{
                        height: '100%',
                        minWidth: 300,
                        maxWidth: 300,
                        padding: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'scroll',
                        gap: 2,
                      }}
                      className="no-scrollbar"
                    >
                      {parseShop(option)}
                    </div>
                  ))}
                </div>
              ) : null}
              {dropdownOptions.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    gap: 16,
                    flexShrink: 0,
                    minWidth: 'fit-content',
                    height: matches ? 'calc(100vh - 190px)' : 'calc(100vh - 140px)',
                  }}
                >
                  {dropdownOptions.map((option) => (
                    <div
                      key={option.value}
                      style={{
                        border: '2px dashed #d8d5ef',
                        minWidth: 300,
                        maxWidth: 300,
                        borderRadius: 14,
                        padding: 14,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        gap: 16,
                      }}
                    >
                      <p style={{ fontWeight: 500, color: '#a9a5c6' }}>
                        {i18n.t('chooseShopCompare')}
                      </p>
                      <Dropdown
                        text={i18n.t('allShopsDropdown')}
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: '#a5a0c9',
                        }}
                        onChange={(_, data) => {
                          if (data.value) {
                            setSelectedDropdownOptions([...selectedDropdownOptions, data.value]);
                            setDropdownOptions(
                              dropdownOptions.filter((option) => option.value !== data.value)
                            );
                          }
                        }}
                        className="franchise-dropdown"
                        options={dropdownOptions}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
    </>
  );
};

export default FranchiseComparisonScreen;
