/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown, Grid, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from '@react-hook/media-query';
import moment from 'moment';
import { Doughnut, HorizontalBar, Line } from 'react-chartjs-2';
import i18n from '../../../i18n/config';
import AnimatedTabs from '../../../components/general/AnimatedTabs/AnimatedTabs';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import { useStateValue } from '../../../core/context/StateProvider';
import refreshIcon from '../../shop/Bookings/icons/refresh.png';
import { getZBons } from '../../../api/BookingAPI';
import ZBonScreenModal from '../../shop/Bookings/ZBonScreenModal';
import { asCurrency } from '../../../utils/NumberFormatter';
import {
  centerTextPlugin,
  doughnutChartData,
  doughnutChartOptions,
  horizontalBarChartData,
  horizontalBarChartOptions,
  lineChartData,
  lineChartOptions,
} from './DailyClosingScreenData';

const DailyClosingScreen = () => {
  const [{ token, shop }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [zBons, setZBons] = useState({ data: [] });
  const [centerText, setCenterText] = useState({});
  const [zBonModalOptions, setZBonModalOptions] = useState({ open: false, zBon: null });
  const tabs = { zbons: 'zbons', statistics: 'statistics' };
  const [lineChart, setLineChart] = useState({});
  const [horizontalChart, setHorizontalChart] = useState({});
  const [doughnutChart, setDoughnutChart] = useState({});
  const [selectedTab, setSelectedTab] = useState(tabs.zbons);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [selectedTitleDropdownOption, setSelectedTitleDropdownOption] = useState(
    i18n.t('closingZBons')
  );
  const [rotations, setRotations] = useState(0);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const titleDropdownOptions = [
    { key: i18n.t('closingZBons'), value: 'zbons', text: i18n.t('closingZBons') },
    {
      key: i18n.t('closingStatistics'),
      value: 'statistics',
      text: i18n.t('closingStatistics'),
    },
  ];

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onCloseZBonModal = () => {
    setZBonModalOptions({ open: false, date: null });
  };

  useEffect(() => {
    loadZBons(moment());
  }, []);

  const isEmpty = (value) =>
    Boolean(value && typeof value === 'object') && !Object.keys(value).length;

  const loadZBons = async (date) => {
    try {
      setIsLoading(true);
      setSelectedMonth(moment(date));
      const res = await getZBons(token, shop._id, moment(date).format());
      setIsLoading(false);
      if (res && res.success) {
        setZBons({ data: res.zBons });
        const array = Array.from({ length: 31 }).fill(0);
        res.zBons.forEach((zBon) => {
          const day = Number(moment(zBon.issueDateStart).format('DD'));
          array[day - 1] = zBon.total;
        });
        setLineChart({
          labels: Array.from({ length: 31 }, (_, i) => i + 1),
          data: array,
        });
        const dataSet = [
          res.zBons.reduce((sum, { cash }) => sum + cash, 0),
          res.zBons.reduce((sum, { card }) => sum + card, 0),
        ];
        setHorizontalChart({
          topValue: Math.max(...dataSet),
          dataSet,
          labels: [i18n.t('dailySummaryBar'), i18n.t('dailySummaryKarte')],
        });
        const doughnutData = [
          res.zBons.reduce((sum, { reducedTaxesTotal }) => sum + reducedTaxesTotal, 0) / 100,
          res.zBons.reduce((sum, { normalTaxesTotal }) => sum + normalTaxesTotal, 0) / 100,
        ];
        setDoughnutChart({
          labels: ['7%', '19%'],
          data: doughnutData,
        });
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('zBonsError'),
        });
        setHorizontalChart(null);
        setLineChart(null);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('zBonsError') });
    }
  };

  const parseZBonsTab = () => (
    <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginTop: matches ? 0 : -16,
          }}
        >
          <div className="bookings-items-toolbar">
            <img
              src={refreshIcon}
              alt=""
              style={{
                cursor: 'pointer',
                width: 30,
                height: 30,
                marginTop: -5,
                transform: `rotate(${rotations * 360 * -1}deg)`,
                transition: 'transform 0.5s ease',
              }}
              onClick={async () => {
                setRotations((prev) => prev + 1);
                await loadZBons();
              }}
            />
          </div>
        </div>
        <div
          className="bookings-items-container"
          style={{ display: 'flex', flexDirection: 'column', marginTop: matches ? 0 : 30 }}
        >
          <div
            className="bookings-header-container"
            style={{
              justifyContent: 'space-between',
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <p style={{ fontWeight: 700, fontSize: 20, marginBottom: 0 }}>{shop.name}</p>
            <Popup
              trigger={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <p style={{ color: '#7268cd', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>
                  {moment(selectedMonth).format('MMM YYYY').toUpperCase().replace('.', '')}
                  <Icon name="caret down" />
                </p>
              }
              style={{ height: 220 }}
              on="click"
              position="bottom left"
            >
              <Grid centered columns={1}>
                <Grid.Column textAlign="center">
                  <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                  <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                    <ReactDatePicker
                      inline
                      locale="de"
                      container
                      wrapperClassName="datepicker"
                      showMonthYearPicker
                      selected={moment(selectedMonth).toDate()}
                      minDate={moment(shop && shop.created ? shop.created : null)
                        .startOf('month')
                        .toDate()}
                      maxDate={moment().toDate()}
                      onChange={async (selectedDate) => {
                        setSelectedMonth(moment(selectedDate));
                        await loadZBons(moment(selectedDate));
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </Popup>
          </div>
          <div className="bookings-table-container">
            <Table fixed celled selectable padded sortable className="bookings-table">
              {matches === true ? (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      width="2"
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'center' : 'left'}
                    >
                      {i18n.t('zBonNr')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width="2" style={{ backgroundColor: 'white' }}>
                      {i18n.t('dailySummaryDate')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                      {i18n.t('time')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'right' : 'left'}
                    >
                      {i18n.t('revenue')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width="2"
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'right' : 'left'}
                    >
                      {i18n.t('tax')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              ) : (
                <></>
              )}
              <Table.Body>
                {parseZBonsTableBody()}
                {isLoading ? (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <Loader
                          active={isLoading === true}
                          size="large"
                          style={{ marginTop: -100 }}
                          className="bookings-create-loader"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ) : (
                  <></>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  const parseZBonsTableBody = () =>
    zBons.data.map((zBon) => (
      <Fragment key={`${zBon.issueDateStart}div`}>
        <Table.Row
          onClick={() => {
            setZBonModalOptions({ open: true, zBon });
          }}
        >
          <Table.Cell
            style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}
            textAlign={matches ? 'center' : 'left'}
          >
            {zBon.number}
          </Table.Cell>
          <Table.Cell style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}>
            {`${moment(zBon.issueDateStart).format('DD.MM.YYYY')}`}
          </Table.Cell>
          <Table.Cell style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}>
            {`${moment(zBon.issueDateStart).format('HH:mm')} - ${moment(zBon.issueDateEnd).format(
              'HH:mm [Uhr]'
            )}`}
          </Table.Cell>
          <Table.Cell
            textAlign={matches ? 'right' : 'left'}
            style={{ borderLeft: 'none', fontSize: 16, fontWeight: 700 }}
          >
            {`${asCurrency(zBon.total / 100)}€`}
          </Table.Cell>
          <Table.Cell
            style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}
            textAlign={matches ? 'right' : 'left'}
          >
            {`${asCurrency(zBon.taxes / 100)}€`}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const parseStatisticsTab = () => (
    <>
      <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginTop: matches ? 0 : -16,
            }}
          >
            <div className="bookings-items-toolbar">
              <img
                src={refreshIcon}
                alt=""
                style={{
                  cursor: 'pointer',
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  transform: `rotate(${rotations * 360 * -1}deg)`,
                  transition: 'transform 0.5s ease',
                }}
                onClick={async () => {
                  setRotations((prev) => prev + 1);
                  await loadZBons();
                }}
              />
            </div>
          </div>
          <div
            className="bookings-container-grid"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
              height: 'unset',
            }}
          >
            <div
              className="bookings-header-container"
              style={{
                justifyContent: 'space-between',
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 700, fontSize: 20, marginBottom: 0 }}>{shop.name}</p>
              <Popup
                trigger={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <p style={{ color: '#7268cd', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>
                    {moment(selectedMonth).format('MMM YYYY').toUpperCase().replace('.', '')}
                    <Icon name="caret down" />
                  </p>
                }
                style={{ height: 220 }}
                on="click"
                position="bottom left"
              >
                <Grid centered columns={1}>
                  <Grid.Column textAlign="center">
                    <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                    <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                      <ReactDatePicker
                        inline
                        locale="de"
                        container
                        wrapperClassName="datepicker"
                        showMonthYearPicker
                        selected={moment(selectedMonth).toDate()}
                        minDate={moment(shop && shop.created ? shop.created : null)
                          .startOf('month')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={async (selectedDate) => {
                          setSelectedMonth(moment(selectedDate));
                          await loadZBons(moment(selectedDate));
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Popup>
            </div>
          </div>
        </div>
      </div>
      <div className="bookings-grid-items-owner" style={{ height: 'calc(100vh - 175px)' }}>
        <Grid stackable columns={3} stretched>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 260,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('mwst')}</p>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 150,
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <>
                  {zBons.data != null ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          gap: 10,
                          alignItems: 'center',
                          marginBottom: 20,
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            7%
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(
                              zBons.data.reduce(
                                (sum, { reducedTaxesTotal }) => sum + reducedTaxesTotal,
                                0
                              ) / 100
                            )}€`}
                          </p>
                        </div>
                        <div style={{ height: 20, width: 1, backgroundColor: '#7268cd' }} />
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            19%
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(
                              zBons.data.reduce(
                                (sum, { normalTaxesTotal }) => sum + normalTaxesTotal,
                                0
                              ) / 100
                            )}€`}
                          </p>
                        </div>
                      </div>
                      <Doughnut
                        data={(cnv) => doughnutChartData(doughnutChart, cnv)}
                        height={130}
                        options={{
                          ...doughnutChartOptions,
                          centerText: `${asCurrency(
                            zBons.data.reduce((sum, { taxes }) => sum + taxes, 0) / 100
                          )}€`,
                        }}
                        plugins={[centerTextPlugin]}
                      />
                    </>
                  ) : (
                    <>
                      <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 500 }}>
                        {i18n.t('dashBoardTopTenItemsNoData')}
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 260,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('revenuePerDay')}</p>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 150,
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <>
                  {isEmpty(lineChart) || lineChart == null ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          gap: 10,
                          alignItems: 'center',
                          marginBottom: 20,
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            {i18n.t('lowest').toUpperCase()}
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(
                              Math.min(...zBons.data.map((zBon) => zBon.total), 0) / 100
                            )}€`}
                          </p>
                        </div>
                        <div style={{ height: 20, width: 1, backgroundColor: '#7268cd' }} />
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            {i18n.t('highest').toUpperCase()}
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(
                              Math.max(...zBons.data.map((zBon) => zBon.total), 0) / 100
                            )}€`}
                          </p>
                        </div>
                      </div>
                      <Line
                        data={(cnv) => lineChartData(lineChart, cnv)}
                        options={lineChartOptions(lineChart.data)}
                        height={130}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 260,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('cashVsCard')}</p>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 150,
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <>
                  {isEmpty(horizontalChart) || horizontalChart == null ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          gap: 10,
                          alignItems: 'center',
                          marginBottom: 20,
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            {i18n.t('dailySummaryBar').toUpperCase()}
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(horizontalChart.dataSet[0] / 100)}€`}
                          </p>
                        </div>
                        <div style={{ height: 20, width: 1, backgroundColor: '#7268cd' }} />
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#7268cd',
                              textAlign: 'center',
                              marginBottom: 4,
                            }}
                          >
                            {i18n.t('dailySummaryKarte').toUpperCase()}
                          </p>
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                            }}
                          >
                            {`${asCurrency(horizontalChart.dataSet[1] / 100)}€`}
                          </p>
                        </div>
                      </div>
                      <HorizontalBar
                        data={(cnv) => horizontalBarChartData(horizontalChart, cnv)}
                        options={horizontalBarChartOptions(horizontalChart.data)}
                        height={130}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                height: 316,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('generalInfo')}</p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('createdAt2')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {moment(shop.created).format('DD.MM.YYYY')}
              </p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('vatNo')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>{`${shop.taxId}`}</p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('shopAddressTitle')}</p>
              <p style={{ fontWeight: 600, fontSize: 17, marginBottom: 4 }}>
                {shop.address.street}
              </p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {`${shop.address.zip} `}
                {shop.address.city}
              </p>
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                height: 316,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('ownerTitle')}</p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('allNames')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {`${shop.shopOwner.firstName} ${shop.shopOwner.lastName}`}
              </p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('ownerMail')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>{`${shop.shopOwner.email}`}</p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('ownerPhone')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>{`${shop.shopOwner.phoneNumber}`}</p>
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                height: 316,
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 700 }}>{i18n.t('moreInfo')}</p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('sepa')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {`${
                  shop.hasSepa
                    ? i18n.t('bookingEditBookingWarningYes')
                    : i18n.t('bookingEditBookingWarningNo')
                }`}
              </p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('numberTables')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {shop.tables ? shop.tables.length : 0}
              </p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('numberCardReaders')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>
                {shop.terminalReaders ? shop.terminalReaders.length : 0}
              </p>
              <p style={{ fontSize: 13, marginBottom: 4 }}>{i18n.t('lastZbonNr')}</p>
              <p style={{ fontWeight: 600, fontSize: 17 }}>{`${shop.zBonNumber}`}</p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );

  return (
    <>
      <div
        className="cockpit-title"
        style={{ position: matches ? '' : 'absolute', marginTop: matches ? 0 : 11 }}
      >
        {matches ? (
          <>
            <p className="cockpit-title">{i18n.t('closingTitle')}</p>
            <AnimatedTabs
              tabs={[
                { id: 'zbons', label: i18n.t('closingZBons') },
                { id: 'statistics', label: i18n.t('closingStatistics') },
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onChangeTab={() => {}}
            />
          </>
        ) : (
          <Dropdown
            style={{
              fontSize: 26,
              fontFamily: 'Montserrat',
              fontWeight: 700,
              height: 30,
              marginTop: 7,
              paddingLeft: 10,
            }}
            onChange={(_, data) => {
              if (data.value) {
                setSelectedTitleDropdownOption(
                  data.options.find((option) => option.value === data.value).text
                );
                setSelectedTab(tabs[data.value]);
              }
            }}
            className="icon products-dropdown"
            labeled
            options={titleDropdownOptions}
            text={selectedTitleDropdownOption}
          />
        )}
      </div>
      {selectedTab === tabs.zbons ? <>{parseZBonsTab()}</> : <></>}
      {selectedTab === tabs.statistics ? <>{parseStatisticsTab()}</> : <></>}
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ZBonScreenModal
        onClose={onCloseZBonModal}
        open={zBonModalOptions.open}
        savedZBon={zBonModalOptions.zBon}
        withCoins={false}
      />
    </>
  );
};

export default DailyClosingScreen;
