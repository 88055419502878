/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import sortBy from 'lodash/sortBy';
import { Table, Search, Button, Checkbox, Dropdown, Loader, Modal, Input } from 'semantic-ui-react';
import { cloneDeep, difference, flatMap } from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';
import QRCodeStyling from 'qr-code-styling';
import ReactGridLayout from 'react-grid-layout';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { useStateValue } from '../../../core/context/StateProvider';
import './TablesScreen.css';
import {
  createQRPDF,
  createRooms,
  deleteRoom,
  deleteTables,
  getRooms,
  getTables,
} from '../../../api/ShopAPI';
import TablesScreenModal from './TablesScreenModal';
import LazyImage from '../../../components/general/LazyImage/LazyImage';
import logo from '../Settings/logoblack.png';
import TablesScreenUploadModal from './TablesScreenUploadModal';
import newbookingIcon from '../Bookings/icons/newbooking.png';
import uploadIcon from '../Bookings/icons/upload.png';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import AnimatedTabs from '../../../components/general/AnimatedTabs/AnimatedTabs';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';

const TablesScreen = () => {
  const [{ token, shop }] = useStateValue();
  const [modalOptions, setModalOptions] = useState({ table: null, open: false });
  const [searchValue, setSearchValue] = useState('');
  const [allTables, setAllTables] = useState([]);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [uploadModalOptions, setUploadModalOptions] = useState({ open: null });
  const [isTablesAvailable, setIsTablesAvailable] = useState(true);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [tableData, setTableData] = useState({
    tables: [],
    selectedColumn: null,
    sortDirection: 'descending',
    isSearching: false,
    searchTerm: '',
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const tabs = { tables: 'tables', tableplan: 'tableplan' };
  const [selectedTab, setSelectedTab] = useState(tabs.tables);
  const [selectedTitleDropdownOption, setSelectedTitleDropdownOption] = useState(
    i18n.t('tablesHeading')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tablePlanDropdownOptions, setTablePlanDropdownOptions] = useState([]);
  const [selectedTablePlanRoomName, setSelectedTablePlanRoomName] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [openDeleteTableModal, setOpenDeleteTableModal] = useState({ open: false, item: null });
  const [roomName, setRoomName] = useState('');
  const [newRoomModalOptions, setNewRoomModalOptions] = useState({ open: false });
  const [addTableModalOptions, setAddTableModalOptions] = useState({ open: false });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState({ open: false });
  const [isSelectTablesDisabled, setIsSelectTablesDisabled] = useState(true);

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  useEffect(() => {
    if (tableData) {
      if (!allTables.length && !tableData.isSearching && isTablesAvailable) {
        getAllTables();
        getDropdownData();
      }
    }
    if (tableData == null && !refreshRequired) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('tablesLoadingError'),
        ok: true,
        onOk: () => {
          closeErrorModal();
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      setRefreshRequired(true);
    }
  }, [tableData, allTables, isTablesAvailable, refreshRequired]);

  useEffect(() => {
    const allTableNumbersInRooms = flatMap(rooms, (room) => room.tables).map(
      (table) => table.number
    );
    const allTableNumberOfShop = allTables.map((table) => table.number);
    if (
      difference(allTableNumberOfShop, allTableNumbersInRooms).length !== 0 &&
      rooms.length !== 0 &&
      allTables.length !== 0 &&
      selectedTablePlanRoomName != null &&
      selectedTablePlanRoomName.length !== 0
    ) {
      setIsSelectTablesDisabled(false);
    } else {
      setIsSelectTablesDisabled(true);
    }
  }, [rooms, tableData, selectedTablePlanRoomName]);

  const onCreateRoom = () => {
    if (rooms.find((room) => room.name === roomName)) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('roomNameError'),
      });
    } else {
      setRooms([...rooms, { name: roomName, tables: [] }]);
      setTablePlanDropdownOptions([
        ...tablePlanDropdownOptions,
        { key: roomName + (tablePlanDropdownOptions.length - 1), text: roomName, value: roomName },
      ]);
      setNewRoomModalOptions({ open: false });
      setRoomName('');
    }
  };

  const onDeleteRoom = async () => {
    if (selectedRoomIndex !== null) {
      if (rooms[selectedRoomIndex]._id == null) {
        setTablePlanDropdownOptions([
          ...tablePlanDropdownOptions.filter((room) => room.value !== selectedTablePlanRoomName),
        ]);
        setSelectedTablePlanRoomName(null);
        setOpenDeleteRoomModal({ open: false });
        setRooms([...rooms.filter((room) => room.name !== selectedTablePlanRoomName)]);
        return;
      }
      await onDeleteRoomSave(selectedTablePlanRoomName);
    }
  };

  const onDeleteRoomSave = async (roomName) => {
    try {
      setIsLoading(true);
      const res = await deleteRoom(token, roomName);
      if (res && res.success) {
        setOpenDeleteRoomModal({ open: false });
        setTablePlanDropdownOptions([
          ...tablePlanDropdownOptions.filter((room) => room.value !== roomName),
        ]);
        setRooms([...rooms.filter((room) => room.name !== roomName)]);
        setSelectedTablePlanRoomName(null);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('roomDeleteError'),
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('roomDeleteError'),
      });
    }
  };

  const onDeleteFromRoom = () => {
    if (
      openDeleteTableModal.item &&
      openDeleteTableModal.item.number !== null &&
      selectedRoomIndex !== null
    ) {
      const index = rooms[selectedRoomIndex].tables.findIndex(
        (table) => table.number === openDeleteTableModal.item.number
      );
      rooms[selectedRoomIndex].tables.splice(index, 1);
      setRooms([...rooms]);
      setOpenDeleteTableModal({ open: false, item: null });
    }
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onSaveRooms = async () => {
    try {
      setIsLoading(true);
      const res = await createRooms(token, rooms);
      if (res && res.success) {
        /* NOOP */
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('tablePlanCreateError'),
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('tablePlanCreateError'),
      });
    }
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const getAllTables = async () => {
    try {
      setIsLoading(true);
      const res = await getTables(token);
      setIsLoading(false);
      if (res && res.success) {
        if (res.tables.length === 0) {
          setIsTablesAvailable(false);
        }
        setTableData({
          tables: res.tables,
          selectedColumn: null,
          sortDirection: 'descending',
          isSearching: false,
          searchTerm: '',
        });
        setAllTables(res.tables);
      } else {
        setTableData(null);
      }
    } catch (_) {
      setIsLoading(false);
      setTableData(null);
    }
  };

  const onCloseModal = async (refreshNeeded) => {
    setModalOptions({ table: null, open: false });
    setUploadModalOptions({ open: false });
    if (refreshNeeded) {
      setTableData({ ...tableData, tables: [], searchTerm: '' });
      setAllTables([]);
      setSearchValue('');
      setIsTablesAvailable(true);
    }
  };

  const onSelectForDelete = (index) => {
    const indexOfSelected = selectedForDeletion.findIndex((selected) => selected === index);
    if (indexOfSelected !== -1) {
      const selectedArrayCpy = cloneDeep(selectedForDeletion);
      selectedArrayCpy.splice(indexOfSelected, 1);
      setSelectedForDeletion(selectedArrayCpy);
    } else {
      setSelectedForDeletion([...selectedForDeletion, index]);
    }
  };

  const createTableCode = async (table) => {
    const qrCodeOptions = {
      width: 800,
      height: 800,
      data: `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${table.code}`,
      margin: 0,
      qrOptions: { typeNumber: '0', mode: 'Byte', errorCorrectionLevel: 'Q' },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
      dotsOptions: { type: 'extra-rounded', color: '#000000', gradient: null },
      backgroundOptions: { color: '#ffffff' },
      image: logo,
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      cornersSquareOptions: { type: 'extra-rounded', color: '#000000' },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      cornersDotOptions: { type: '', color: '#000000' },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#000000',
          color2: '#000000',
          rotation: '0',
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: '#ffffff',
          color2: '#ffffff',
          rotation: '0',
        },
      },
    };
    const qr = new QRCodeStyling(qrCodeOptions);
    await qr._canvasDrawingPromise;
    const qrImage = qr._canvas.toDataURL('image/png', 90);
    return qrImage;
  };

  const triggerQRCodeCreate = async (table) => {
    try {
      const tableDesc = `${table.description} ${table.number ? `(${table.number})` : ''}`;
      const code = await createTableCode(table);
      await createQRPDF(tableDesc, code, token);
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: 'Error',
        message: error,
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const parseTables = (tables) =>
    tables.map((table, index) => (
      <Fragment key={`${index}frag`}>
        <Table.Row
          key={table._id}
          style={{ backgroundColor: selectedForDeletion.includes(index) ? '#7268cd1f' : '' }}
        >
          {matches ? (
            <>
              <Table.Cell
                key={`${table._id}11`}
                width="1"
                selectable={false}
                verticalAlign="middle"
                textAlign="center"
                onClick={() => onSelectForDelete(index)}
              >
                <Checkbox
                  onChange={() => onSelectForDelete(index)}
                  checked={selectedForDeletion.includes(index)}
                />
              </Table.Cell>
              <Table.Cell
                key={`${table._id}1`}
                collapsing
                onClick={() => setModalOptions({ table, open: true })}
              >
                {table.code ? (
                  <LazyImage
                    key={`${table.code}1`}
                    style={{ height: 80, width: 80 }}
                    alt=""
                    size="medium"
                    data={`${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_CUSTOMER_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/${table.code}`}
                    name={`moaby-tisch-${table.number}-${shop.name}.png`}
                  />
                ) : (
                  <></>
                )}
              </Table.Cell>
            </>
          ) : (
            <></>
          )}
          <Table.Cell
            key={`${table._id}2`}
            collapsing
            onClick={() => setModalOptions({ table, open: true })}
          >
            {table.description}
          </Table.Cell>
          <Table.Cell
            key={`${table._id}4`}
            collapsing
            onClick={() => setModalOptions({ table, open: true })}
          >
            {table.number}
          </Table.Cell>
          {matches ? (
            <Table.Cell key={`${table._id}5`} collapsing verticalAlign="middle" textAlign="center">
              <Button
                key={`${table._id}5bttn`}
                icon="print"
                onClick={() => {
                  triggerQRCodeCreate(table);
                }}
                color="teal"
              />
            </Table.Cell>
          ) : (
            <></>
          )}
        </Table.Row>
      </Fragment>
    ));

  const handleSort = async (selectedColumn) => {
    const isSearching = tableData.isSearching ? tableData.isSearching : false;
    if (tableData.selectedColumn !== selectedColumn) {
      const tables = sortBy(tableData.tables, [selectedColumn]);
      setTableData({
        tables,
        selectedColumn,
        sortDirection: 'ascending',
        isSearching,
        searchTerm: tableData.searchTerm,
      });
      return;
    }
    const tables = tableData.tables.slice().reverse();
    setTableData({
      tables,
      selectedColumn,
      sortDirection: tableData.sortDirection === 'ascending' ? 'descending' : 'ascending',
      isSearching,
      searchTerm: tableData.searchTerm,
    });
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (tableData.tables) {
      if (searchTerm.length === 0) {
        await getAllTables();
        setSearchValue('');
      } else {
        const loweredSearchTerm = searchTerm.toLowerCase();
        const searchResults = allTables.filter(
          (table) =>
            table.number.toString().includes(loweredSearchTerm) ||
            table.description.toLowerCase().includes(loweredSearchTerm)
        );
        if (searchResults) {
          setTableData({
            tables: searchResults,
            selectedColumn: tableData.selectedColumn,
            sortDirection: tableData.sortDirection,
            isSearching: true,
            searchTerm,
          });
        }
      }
    }
  };

  const onDeleteTables = async (tableIds) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('deleteTables'),
        message: i18n.t('deleteTablesWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (
            shop.tables.length === 1 &&
            (shop.isInHouseTableAllowed === false || shop.isTakeAwayTableAllowed === false)
          ) {
            setErrorModalOptions({
              open: true,
              title: i18n.t('error'),
              message: i18n.t('deleteTablesError1'),
              ok: true,
              onOk: () => {
                closeErrorModal();
                closeWarningModal();
              },
              yes: false,
              onYes: null,
              no: false,
              onNo: null,
            });
          } else {
            const res = await deleteTables(token, tableIds);
            setSelectedForDeletion([]);
            if (res && res.success) {
              await getAllTables();
              closeWarningModal();
            } else {
              setErrorModalOptions({
                open: true,
                title: i18n.t('error'),
                message: i18n.t('deleteTablesError2'),
                ok: true,
                onOk: () => {
                  closeErrorModal();
                  closeWarningModal();
                },
                yes: false,
                onYes: null,
                no: false,
                onNo: null,
              });
            }
          }
        },
        no: true,
        onNo: () => {
          closeWarningModal();
        },
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('deleteTablesError3'),
        ok: true,
        onOk: () => {
          closeErrorModal();
          closeWarningModal();
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const getDropdownData = async () => {
    try {
      const res = await getRooms(token);
      if (res && res.success) {
        setRooms(res.rooms);
        setTablePlanDropdownOptions([
          ...res.rooms.map((room, index) => ({
            key: room.name + index,
            text: room.name,
            value: room.name,
          })),
        ]);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('roomsLoadingError'),
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('roomsLoadingError'),
      });
    }
  };

  const onDropDownChange = (option) => {
    setSearchValue('');
    if (option.length === 0) {
      setTableData({ ...tableData, tables: allTables });
      return;
    }
    const room = rooms.find((room) => room.name.includes(option));
    if (room == null) {
      return;
    }
    if (room.tables && room.tables.length === 0) {
      setTableData({ ...tableData, tables: [] });
      return;
    }
    const tables = (allTables || []).filter((table) =>
      room.tables.find((rTable) => rTable.number === table.number)
    );
    setTableData({ ...tableData, tables });
  };

  const titleDropdownOptions = [
    { key: i18n.t('tablesHeading'), value: 'tables', text: i18n.t('tablesHeading') },
    {
      key: i18n.t('tablePlan'),
      value: 'tableplan',
      text: i18n.t('tablePlan'),
    },
  ];

  const parseTablesView = () => (
    <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginTop: matches ? 0 : -16,
          }}
        >
          <div className="bookings-items-toolbar">
            <img
              src={uploadIcon}
              alt=""
              style={{ cursor: 'pointer', width: 30, height: 32, marginTop: -6 }}
              onClick={async () => {
                setUploadModalOptions({ open: true });
              }}
            />
            <p style={{ color: '#7268cd', fontWeight: 500, fontSize: 18, marginTop: -4 }}>|</p>
            <img
              src={newbookingIcon}
              alt=""
              style={{ cursor: 'pointer', width: 32, height: 32, marginTop: -6 }}
              onClick={async () => {
                setModalOptions({ open: true, item: null });
              }}
            />
          </div>
        </div>
        <div
          className="bookings-items-container"
          style={{ display: 'flex', flexDirection: 'column', marginTop: matches ? 0 : 30 }}
        >
          <div className="bookings-header-container">
            <div className="bookings-search">
              <Search
                placeholder={i18n.t('tablesSearch')}
                className="bookings-searchbar"
                onSearchChange={(_, data) => handleSearch(data.value)}
                open={false}
                value={searchValue}
              />
            </div>
            {matches ? (
              <div className="bookings-pagination">
                <div className="bookings-pagination-center">
                  {selectedForDeletion.length > 0 && (
                    <Button
                      icon="trash"
                      color="teal"
                      style={{ height: 38, width: 38 }}
                      onClick={() => {
                        const tableIds = tableData.tables
                          .map((table, index) => {
                            if (selectedForDeletion.includes(index)) {
                              return table._id.toString();
                            }
                            return null;
                          })
                          .filter((table) => table !== null);
                        onDeleteTables(tableIds);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="bookings-dropdown">
              <Dropdown
                selection
                clearable
                placeholder={i18n.t('room')}
                options={(rooms || []).map((room) => ({
                  key: room.name,
                  text: room.name,
                  value: room.name,
                }))}
                onChange={(_, data) => {
                  onDropDownChange(data.value);
                }}
              />
            </div>
          </div>
          <div
            className="bookings-table-container"
            style={{ height: matches === false ? 'calc(100vh - 296px)' : '' }}
          >
            <Table fixed celled selectable padded sortable className="bookings-table">
              {matches === true ? (
                <Table.Header>
                  <Table.Row>
                    {matches ? (
                      <>
                        <Table.HeaderCell
                          style={{ backgroundColor: 'white' }}
                          className="div-hover"
                          key="1111"
                          width="1"
                          verticalAlign="middle"
                          textAlign="center"
                        >
                          <Checkbox
                            onChange={() => {
                              if (
                                tableData &&
                                tableData.tables &&
                                selectedForDeletion.length !== tableData.tables.length
                              ) {
                                setSelectedForDeletion([...Array(tableData.tables.length).keys()]);
                              } else {
                                setSelectedForDeletion([]);
                              }
                            }}
                            checked={
                              tableData &&
                              tableData.tables &&
                              selectedForDeletion.length === tableData.tables.length &&
                              tableData.tables.length !== 0
                            }
                            disabled={
                              (tableData && tableData.tables && tableData.tables.length === 0) ||
                              tableData === null
                            }
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ backgroundColor: 'white' }}
                          className="div-hover"
                        >
                          {i18n.t('tablesCode')}
                        </Table.HeaderCell>
                      </>
                    ) : (
                      <></>
                    )}
                    <Table.HeaderCell
                      style={{ backgroundColor: 'white' }}
                      className="div-hover"
                      width="8"
                      sorted={
                        tableData
                          ? tableData.selectedColumn === 'description'
                            ? tableData.sortDirection
                            : null
                          : null
                      }
                      onClick={() => handleSort('description')}
                    >
                      {i18n.t('tableDescription')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ backgroundColor: 'white' }}
                      className="div-hover"
                      width="3"
                      sorted={
                        tableData
                          ? tableData.selectedColumn === 'number'
                            ? tableData.sortDirection
                            : null
                          : null
                      }
                      onClick={() => handleSort('number')}
                    >
                      {i18n.t('tableNumber')}
                    </Table.HeaderCell>
                    {matches ? (
                      <Table.HeaderCell
                        width="2"
                        style={{ backgroundColor: 'white' }}
                        className="div-hover"
                      >
                        {i18n.t('tStand')}
                      </Table.HeaderCell>
                    ) : (
                      <></>
                    )}
                  </Table.Row>
                </Table.Header>
              ) : (
                <></>
              )}
              <Table.Body>
                {tableData && tableData.tables.length > 0 ? parseTables(tableData.tables) : []}
                {isLoading ? (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <Loader
                          active={isLoading === true}
                          size="large"
                          style={{ marginTop: -100 }}
                          className="bookings-create-loader"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ) : (
                  <></>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  const parseGridItems = (layout) =>
    layout.map((item) => (
      <div
        key={item.number}
        className="tables-grid-item"
        data-grid={{
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          minH: item.minH,
          maxW: item.maxW,
          maxH: item.maxH,
          minW: item.minW,
          isBounded: true,
          verticalCompact: true,
        }}
      >
        {item.number}
        <span
          className="remove"
          style={{
            position: 'absolute',
            left: '6px',
            top: '2px',
            cursor: 'pointer',
            fontSize: 20,
            color: 'white',
          }}
          onTouchStart={() => setOpenDeleteTableModal({ open: true, item })}
          onMouseDown={() => setOpenDeleteTableModal({ open: true, item })}
        >
          ×
        </span>
      </div>
    ));

  const onTablePlanDropDownChange = (value) => {
    const index = rooms.findIndex((room) => room.name === value);
    setSelectedRoomIndex(index);
    setSelectedTablePlanRoomName(value);
  };

  const onLayoutChange = (newLayout) => {
    if (
      selectedRoomIndex !== null &&
      rooms[selectedRoomIndex] != null &&
      rooms[selectedRoomIndex].tables != null
    ) {
      for (let i = 0; i < rooms[selectedRoomIndex].tables.length; i++) {
        const table = rooms[selectedRoomIndex].tables[i];
        const layout = newLayout[i];
        if (table.number === Number(layout.i)) {
          rooms[selectedRoomIndex].tables[i] = {
            ...rooms[selectedRoomIndex].tables[i],
            w: layout.w,
            h: layout.h,
            x: layout.x,
            y: layout.y,
          };
        }
      }
      setRooms([...rooms]);
      setIsSaveDisabled(false);
    }
  };

  const parseTablePlan = () => (
    <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginTop: matches ? 0 : -16,
          }}
        >
          <div className="bookings-items-toolbar">
            <img
              src={newbookingIcon}
              alt=""
              style={{ cursor: 'pointer', width: 32, height: 32, marginTop: -6 }}
              onClick={async () => {
                setNewRoomModalOptions({ open: true });
              }}
            />
          </div>
        </div>
        <div
          className="bookings-items-container"
          style={{ display: 'flex', flexDirection: 'column', marginTop: matches ? 0 : 30 }}
        >
          <div className="bookings-header-container" style={{ justifyContent: 'space-between' }}>
            <div
              className="bookings-dropdown"
              style={{ marginLeft: 0, marginBottom: 8, marginTop: 0 }}
            >
              <Dropdown
                selection
                placeholder={i18n.t('chooseRoom')}
                options={tablePlanDropdownOptions}
                value={selectedTablePlanRoomName}
                onChange={(_, data) => {
                  onTablePlanDropDownChange(data.value);
                }}
              />
              {selectedTablePlanRoomName !== null && (
                <Button
                  icon="cancel"
                  color="red"
                  basic
                  onClick={() => setOpenDeleteRoomModal({ open: true })}
                  circular
                  style={{ marginLeft: 5 }}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: matches ? 'space-between' : 'center',
                width: matches ? 'unset' : '100%',
                height: 36,
              }}
            >
              <Button
                content={i18n.t('tablesAddNewItem')}
                floated="left"
                onClick={() => {
                  if (selectedRoomIndex != null) {
                    const allTableNumbersInRooms = flatMap(rooms, (room) => room.tables).map(
                      (table) => table.number
                    );
                    const allTableNumberOfShop = allTables.map((table) => table.number);
                    if (difference(allTableNumberOfShop, allTableNumbersInRooms).length !== 0) {
                      setAddTableModalOptions({ open: true });
                    } else {
                      setModalOptions({ open: true, table: null });
                    }
                  }
                }}
                disabled={selectedRoomIndex == null || isSelectTablesDisabled}
                style={{ outline: '1px solid #7268cd', backgroundColor: 'white' }}
              />
              <Button
                loading={isLoading}
                content={i18n.t('tableModalSave')}
                disabled={isSaveDisabled === true || isLoading === true}
                floated="right"
                onClick={onSaveRooms}
                color="teal"
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#cbc7ed',
              padding: 5,
              marginTop: 10,
              borderRadius: 20,
              flex: 1,
            }}
            className="reactgridlayout-wrapper"
          >
            {selectedRoomIndex !== null ? (
              <>
                <ReactGridLayout
                  onLayoutChange={(e) => onLayoutChange(e)}
                  cols={18}
                  rowHeight={25}
                  width={950}
                  compactType={null}
                  className="bookings-tableview"
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: 10,
                    height: '100%',
                    marginRight: 5,
                    width: '100%',
                    overflow: 'scroll',
                  }}
                >
                  {parseGridItems(
                    rooms && rooms.length > 0 && rooms[selectedRoomIndex]
                      ? rooms[selectedRoomIndex].tables
                      : []
                  )}
                </ReactGridLayout>
              </>
            ) : (
              <>
                <p style={{ fontWeight: 600, color: 'white', textAlign: 'center', paddingTop: 20 }}>
                  {i18n.t('chooseRoomDescription')}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className="cockpit-title"
        style={{ position: matches ? '' : 'absolute', marginTop: matches ? 6 : 11 }}
      >
        {matches ? (
          <>
            <p className="cockpit-title">{i18n.t('tablesHeading')}</p>
            <AnimatedTabs
              tabs={[
                { id: 'tables', label: i18n.t('tablesHeading') },
                { id: 'tableplan', label: i18n.t('tablePlan') },
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onChangeTab={() => {
                setSelectedRoomIndex(null);
                setSelectedTablePlanRoomName(null);
                setSelectedForDeletion([]);
                setIsSaveDisabled(true);
                if (selectedTab === tabs.tables) {
                  setSelectedTitleDropdownOption(i18n.t('tablePlan'));
                } else {
                  setSelectedTitleDropdownOption(i18n.t('tablesHeading'));
                }
                if (searchValue && searchValue.length > 0) {
                  handleSearch('');
                }
              }}
            />
          </>
        ) : (
          <Dropdown
            style={{
              fontSize: 26,
              fontFamily: 'Montserrat',
              fontWeight: 700,
              height: 30,
              marginTop: 7,
              paddingLeft: 10,
            }}
            onChange={(_, data) => {
              if (data.value) {
                setSelectedTitleDropdownOption(
                  data.options.find((option) => option.value === data.value).text
                );
                setSelectedTab(tabs[data.value]);
              }
            }}
            className="icon"
            labeled
            options={titleDropdownOptions}
            text={selectedTitleDropdownOption}
          />
        )}
      </div>
      {selectedTab === tabs.tables ? <>{parseTablesView()}</> : <></>}
      {selectedTab === tabs.tableplan ? <>{parseTablePlan()}</> : <></>}
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <TablesScreenModal
        open={modalOptions.open}
        table={modalOptions.table}
        onClose={onCloseModal}
        tableNumbers={
          tableData ? (tableData.tables ? tableData.tables.map((table) => table.number) : []) : []
        }
      />
      <TablesScreenUploadModal open={uploadModalOptions.open} onClose={onCloseModal} />
      <AnimatedModal
        dimmer="blurring"
        size="tiny"
        open={openDeleteTableModal.open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'none', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('deleteTable')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setOpenDeleteTableModal({ open: false, item: null });
            }}
            floated="right"
            className="items-close-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9' }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('deleteTableFromRoom')}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('deleteFromRoom')}
            onClick={() => onDeleteFromRoom(openDeleteTableModal.item)}
            color="teal"
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal
        dimmer="blurring"
        size="tiny"
        open={openDeleteRoomModal.open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'white', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('deleteRoom')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setOpenDeleteRoomModal({ open: false });
            }}
            floated="right"
            className="items-close-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9' }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('deleteRoomWarning')}</p>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: '#f5f5f9' }}>
          <Button
            content={i18n.t('itemsDelete')}
            onClick={() => onDeleteRoom()}
            loading={isLoading}
            disabled={isLoading}
            color="red"
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal
        dimmer="blurring"
        size="tiny"
        open={newRoomModalOptions.open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            paddingBottom: 5,
            backgroundColor: '#f5f5f9',
            fontFamily: 'Montserrat',
          }}
        >
          {i18n.t('newRoom')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setRoomName('');
              setNewRoomModalOptions({ open: false });
            }}
            floated="right"
            className="items-close-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content style={{ backgroundColor: '#f5f5f9' }}>
          <p style={{ fontWeight: 600, fontSize: 16 }}>Name</p>
          <input
            style={{ backgroundColor: 'white' }}
            className="item-modal-input"
            placeholder="Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value.trim().length > 0 ? e.target.value : '')}
          />
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', minHeight: 65, backgroundColor: '#f5f5f9' }}>
          <Button
            disabled={roomName.length === 0}
            content={i18n.t('create')}
            onClick={onCreateRoom}
            color="teal"
            style={{ marginLeft: 0 }}
            floated="right"
          />
        </Modal.Actions>
      </AnimatedModal>
      <AnimatedModal
        dimmer="blurring"
        size="tiny"
        open={addTableModalOptions.open}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{ borderBottom: 'none', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
        >
          {i18n.t('chooseTable')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => {
              setAddTableModalOptions({ open: false });
            }}
            floated="right"
            className="items-close-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ padding: 14, paddingTop: 4, backgroundColor: '#f5f5f9', scrollbarWidth: 'none' }}
          className="no-scrollbar"
        >
          <div style={{ borderRadius: 14, padding: 10, backgroundColor: 'white' }}>
            {allTables.map((table, index) => {
              const visibleTables = allTables.filter(
                (t) =>
                  !rooms.some((room) => room.tables.some((rTable) => rTable.number === t.number))
              );
              const isVisible = !rooms.some((room) =>
                room.tables.some((rTable) => rTable.number === table.number)
              );
              const isLastVisible =
                isVisible && visibleTables[visibleTables.length - 1].number === table.number;
              return (
                <Fragment key={`${table.number + index}t`}>
                  {rooms.find((room) =>
                    room.tables.find((rTable) => rTable.number === table.number)
                  ) == null && (
                    <div
                      key={`${table.number + index}r`}
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        cursor: 'pointer',
                        padding: 12,
                        borderBottom: !isLastVisible ? '1px solid rgb(240, 242, 246)' : 'none',
                      }}
                      onClick={() => {
                        const room = rooms[selectedRoomIndex];
                        table.x = 0;
                        table.y = 0;
                        table.w = 1;
                        table.h = 2;
                        table.minH = 2;
                        table.maxH = 4;
                        table.maxW = 4;
                        table.minW = 1;
                        room.tables.push(table);
                        rooms[selectedRoomIndex] = room;
                        setRooms([...rooms]);
                        const availableTables = allTables.filter(
                          (t) =>
                            !rooms.some((room) =>
                              room.tables.some((rTable) => rTable.number === t.number)
                            )
                        );

                        if (availableTables.length === 0) {
                          setAddTableModalOptions({ open: false });
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <p>{table.description}</p>
                        </div>
                        <div>
                          <p>{table.number}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          <Button
            style={{ marginLeft: 0 }}
            floated="right"
            content={i18n.t('createNewTable')}
            onClick={() => {
              setAddTableModalOptions({ open: false });
              setModalOptions({ open: true, table: false });
            }}
            color="teal"
          />
        </Modal.Actions>
      </AnimatedModal>
      <ErrorWarningModal
        open={errorModalOptions.open}
        title={errorModalOptions.title}
        message={errorModalOptions.message}
        ok={errorModalOptions.ok}
        onOk={errorModalOptions.onOk}
        yes={errorModalOptions.yes}
        onYes={errorModalOptions.onYes}
        no={errorModalOptions.no}
        onNo={errorModalOptions.onNo}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};
export default TablesScreen;
