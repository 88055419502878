/* eslint-disable import/prefer-default-export */

export class PrinterQueue {
  constructor(printFunction) {
    this.printFunction = printFunction;
    this.queue = [];
    this.isProcessing = false;
    this.printjobIds = new Set(JSON.parse(localStorage.getItem('printjobIds')));
    this.failedPrints = [];
  }

  async addToQueue(printjob) {
    const { printjobId } = printjob;

    if (this.printjobIds.has(printjobId)) {
      console.log('Duplicate Printjob detected:', printjob);
      return;
    }

    this.printjobIds.add(printjobId);
    try {
      localStorage.setItem('printjobIds', JSON.stringify([...this.printjobIds]));
    } catch (error) {
      console.error('LocalStorage setItem Error: ', error);
    }
    this.queue.push(printjob);

    await this.processQueue();
  }

  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;
    const printjob = this.queue.shift();
    // this.printjobIds.delete(printjob.printjobId);

    try {
      const lastPrintedPrintJobId = Number(localStorage.getItem('lastPrintedPrintjobId') || 0);
      if (lastPrintedPrintJobId) {
        if (printjob.printjobId > lastPrintedPrintJobId) {
          localStorage.setItem('lastPrintedPrintjobId', printjob.printjobId.toString());
        }
      } else {
        localStorage.setItem('lastPrintedPrintjobId', printjob.printjobId.toString());
      }
    } catch (error) {
      console.error('LocalStorage setItem Error: ', error);
    }

    try {
      await this.handleItem(printjob);
    } catch (error) {
      console.error('Printing Error:', error);
      this.addToFailedPrints(printjob);
    } finally {
      this.isProcessing = false;

      await this.processQueue();
    }
  }

  async handleItem(printjob) {
    console.log('processing queue: ', printjob.printjobId);
    return this.printFunction(printjob);
  }

  async addToFailedPrints(printjob) {
    this.failedPrints.push(printjob);
    if (!localStorage.getItem('failedPrints')) {
      localStorage.setItem('failedPrints', JSON.stringify([]));
    }

    const failedPrints = JSON.parse(localStorage.getItem('failedPrints'));
    failedPrints.push(printjob);
    localStorage.setItem('failedPrints', JSON.stringify(failedPrints));
    window.dispatchEvent(new Event('storage'));
  }
}
