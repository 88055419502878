/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import './App.css';
import '../i18n/config';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import NavbarItem from '../components/general/Navbar/NavbarItem';
import Navbar from '../components/general/Navbar/Navbar';
import CustomerHome from '../screens/customer/CustomerHome/CustomerHome';
import CustomerItem from '../screens/customer/CustomerItem/CustomerItem';
import consumeCode from '../api/QRAPI';
import { useStateValue } from './context/StateProvider';
import BookingsScreen from '../screens/shop/Bookings/BookingsScreen';
import ItemsScreen from '../screens/shop/Items/ItemsScreen';
import TablesScreen from '../screens/shop/Tables/TablesScreen';
import SettingsScreen from '../screens/shop/Settings/SettingsScreen';
import DashboardScreen from '../screens/shop/Dashboard/DashboardScreen';
import LoginScreen from '../screens/shop/Login/LoginScreen';
import CreateShopScreen from '../screens/admin/CreateShop/CreateShopScreen';
import UpdateCategoriesScreen from '../screens/admin/UpdateCategories/UpdateCategoriesScreen';
import CreateTSSScreen from '../screens/admin/CreateTSS/CreateTSSScreen';
import CreateQRScreen from '../screens/admin/CreateQR/CreateQRScreen';
import AddRemoveHardwareScreen from '../screens/admin/AddRemoveHardware/AddRemoveHardwareScreen';
import InvoiceScreen from '../screens/admin/Invoice/InvoiceScreen';
import SidebarItem from '../components/shop/Sidebar/SidebarItem';
import Sidebar from '../components/shop/Sidebar/Sidebar';
import dashboardIconSelected from '../components/shop/Sidebar/Icons/dashboard-filled.png';
import dashboardIconUnselected from '../components/shop/Sidebar/Icons/dashboard-unselected.png';
import bookingsIconSelected from '../components/shop/Sidebar/Icons/bookings-filled.png';
import bookingsIconUnselected from '../components/shop/Sidebar/Icons/bookings-unselected.png';
import itemsIconSelected from '../components/shop/Sidebar/Icons/items-filled.png';
import itemsIconUnselected from '../components/shop/Sidebar/Icons/items-unselected.png';
import tablesIconSelected from '../components/shop/Sidebar/Icons/tables-filled.png';
import tablesIconUnselected from '../components/shop/Sidebar/Icons/tables-unselected.png';
import settingsIconSelected from '../components/shop/Sidebar/Icons/settings-filled.png';
import settingsIconUnselected from '../components/shop/Sidebar/Icons/settings-unselected.png';
import shopIconSelected from '../components/shop/Sidebar/Icons/shop-selected.png';
import shopIconUnselected from '../components/shop/Sidebar/Icons/shop-unselected.png';
import categoryIconSelected from '../components/shop/Sidebar/Icons/category-selected.png';
import categoryIconUnselected from '../components/shop/Sidebar/Icons/category-unselected.png';
import hardwareIconSelected from '../components/shop/Sidebar/Icons/hardware-selected.png';
import hardwareIconUnselected from '../components/shop/Sidebar/Icons/hardware-unselected.png';
import tssIconSelected from '../components/shop/Sidebar/Icons/fiskaly-selected.png';
import tssIconUnselected from '../components/shop/Sidebar/Icons/fiskaly-unselected.png';
import qrIconSelected from '../components/shop/Sidebar/Icons/qrcode-selected.png';
import qrIconUnselected from '../components/shop/Sidebar/Icons/qrcode-unselected.png';
import invoiceIconSelected from '../components/shop/Sidebar/Icons/invoice-selected.png';
import invoiceIconUnselected from '../components/shop/Sidebar/Icons/invoice-unselected.png';
import comparisonIconSelected from '../components/shop/Sidebar/Icons/compare-selected.png';
import comparisonIconUnselected from '../components/shop/Sidebar/Icons/compare-unselected.png';
import documentsIconSelected from '../components/shop/Sidebar/Icons/docs-selected.png';
import documentsIconUnselected from '../components/shop/Sidebar/Icons/docs-unselected.png';
import { getMe } from '../api/AppInfoAPI';
import ErrorWarningModal from '../components/general/ErrorWarningModal/ErrorWarningModal';
import PasswordForgotScreen from '../screens/shop/Login/PasswordForgotScreen';
import { Toast } from '../components/general/Toast/Toast';
import ReportsScreen from '../screens/shop/Bookings/ReportsScreen';
import FranchiseDashboardScreen from '../screens/franchisor/Dashboard/FranchiseDashboardScreen';
import FranchiseComparisonScreen from '../screens/franchisor/Comparison/FranchiseComparisonScreen';
import FranchiseDocumentsScreen from '../screens/franchisor/Documents/FranchiseDocumentsScreen';
import DailyClosingScreen from '../screens/reports/DailyClosing/DailyClosingScreen';
import ExportsScreen from '../screens/reports/Exports/ExportsScreen';

const App = () => {
  const [isShop, setIsShop] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [status, setStatus] = useState({ loggedIn: false, checked: false });
  const [isPathChecked, setIsPathChecked] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [{ token, user, shop }, dispatch] = useStateValue();
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (!refreshRequired) {
      if (!status.checked && token) {
        checkIfLoggedIn();
      } else if (!isPathChecked) {
        onboardApp();
      }
    }
  });

  const useQuery = () => new URLSearchParams(useLocation().search);

  const loginRoutes = [
    {
      path: '/login',
      exact: true,
      main: () => <LoginScreen />,
    },
    {
      path: '/forgot',
      exact: true,
      main: () => <PasswordForgotScreen />,
    },
    {
      path: '/forgot/:resetToken',
      exact: true,
      main: () => <PasswordForgotScreen />,
    },
    {
      path: '/password/*',
      exact: true,
      main: () => (
        <PasswordForgotScreen hash={useQuery().get('hash')} token={useQuery().get('token')} />
      ),
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/login" />,
    },
  ];

  const adminRoutes = [
    {
      path: '/create',
      exact: true,
      main: () => <CreateShopScreen />,
    },
    {
      path: '/categories',
      exact: true,
      main: () => <UpdateCategoriesScreen />,
    },
    {
      path: '/tss',
      exact: true,
      main: () => <CreateTSSScreen />,
    },
    {
      path: '/hardware',
      exact: true,
      main: () => <AddRemoveHardwareScreen />,
    },
    {
      path: '/invoices',
      exact: true,
      main: () => <InvoiceScreen />,
    },
    {
      path: '/qr',
      exact: true,
      main: () => <CreateQRScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/create" />,
    },
  ];

  const employeeRoutes = [
    {
      path: '/bookings',
      exact: true,
      main: () => <BookingsScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/bookings" />,
    },
  ];

  const newTaxRoutes = [
    {
      path: '/closings',
      exact: true,
      main: () => <DailyClosingScreen />,
    },
    {
      path: '/exports',
      exact: true,
      main: () => <ExportsScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/closings" />,
    },
  ];

  const taxRoutes = [
    {
      path: '/reports',
      exact: true,
      main: () => <ReportsScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/reports" />,
    },
  ];

  const routes = [
    {
      path: '/dashboard',
      exact: true,
      main: () => <DashboardScreen />,
    },
    {
      path: '/bookings',
      exact: true,
      main: () => <BookingsScreen />,
    },
    {
      path: '/items',
      exact: true,
      main: () => <ItemsScreen />,
    },
    {
      path: '/tables',
      exact: true,
      main: () => <TablesScreen />,
    },
    {
      path: '/settings',
      exact: true,
      main: () => <SettingsScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/dashboard" />,
    },
  ];

  const franchisorRoutes = [
    {
      path: '/dashboard',
      exact: true,
      main: () => <FranchiseDashboardScreen />,
    },
    {
      path: '/comparison',
      exact: true,
      main: () => <FranchiseComparisonScreen />,
    },
    {
      path: '/documents',
      exact: true,
      main: () => <FranchiseDocumentsScreen />,
    },
    {
      path: '*',
      exact: true,
      main: () => <Redirect to="/dashboard" />,
    },
  ];

  const sidebarItems = [];
  if (user && user.role === 'owner') {
    sidebarItems.push(
      new SidebarItem(
        i18n.t('dashBoardTitle'),
        '/dashboard',
        dashboardIconSelected,
        dashboardIconUnselected
      )
    );
    sidebarItems.push(
      new SidebarItem(
        i18n.t('bookingsTitle'),
        '/bookings',
        bookingsIconSelected,
        bookingsIconUnselected
      )
    );
    sidebarItems.push(
      new SidebarItem(i18n.t('itemsHeading'), '/items', itemsIconSelected, itemsIconUnselected)
    );
    sidebarItems.push(
      new SidebarItem(i18n.t('tablesHeading'), '/tables', tablesIconSelected, tablesIconUnselected)
    );
    sidebarItems.push(
      new SidebarItem(
        i18n.t('settingsHeading'),
        '/settings',
        settingsIconSelected,
        settingsIconUnselected
      )
    );
  } else if (user && user.role === 'franchisor') {
    sidebarItems.push(
      new SidebarItem(
        i18n.t('dashBoardTitle'),
        '/dashboard',
        dashboardIconSelected,
        dashboardIconUnselected
      )
    );
    sidebarItems.push(
      new SidebarItem(
        i18n.t('comparisonTitle'),
        '/comparison',
        comparisonIconSelected,
        comparisonIconUnselected
      )
    );
    sidebarItems.push(
      new SidebarItem(
        i18n.t('documentsTitle'),
        '/documents',
        documentsIconSelected,
        documentsIconUnselected
      )
    );
  } else if (user && user.role === 'tax') {
    sidebarItems.push(
      new SidebarItem(
        i18n.t('closingTitle'),
        '/closings',
        bookingsIconSelected,
        bookingsIconUnselected
      )
    );
    sidebarItems.push(
      new SidebarItem(
        i18n.t('exportsTitle'),
        '/exports',
        documentsIconSelected,
        documentsIconUnselected
      )
    );
  } else {
    sidebarItems.push(
      new SidebarItem(
        i18n.t('bookingsTitle'),
        '/bookings',
        bookingsIconSelected,
        bookingsIconUnselected
      )
    );
  }

  const adminSidebarItems = [
    new SidebarItem('Create', '/create', shopIconSelected, shopIconUnselected),
    new SidebarItem('Categories', '/categories', categoryIconSelected, categoryIconUnselected),
    new SidebarItem('TSS', '/tss', tssIconSelected, tssIconUnselected),
    new SidebarItem('Hardware', '/hardware', hardwareIconSelected, hardwareIconUnselected),
    new SidebarItem('Invoices', '/invoices', invoiceIconSelected, invoiceIconUnselected),
    new SidebarItem('QR-Codes', '/qr', qrIconSelected, qrIconUnselected),
  ];

  const { location } = window;
  let estimatedIndex;
  let currentIndex;
  if (user && !user.isAdmin) {
    estimatedIndex = sidebarItems.findIndex((item) => item.linkTo === location.pathname);
    currentIndex = estimatedIndex === -1 ? 0 : estimatedIndex;
  } else if (user && user.isAdmin) {
    estimatedIndex = adminSidebarItems.findIndex((item) => item.linkTo === location.pathname);
    currentIndex = estimatedIndex === -1 ? 0 : estimatedIndex;
  }
  const containerClass = status.loggedIn ? 'container-loggedin' : 'container-loggedout';
  const main =
    user && (user.role === 'employee' || user.role === 'manager')
      ? 'main-employee'
      : user && (user.role === 'franchisor' || user.role === 'tax')
      ? 'main-franchisor'
      : 'main';

  const language =
    (sessionStorage.getItem('language') || navigator.language.substring(0, 2) || '') === 'de'
      ? 'DE'
      : 'EN';
  sessionStorage.setItem('language', language === 'DE' ? 'de' : 'en');

  const navbarItemsShop = [];
  navbarItemsShop.push(
    new NavbarItem(
      '',
      '/',
      language,
      '',
      () => {
        const currentLang =
          sessionStorage.getItem('language') || navigator.language.substring(0, 2);
        i18n.changeLanguage(currentLang === 'en' ? 'de' : 'en');
        sessionStorage.setItem('language', currentLang === 'en' ? 'de' : 'en');
        window.location.reload();
      },
      { fontWeight: 700, fontSize: 13, paddingTop: 1 }
    )
  );
  if (user && user.role === 'owner' && !user.isAdmin) {
    navbarItemsShop.push(
      new NavbarItem('', '/', i18n.t('help'), '', () => {
        window.location.href = `mailto:info@moaby.app?subject=Hilfe%3A%20%5BDein%20Thema%5D&body=Hallo%20moaby%20Team%2C%0D%0A%0D%0AIch%20brauche%20hilfe%20mit%20%5BDeine%20Beschreibung%5D.%0D%0A%0D%0AShop%3A%20${
          shop ? shop.name : ''
        }%0D%0AShop%20ID%3A%20${shop ? shop._id : ''}%0D%0A%0D%0AVielen%20Dank%2C%0D%0A${
          shop ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}` : ''
        }`;
      })
    );
  }
  navbarItemsShop.push(
    new NavbarItem('', '/', i18n.t('logout'), '', () => {
      dispatch({
        type: 'logout',
      });
      window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
    })
  );

  const navbarItemsCustomer = [
    new NavbarItem('', '/:qr', 'Home', CustomerHome),
    new NavbarItem('', '/:qr/:id', 'Item', CustomerItem),
    new NavbarItem('', '/checkout', 'Checkout'),
  ];

  const checkIfLoggedIn = async () => {
    try {
      let path = window.location.pathname.replace(/\/+$/, '');
      path = path[0] === '/' ? path.substr(1) : path;
      if (path.startsWith('password')) {
        dispatch({
          type: 'logout',
        });
        setStatus({ loggedIn: false, checked: true });
      } else {
        const res = await getMe(token);
        if (res && res.success) {
          dispatch({
            type: 'update',
            shop: res.me.shop,
            config: res.me.config,
            user: res.me.user,
          });
          setStatus({ loggedIn: true, checked: true });
        } else {
          dispatch({
            type: 'logout',
          });
          setStatus({ loggedIn: false, checked: true });
        }
      }
    } catch (_) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('moabyLoadingError'),
        ok: true,
        onOk: () => {
          setErrorModalOptions({
            open: false,
            title: '',
            message: '',
            ok: false,
            onOk: null,
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      setRefreshRequired(true);
    }
  };

  const onboardApp = async () => {
    setIsLoading(true);
    let path = window.location.pathname.replace(/\/+$/, '');
    path = path[0] === '/' ? path.substr(1) : path;
    let subdomain;
    const hosts = window.location.host.split('.');
    if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'training') {
      if (hosts[0] === 'www') {
        subdomain = `${hosts[1]}.${hosts[2]}`;
      } else {
        subdomain = `${hosts[0]}.${hosts[1]}`;
      }
    } else if (hosts[0] === 'www') {
      subdomain = hosts[1];
    } else {
      subdomain = hosts[0];
    }
    const pathStrings = path.split('/');
    if (
      path.length === 0 &&
      !status.loggedIn &&
      subdomain !== process.env.REACT_APP_SHOPS_SUBDOMAIN &&
      subdomain !== process.env.REACT_APP_CUSTOMER_SUBDOMAIN
    ) {
      window.location.href = `https://${process.env.REACT_APP_WEBSITE}`;
    } else if (
      subdomain === process.env.REACT_APP_SHOPS_SUBDOMAIN &&
      (path === 'login' ||
        path === 'dashboard' ||
        path === 'bookings' ||
        path === 'reports' ||
        path === 'items' ||
        path === 'create' ||
        path === 'categories' ||
        path === 'tables' ||
        path === 'payment' ||
        path === 'forgot' ||
        path.startsWith('forgot/') ||
        path.startsWith('password') ||
        path === 'settings' ||
        path.length === 0)
    ) {
      setIsShop(true);
      setIsLoading(false);
      setIsPathChecked(true);
    } else if (status.loggedIn) {
      setIsShop(true);
      setIsLoading(false);
      setIsPathChecked(true);
    } else if (pathStrings.length === 1 && subdomain === process.env.REACT_APP_CUSTOMER_SUBDOMAIN) {
      setIsPathChecked(true);
      try {
        const res = await consumeCode(path);
        if (res && res.success) {
          dispatch({
            type: 'update',
            shop: res.shop,
            qrCode: res.qrCode,
            config: res.config,
            table: res.table,
          });
          setIsShop(false);
          setIsLoading(false);
        } else {
          window.location.href = `https://${process.env.REACT_APP_WEBSITE}`;
        }
      } catch (error) {
        console.log(error);
        window.location.href = `https://${process.env.REACT_APP_WEBSITE}`;
      }
    } else if (pathStrings.length > 1) {
      setIsPathChecked(true);
      window.location.href = `${window.location.origin}/${pathStrings[0]}`;
    }
  };

  return (
    <>
      {!isLoading ? (
        <Router>
          {isShop ? (
            <>
              <Helmet
                meta={[
                  {
                    name: `theme-color`,
                    content: '#7268cd',
                  },
                  {
                    name: 'apple-mobile-web-app-capable',
                    content: 'yes',
                  },
                  {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: '#7268cd',
                  },
                ]}
              >
                <meta name="theme-color" content="#7268cd" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="#7268cd" />
              </Helmet>
              {status.loggedIn ? <Navbar isShop items={navbarItemsShop} /> : <></>}
              <Router>
                <div className={containerClass}>
                  {status.loggedIn ? (
                    <>
                      <div>
                        <Sidebar
                          items={user && user.isAdmin ? adminSidebarItems : sidebarItems}
                          currentIndex={currentIndex}
                          isAdmin={user && user.isAdmin === true}
                        />
                        {user && user.isAdmin ? (
                          <>
                            <Switch>
                              {adminRoutes.map((route, index) => (
                                <Route key={index} path={route.path} exact={route.exact} />
                              ))}
                            </Switch>
                          </>
                        ) : (
                          <>
                            <Switch>
                              {user.role === 'employee' || user.role === 'manager'
                                ? employeeRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} />
                                  ))
                                : user.role === 'tax'
                                ? newTaxRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} />
                                  ))
                                : user.role === 'owner'
                                ? routes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} />
                                  ))
                                : franchisorRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} />
                                  ))}
                            </Switch>
                          </>
                        )}
                      </div>
                      <div className={main}>
                        {user && user.isAdmin ? (
                          <>
                            <Switch>
                              {adminRoutes.map((route, index) => (
                                <Route
                                  key={index}
                                  path={route.path}
                                  exact={route.exact}
                                  children={<route.main />}
                                />
                              ))}
                            </Switch>
                          </>
                        ) : (
                          <>
                            <Switch>
                              {user.role === 'employee' || user.role === 'manager'
                                ? employeeRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
                                  ))
                                : user.role === 'tax'
                                ? newTaxRoutes.map((route, index) => (
                                    // taxRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
                                  ))
                                : user.role === 'owner'
                                ? routes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
                                  ))
                                : franchisorRoutes.map((route, index) => (
                                    // eslint-disable-next-line prettier/prettier
                                  <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
                                  ))}
                            </Switch>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Switch>
                        {loginRoutes.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                          />
                        ))}
                        {forgotPassword ? <></> : <Redirect to="/login" />}
                      </Switch>
                    </>
                  )}
                </div>
              </Router>
            </>
          ) : (
            <>
              <Switch>
                {navbarItemsCustomer.map((item) => (
                  <Route key={item.title} path={item.linkTo} exact component={item.component} />
                ))}
                <Redirect to="/:qr" />
              </Switch>
            </>
          )}
        </Router>
      ) : (
        <Loader active={isLoading} />
      )}
      <ErrorWarningModal
        open={errorModalOptions.open}
        title={errorModalOptions.title}
        message={errorModalOptions.message}
        ok={errorModalOptions.ok}
        onOk={errorModalOptions.onOk}
        yes={errorModalOptions.yes}
        onYes={errorModalOptions.onYes}
        no={errorModalOptions.no}
        onNo={errorModalOptions.onNo}
      />
      <Toast />
    </>
  );
};

export default App;
