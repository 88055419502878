import moment from 'moment';
import { get } from './Fetch';

export const getShops = async (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/shops`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseBookingsOfToday = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/count/today/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseBookingsOfThisMonth = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/count/monthly/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseRevenueOfToday = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/revenue/today/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseRevenueOfThisMonth = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/revenue/monthly/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseDailyPerMonth = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/dailyPerMonth/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseBookingTimes = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/times/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseTopTenBookedItems = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/topten/items/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseCardVSCash = async (shop, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}franchise/dashboard/cardvscash/${shop}`;
  const headers = { Authorization: token };
  return get(url, headers);
};

export const getFranchiseCashBookExcel = async (token, date, shopId) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}franchise/cashbook/monthly/excel/${date}/${shopId}`;
  const headers = {
    Authorization: token,
    'Content-Type': 'application/pdf',
  };
  const title = `Kassenbuch-${moment(date).format('MM-YY')}.pdf`;
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
