/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import clearIcon from './clear.png';
import './NumPad.css';

const NumPad = (props) => {
  const { maxDigits, onChange, currentValue } = props;
  const [num, setNum] = useState([]);

  useEffect(() => {
    if (currentValue != null) {
      setNum(String(currentValue).split(''));
    }
  }, [currentValue]);

  useEffect(() => {
    if (num.length > 0 && num[0] === '0' && num[1] !== ',') {
      num.splice(0, 1);
      setNum(num);
    }
  }, [num]);

  const addToNum = (nNum) => {
    if (num.includes(',') && num[num.findIndex((n) => n === ',') + 2]) return;
    if (nNum === 0 && num.every((n) => n === '0')) return;
    if (nNum === ',' && num.every((n) => n === '0')) {
      num.push('0');
      num.push(',');
      setNum([...num]);
    }
    if (nNum === ',' && num.includes(',') === false && num.length < maxDigits && num.length > 0) {
      num.push(nNum);
      setNum([...num]);
    } else if (nNum !== ',' && num.length < maxDigits) {
      num.push(nNum);
      setNum([...num]);
    }
    onChange(num.join(''));
  };

  const removeFromNum = () => {
    if (num.every((n) => n === '0')) {
      setNum([]);
    }
    if (num.length > 0) {
      if (num.length === 2 && num[0] === '-') {
        setNum([]);
        onChange('');
        return;
      }

      num.splice(num.length - 1, 1);
      setNum([...num]);
      if (num.length > 0 && num[num.length - 1] === ',') {
        num.splice(num.length - 1, 1);
        setNum([...num]);
      }
      onChange(num.join(''));

      if (num.length > 1 && num[0] === '-' && parseFloat(num.join('').replace(',', '.')) === 0) {
        setNum([]);
        onChange('');
      }
    }
  };

  return (
    <div style={{ background: '#f5f5f9' }}>
      <div className="num-numpad-container">
        <div className="num-numpad-line">
          <div className="num-numpad-num" onClick={() => addToNum(1)}>
            1
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(2)}>
            2
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(3)}>
            3
          </div>
        </div>
        <div className="num-numpad-line">
          <div className="num-numpad-num" onClick={() => addToNum(4)}>
            4
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(5)}>
            5
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(6)}>
            6
          </div>
        </div>
        <div className="num-numpad-line">
          <div className="num-numpad-num" onClick={() => addToNum(7)}>
            7
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(8)}>
            8
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(9)}>
            9
          </div>
        </div>
        <div className="num-numpad-line">
          <div className="num-numpad-num" onClick={() => addToNum(',')}>
            ,
          </div>
          <div className="num-numpad-num" onClick={() => addToNum(0)}>
            0
          </div>
          <div
            className="num-numpad-num"
            onClick={() => removeFromNum()}
            style={{ fontSize: 26, alignItems: 'center' }}
          >
            <img src={clearIcon} alt="R" style={{ height: 25, width: 25 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumPad;
