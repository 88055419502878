import { get, post, put, del } from './Fetch';

// GET

export const getItemsPageSorted = async (
  shopId,
  page,
  results,
  entity,
  direction,
  category,
  token
) => {
  const url = category
    ? `${process.env.REACT_APP_SERVER_URL}items/all/items/${shopId}/${page}/${results}/${entity}/${direction}/${category}`
    : `${process.env.REACT_APP_SERVER_URL}items/all/items/${shopId}/${page}/${results}/${entity}/${direction}`;
  const header = { Authorization: token };
  return get(url, header);
};

export const getItemsPageSortedQr = async (shopId, page, results, entity, direction, category) => {
  const url = category
    ? `${process.env.REACT_APP_SERVER_URL}items/all/qr/${shopId}/${page}/${results}/${entity}/${direction}/${category}`
    : `${process.env.REACT_APP_SERVER_URL}items/all/qr/${shopId}/${page}/${results}/${entity}/${direction}`;
  const header = {};
  return get(url, header);
};

export const getItemsPageSortedTerminal = async (
  shopId,
  page,
  results,
  entity,
  direction,
  category,
  token
) => {
  const url = category
    ? `${process.env.REACT_APP_SERVER_URL}items/all/terminal/${shopId}/${page}/${results}/${entity}/${direction}/${category}`
    : `${process.env.REACT_APP_SERVER_URL}items/all/terminal/${shopId}/${page}/${results}/${entity}/${direction}`;
  const header = { Authorization: token };
  return get(url, header);
};

export const getSearchedItems = async (shopId, searchTerm, page, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/search/${encodeURIComponent(
    searchTerm
  )}/${page}/${shopId}`;
  const header = { Authorization: token };
  return get(url, header);
};

export const getAllergens = async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/allergens`;
  return get(url, {});
};

export const getOFFProduct = async (code, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/off/${code}`;
  const header = { Authorization: token };
  return get(url, header);
};

export const getExcelTemplate = async (token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}items/excel`;
  const headers = { Authorization: token };
  const title = 'Produkttemplate';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getAllItemsExcel = async (token) => {
  const downloadUrl = `${process.env.REACT_APP_SERVER_URL}items/download/excel`;
  const headers = { Authorization: token };
  const title = 'Produkte';
  const response = await (await fetch(downloadUrl, { method: 'GET', headers })).blob();
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${title}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

// POST

export const createItem = async (token, item) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items`;
  const headers = { Authorization: token };
  const body = { item };
  return post(url, headers, body);
};

export const createMultiItems = async (items, token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/multi`;
  const headers = { Authorization: token };
  const body = { items };
  return post(url, headers, body);
};

export const createDiverseItem = async (token, data) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/diverse`;
  const headers = { Authorization: token };
  const body = data;
  return post(url, headers, body);
};

export const uploadExcel = async (token, excel) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/excel`;
  const headers = { Authorization: token };
  const body = { excel };
  return post(url, headers, body);
};

// PUT

export const updateItem = async (token, itemId, item) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/${itemId}`;
  const headers = { Authorization: token };
  const body = item;
  return put(url, headers, body);
};

// DEL

export const deleteItem = async (token, itemId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/${itemId}`;
  const headers = { Authorization: token };
  return del(url, headers);
};

export const deleteItems = async (token, itemIds) => {
  const url = `${process.env.REACT_APP_SERVER_URL}items/multiple/delete`;
  const body = { itemIds };
  const headers = { Authorization: token };
  return del(url, headers, body);
};
