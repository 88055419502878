/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, Grid, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from '@react-hook/media-query';
import moment from 'moment';
import i18n from '../../../i18n/config';
import AnimatedTabs from '../../../components/general/AnimatedTabs/AnimatedTabs';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import { useStateValue } from '../../../core/context/StateProvider';
import refreshIcon from '../../shop/Bookings/icons/refresh.png';
import { getCashBookExcel, getMonthlyReportPDF, getZBons } from '../../../api/BookingAPI';
import ZBonScreenModal from '../../shop/Bookings/ZBonScreenModal';
import { asCurrency } from '../../../utils/NumberFormatter';
import { getFranchiseCashBookExcel, getShops } from '../../../api/FranchiseAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { deleteDSFinVKExport, getCPCExportLink, getOrCreateCPCExport } from '../../../api/CPCAPI';
import { deleteTSEExport, getOrCreateTSEExport, getTSEExportLink } from '../../../api/TSEAPI';

const FranchiseDocumentsScreen = () => {
  const [{ token }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [zBons, setZBons] = useState({ data: [] });
  const [zBonModalOptions, setZBonModalOptions] = useState({ open: false, zBon: null });
  const tabs = { zbons: 'zbons', exports: 'exports' };
  const [selectedTab, setSelectedTab] = useState(tabs.zbons);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [selectedTitleDropdownOption, setSelectedTitleDropdownOption] = useState(
    i18n.t('closingZBons')
  );
  const [rotations, setRotations] = useState(0);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shops, setShops] = useState([]);
  const [exportsData, setExportsData] = useState({
    hasCPCExport: false,
    cpcExportId: null,
    hasTSEExport: false,
    tseExportId: null,
  });
  const [isLoadingCPCExport, setIsLoadingCPCExport] = useState(false);
  const [isLoadingTSEExport, setIsLoadingTSEExport] = useState(false);
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const titleDropdownOptions = [
    { key: i18n.t('closingZBons'), value: 'zbons', text: i18n.t('closingZBons') },
    {
      key: i18n.t('exportsTitle'),
      value: 'exports',
      text: i18n.t('exportsTitle'),
    },
  ];

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onCloseZBonModal = () => {
    setZBonModalOptions({ open: false, date: null });
  };

  const onSelectShop = async (date, value) => {
    try {
      setSelectedDropdownOption(value);
      const shop = shops.find((shop) => shop._id === value);
      setSelectedShop(shop);
      setIsLoading(true);
      setSelectedMonth(moment(date));
      const res = await getZBons(token, shop._id, moment(date).format());
      setIsLoading(false);
      if (res && res.success) {
        setZBons({ data: res.zBons });
        setExportsData({
          hasCPCExport: res.hasCPCExport || false,
          cpcExportId: res.cpcExportId || null,
          hasTSEExport: res.hasTSEExport || false,
          tseExportId: res.tseExportId || null,
        });
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('zBonsError'),
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('zBonsError') });
    }
  };

  const loadZBons = async (date, shop) => {
    try {
      setIsLoading(true);
      setSelectedMonth(moment(date));
      const res = await getZBons(token, selectedShop._id, moment(date).format());
      setIsLoading(false);
      if (res && res.success) {
        setZBons({ data: res.zBons });
        setExportsData({
          hasCPCExport: res.hasCPCExport || false,
          cpcExportId: res.cpcExportId || null,
          hasTSEExport: res.hasTSEExport || false,
          tseExportId: res.tseExportId || null,
        });
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('zBonsError'),
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('zBonsError') });
    }
  };

  useEffect(() => {
    if (dropdownOptions.length === 0) {
      getFranchiseShops();
    }
  }, []);

  const getFranchiseShops = async () => {
    try {
      setIsLoading(true);
      const res = await getShops(token);
      if (res && res.success) {
        const shops = res.shops || [];
        setShops(shops);
        setDropdownOptions([
          ...shops.map((shop) => ({ key: shop._id, text: shop.name, value: shop._id })),
        ]);
        setIsLoading(false);
      } else {
        setErrorWarningModalOptions({ open: true, message: i18n.t('franchiseShopsError') });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('franchiseShopsError') });
    }
  };

  const downloadMonthlyReport = async () => {
    try {
      await getMonthlyReportPDF(token, selectedShop._id, moment(selectedMonth).format());
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const downloadCashBook = async () => {
    try {
      await getFranchiseCashBookExcel(token, moment(selectedMonth).format(), selectedShop._id);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const downloadDSFinVK = async () => {
    try {
      setIsLoadingCPCExport(true);
      const firstRes = await getOrCreateCPCExport(token, selectedMonth.unix(), selectedShop._id);
      if (firstRes.success === false && firstRes.error && firstRes.error.includes('Pending CPC')) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError5'),
        });
        return;
      }
      if (firstRes.success === false && firstRes.error && firstRes.error.includes('Error CPC')) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError6'),
        });
        return;
      }
      if (firstRes.success === false || firstRes.exportId == null) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError2'),
        });
        return;
      }
      const { exportId } = firstRes;
      const secondRes = await getCPCExportLink(token, exportId, selectedShop._id);
      if (secondRes.success === false) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError3'),
        });
        return;
      }
      if (secondRes.success === true && secondRes.isPending === true) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError4'),
        });
      }
      if (secondRes.success === true && secondRes.downloaded === true) {
        setExportsData({ ...exportsData, hasCPCExport: true, cpcExportId: exportId });
      }
    } catch (error) {
      console.error(error);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('cpcDownloadError1')}`,
      });
    } finally {
      setIsLoadingCPCExport(false);
    }
  };

  const resetDSFinVK = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('resetDsfinvkWarningTitle'),
      message: i18n.t('resetDsfinvkWarning1'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsLoadingCPCExport(true);
        try {
          const res = await deleteDSFinVKExport(token, selectedMonth.unix(), selectedShop._id);
          if (res == null || res.sucess === false) {
            throw new Error('DSFinVK Export reset failed or returned invalid response.');
          }
          setExportsData({ ...exportsData, hasCPCExport: false, cpcExportId: null });
        } catch (error) {
          console.error(error);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('exportResetError'),
          });
        } finally {
          setIsLoadingCPCExport(false);
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const downloadTSE = async () => {
    try {
      setIsLoadingTSEExport(true);
      const firstRes = await getOrCreateTSEExport(token, selectedMonth.unix(), selectedShop._id);
      if (firstRes.success === false || firstRes.exportId == null) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError2'),
        });
        return;
      }
      const { exportId } = firstRes;
      const secondRes = await getTSEExportLink(token, exportId, selectedShop._id);
      if (secondRes.success === false) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError3'),
        });
        return;
      }
      if (secondRes.success === true && secondRes.isPending === true) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError4'),
        });
      }
      if (secondRes.success === true && secondRes.downloaded === true) {
        setExportsData({ ...exportsData, hasTSEExport: true, tseExportId: exportId });
      }
    } catch (error) {
      console.error(error);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('cpcDownloadError1')}`,
      });
    } finally {
      setIsLoadingTSEExport(false);
    }
  };

  const resetTSE = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('resetTsekWarningTitle'),
      message: i18n.t('resetTsekWarning1'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsLoadingTSEExport(true);
        try {
          const res = await deleteTSEExport(token, selectedMonth.unix(), selectedShop._id);
          if (res == null || res.sucess === false) {
            throw new Error('TSE Export reset failed or returned invalid response.');
          }
          setExportsData({ ...exportsData, hasTSEExport: false, tseExportId: null });
        } catch (error) {
          console.error(error);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('exportResetError'),
          });
        } finally {
          setIsLoadingTSEExport(false);
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const parseZBonsTab = () => (
    <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginTop: matches ? 0 : -16,
          }}
        >
          <div className="bookings-items-toolbar">
            <img
              src={refreshIcon}
              alt=""
              style={{
                cursor: 'pointer',
                width: 30,
                height: 30,
                marginTop: -5,
                transform: `rotate(${rotations * 360 * -1}deg)`,
                transition: 'transform 0.5s ease',
              }}
              onClick={async () => {
                setRotations((prev) => prev + 1);
                await loadZBons();
              }}
            />
          </div>
        </div>
        <div
          className="bookings-items-container"
          style={{ display: 'flex', flexDirection: 'column', marginTop: matches ? 0 : 30 }}
        >
          <div
            className="bookings-header-container"
            style={{
              justifyContent: 'space-between',
              paddingBottom: 4,
              alignItems: 'center',
            }}
          >
            <Dropdown
              style={{
                fontWeight: 700,
                fontSize: 20,
                color: selectedShop ? 'black' : '#a5a0c9',
              }}
              onChange={async (_, data) => {
                if (data.value) {
                  onSelectShop(selectedMonth, data.value);
                }
              }}
              className="franchise-dropdown"
              labeled
              options={dropdownOptions}
              text={selectedShop ? selectedShop.name : i18n.t('chooseShop')}
            />
            <Popup
              trigger={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <p style={{ color: '#7268cd', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>
                  {moment(selectedMonth).format('MMM YYYY').toUpperCase().replace('.', '')}
                  <Icon name="caret down" />
                </p>
              }
              style={{ height: 220 }}
              on="click"
              position="bottom left"
            >
              <Grid centered columns={1}>
                <Grid.Column textAlign="center">
                  <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                  <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                    <ReactDatePicker
                      inline
                      locale="de"
                      container
                      wrapperClassName="datepicker"
                      showMonthYearPicker
                      selected={moment(selectedMonth).toDate()}
                      minDate={moment(
                        selectedShop && selectedShop.created ? selectedShop.created : null
                      )
                        .startOf('month')
                        .toDate()}
                      maxDate={moment().toDate()}
                      onChange={async (selectedDate) => {
                        await loadZBons(moment(selectedDate));
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </Popup>
          </div>
          <div className="bookings-table-container">
            <Table fixed celled selectable padded sortable className="bookings-table">
              {matches === true ? (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      width="2"
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'center' : 'left'}
                    >
                      {i18n.t('zBonNr')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width="2" style={{ backgroundColor: 'white' }}>
                      {i18n.t('dailySummaryDate')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ backgroundColor: 'white' }}>
                      {i18n.t('time')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'right' : 'left'}
                    >
                      {i18n.t('revenue')}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width="2"
                      style={{ backgroundColor: 'white' }}
                      textAlign={matches ? 'right' : 'left'}
                    >
                      {i18n.t('tax')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              ) : (
                <></>
              )}
              <Table.Body>
                {parseZBonsTableBody()}
                {isLoading ? (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <Loader
                          active={isLoading === true}
                          size="large"
                          style={{ marginTop: -100 }}
                          className="bookings-create-loader"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ) : (
                  <></>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );

  const parseZBonsTableBody = () =>
    zBons.data.map((zBon) => (
      <Fragment key={`${zBon.issueDateStart}div`}>
        <Table.Row
          onClick={() => {
            setZBonModalOptions({ open: true, zBon });
          }}
        >
          <Table.Cell
            style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}
            textAlign={matches ? 'center' : 'left'}
          >
            {zBon.number}
          </Table.Cell>
          <Table.Cell style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}>
            {`${moment(zBon.issueDateStart).format('DD.MM.YYYY')}`}
          </Table.Cell>
          <Table.Cell style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}>
            {`${moment(zBon.issueDateStart).format('HH:mm')} - ${moment(zBon.issueDateEnd).format(
              'HH:mm [Uhr]'
            )}`}
          </Table.Cell>
          <Table.Cell
            textAlign={matches ? 'right' : 'left'}
            style={{ borderLeft: 'none', fontSize: 16, fontWeight: 700 }}
          >
            {`${asCurrency(zBon.total / 100)}€`}
          </Table.Cell>
          <Table.Cell
            style={{ borderLeft: 'none', fontWeight: '500', fontSize: 15 }}
            textAlign={matches ? 'right' : 'left'}
          >
            {`${asCurrency(zBon.taxes / 100)}€`}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  const parseExportsTab = () => (
    <>
      <div style={{ display: 'flex', marginTop: matches ? 16 : 28 }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginTop: matches ? 0 : -16,
            }}
          >
            <div className="bookings-items-toolbar">
              <img
                src={refreshIcon}
                alt=""
                style={{
                  cursor: 'pointer',
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  transform: `rotate(${rotations * 360 * -1}deg)`,
                  transition: 'transform 0.5s ease',
                }}
                onClick={async () => {
                  setRotations((prev) => prev + 1);
                  await loadZBons();
                }}
              />
            </div>
          </div>
          <div
            className="bookings-container-grid"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
              height: 'unset',
            }}
          >
            <div
              className="bookings-header-container"
              style={{
                justifyContent: 'space-between',
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <Dropdown
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                  color: selectedShop ? 'black' : '#a5a0c9',
                }}
                onChange={async (_, data) => {
                  if (data.value) {
                    onSelectShop(selectedMonth, data.value);
                  }
                }}
                className="franchise-dropdown"
                labeled
                options={dropdownOptions}
                text={selectedShop ? selectedShop.name : i18n.t('chooseShop')}
              />
              <Popup
                trigger={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <p style={{ color: '#7268cd', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>
                    {moment(selectedMonth).format('MMM YYYY').toUpperCase().replace('.', '')}
                    <Icon name="caret down" />
                  </p>
                }
                style={{ height: 220 }}
                on="click"
                position="bottom left"
              >
                <Grid centered columns={1}>
                  <Grid.Column textAlign="center">
                    <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                    <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                      <ReactDatePicker
                        inline
                        locale="de"
                        container
                        wrapperClassName="datepicker"
                        showMonthYearPicker
                        selected={moment(selectedMonth).toDate()}
                        minDate={moment(
                          selectedShop && selectedShop.created ? selectedShop.created : null
                        )
                          .startOf('month')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={async (selectedDate) => {
                          setSelectedMonth(moment(selectedDate));
                          await loadZBons(moment(selectedDate));
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Popup>
            </div>
          </div>
        </div>
      </div>
      <div className="bookings-grid-items-owner" style={{ height: 'calc(100vh - 175px)' }}>
        <Grid stackable columns={2} stretched>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>
                  {i18n.t('monthlyReport')}
                </p>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('monthlyReportSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                  <Button
                    content={i18n.t('appPaySuccess7')}
                    color="teal"
                    fluid
                    onClick={async () => {
                      await downloadMonthlyReport();
                    }}
                    disabled={zBons.data.length === 0}
                    style={{ fontFamily: 'Montserrat' }}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>
                  {i18n.t('dailySummaryCashBook')}
                </p>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('cashbookSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
                  <Button
                    content={i18n.t('appPaySuccess7')}
                    color="teal"
                    fluid
                    onClick={async () => {
                      await downloadCashBook();
                    }}
                    disabled={
                      zBons.data.filter((zBon) => zBon.cashBook != null).length === 0 ||
                      selectedShop.allowCashBook === false
                    }
                    style={{ fontFamily: 'Montserrat' }}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  DSFinV-K
                  <Popup
                    on={['hover', 'click']}
                    // eslint-disable-next-line prettier/prettier
                    trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                    content={i18n.t('infoDSFinVK')}
                    popper={<div style={{ filter: 'none' }} />}
                  />
                </div>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('dsfinvkSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                  <Popup
                    on={['hover', 'click']}
                    content={
                      zBons.data.length === 0
                        ? i18n.t('dsfinvkError1')
                        : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                        ? i18n.t('dsfinvkError2')
                        : selectedMonth.isSame(moment(), 'month')
                        ? i18n.t('dsfinvkError3')
                        : i18n.t('dsfinvkError4')
                    }
                    basic
                    position="top right"
                    popper={<div style={{ filter: 'none' }} />}
                    disabled={
                      zBons.data.length !== 0 &&
                      zBons.data.find((zbon) => zbon.cpcClosingId != null) != null &&
                      selectedMonth.isSame(moment(), 'month') === false
                    }
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <div style={{ width: '100%' }}>
                        <Button
                          content={i18n.t('appPaySuccess7')}
                          color="teal"
                          fluid
                          loading={isLoadingCPCExport}
                          onClick={async () => {
                            await downloadDSFinVK();
                          }}
                          disabled={
                            zBons.data.length === 0 ||
                            zBons.data.find((zbon) => zbon.cpcClosingId != null) == null ||
                            selectedMonth.isSame(moment(), 'month')
                          }
                          style={{ fontFamily: 'Montserrat' }}
                        />
                      </div>
                    }
                  />
                  <Popup
                    on={['hover', 'click']}
                    content={
                      zBons.data.length === 0
                        ? i18n.t('dsfinvkError1')
                        : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                        ? i18n.t('dsfinvkError2')
                        : selectedMonth.isSame(moment(), 'month')
                        ? i18n.t('dsfinvkError3')
                        : exportsData.hasCPCExport === false
                        ? i18n.t('dsfinvkError5')
                        : i18n.t('dsfinvkError4')
                    }
                    basic
                    position="top right"
                    popper={<div style={{ filter: 'none' }} />}
                    disabled={
                      zBons.data.length !== 0 &&
                      zBons.data.find((zbon) => zbon.cpcClosingId != null) != null &&
                      selectedMonth.isSame(moment(), 'month') === false &&
                      exportsData.hasCPCExport === true
                    }
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <div style={{ width: '100%' }}>
                        <Button
                          content={i18n.t('reset')}
                          color="red"
                          fluid
                          onClick={async () => {
                            await resetDSFinVK();
                          }}
                          loading={isLoadingCPCExport}
                          disabled={
                            zBons.data.length === 0 ||
                            zBons.data.find((zbon) => zbon.cpcClosingId != null) == null ||
                            selectedMonth.isSame(moment(), 'month') ||
                            exportsData.hasCPCExport === false
                          }
                          style={{ fontFamily: 'Montserrat' }}
                        />
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  TSE
                  <Popup
                    on={['hover', 'click']}
                    // eslint-disable-next-line prettier/prettier
                    trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                    content={i18n.t('infoTSE')}
                    popper={<div style={{ filter: 'none' }} />}
                  />
                </div>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('tseSubtitle')}
                </p>
              </div>
              <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                <Popup
                  on={['hover', 'click']}
                  content={
                    zBons.data.length === 0
                      ? i18n.t('dsfinvkError1')
                      : selectedMonth.isSame(moment(), 'month')
                      ? i18n.t('dsfinvkError3')
                      : i18n.t('dsfinvkError4')
                  }
                  basic
                  position="top right"
                  popper={<div style={{ filter: 'none' }} />}
                  disabled={
                    zBons.data.length !== 0 && selectedMonth.isSame(moment(), 'month') === false
                  }
                  trigger={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <div style={{ width: '100%' }}>
                      <Button
                        content={i18n.t('appPaySuccess7')}
                        color="teal"
                        fluid
                        loading={isLoadingTSEExport}
                        onClick={async () => {
                          await downloadTSE();
                        }}
                        disabled={
                          zBons.data.length === 0 || selectedMonth.isSame(moment(), 'month')
                        }
                        style={{ fontFamily: 'Montserrat' }}
                      />
                    </div>
                  }
                />
                <Popup
                  on={['hover', 'click']}
                  content={
                    zBons.data.length === 0
                      ? i18n.t('dsfinvkError1')
                      : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                      ? i18n.t('dsfinvkError2')
                      : selectedMonth.isSame(moment(), 'month')
                      ? i18n.t('dsfinvkError3')
                      : exportsData.hasTSEExport === false
                      ? i18n.t('dsfinvkError6')
                      : i18n.t('dsfinvkError4')
                  }
                  basic
                  position="top right"
                  popper={<div style={{ filter: 'none' }} />}
                  disabled={
                    zBons.data.length !== 0 &&
                    selectedMonth.isSame(moment(), 'month') === false &&
                    exportsData.hasTSEExport === true
                  }
                  trigger={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <div style={{ width: '100%' }}>
                      <Button
                        content={i18n.t('reset')}
                        color="red"
                        fluid
                        loading={isLoadingTSEExport}
                        onClick={async () => {
                          await resetTSE();
                        }}
                        disabled={
                          zBons.data.length === 0 ||
                          selectedMonth.isSame(moment(), 'month') ||
                          exportsData.hasTSEExport === false
                        }
                        style={{ fontFamily: 'Montserrat' }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );

  return (
    <>
      <div
        className="cockpit-title"
        style={{ position: matches ? '' : 'absolute', marginTop: matches ? 0 : 11 }}
      >
        {matches ? (
          <>
            <p className="cockpit-title">{i18n.t('documentsTitle')}</p>
            <AnimatedTabs
              tabs={[
                { id: 'zbons', label: i18n.t('closingZBons') },
                { id: 'exports', label: i18n.t('exportsTitle') },
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onChangeTab={() => {}}
            />
          </>
        ) : (
          <Dropdown
            style={{
              fontSize: 26,
              fontFamily: 'Montserrat',
              fontWeight: 700,
              height: 30,
              marginTop: 7,
              paddingLeft: 10,
            }}
            onChange={(_, data) => {
              if (data.value) {
                setSelectedTitleDropdownOption(
                  data.options.find((option) => option.value === data.value).text
                );
                setSelectedTab(tabs[data.value]);
              }
            }}
            className="icon products-dropdown"
            labeled
            options={titleDropdownOptions}
            text={selectedTitleDropdownOption}
          />
        )}
      </div>
      {selectedTab === tabs.zbons ? <>{parseZBonsTab()}</> : <></>}
      {selectedTab === tabs.exports ? <>{parseExportsTab()}</> : <></>}
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
      <ZBonScreenModal
        onClose={onCloseZBonModal}
        open={zBonModalOptions.open}
        savedZBon={zBonModalOptions.zBon}
        withCoins={false}
        selectedShop={selectedShop}
      />
    </>
  );
};

export default FranchiseDocumentsScreen;
