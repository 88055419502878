/* eslint-disable no-nested-ternary */
import i18n from '../../../i18n/config';
import { abbreviate, asCurrency } from '../../../utils/NumberFormatter';

export const centerTextPlugin = {
  id: 'centerTextPlugin',
  beforeDraw: (chart) => {
    const { ctx, width, height } = chart.chart;
    ctx.save();
    const lines = [
      {
        text: i18n.t('total'),
        color: '#7268cd',
        font: '600 15px Montserrat, sans-serif',
        offset: -20,
      },
      {
        text: chart.config.options.centerText,
        color: '#000',
        font: '700 18px Montserrat, sans-serif',
        offset: 4,
      },
    ];

    const centerX = width / 2;
    const centerY = height / 2;

    lines.forEach((line) => {
      ctx.fillStyle = line.color;
      ctx.font = line.font;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(line.text, centerX, centerY + line.offset);
    });

    ctx.restore();
  },
};

export const doughnutChartData = (doughnutChart) => ({
  labels: doughnutChart.labels,
  datasets: [
    {
      data:
        doughnutChart.data[0] !== null &&
        doughnutChart.data[0] === 0 &&
        doughnutChart.data[1] !== null &&
        doughnutChart.data[1] === 0
          ? [1, 0]
          : doughnutChart.data.map((iData) => abbreviate(iData)),
      backgroundColor:
        doughnutChart.data[0] !== null &&
        doughnutChart.data[0] === 0 &&
        doughnutChart.data[1] !== null &&
        doughnutChart.data[1] === 0
          ? ['#7268cd80', '#7268cd80']
          : ['#7268cd', '#7268cd'],
      hoverBackgroundColor: ['#7268cd80', '#7268cd80'],
      borderColor: ['#fff', '#fff'],
      borderWidth: [2, 2],
    },
  ],
});

export const doughnutChartOptions = {
  responsive: true,
  legend: { display: false },
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 90,
};

export const lineChartData = (lineChart, canvas) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(600, 0, 300, 300);
  gradient.addColorStop(0, 'rgba(114, 104, 205, 0.5)');
  gradient.addColorStop(1, 'rgba(114, 104, 205, 1)');
  return {
    labels: lineChart.labels,
    datasets: [
      {
        data: lineChart.data,
        backgroundColor: gradient,
        borderColor: gradient,
        pointBorderColor: gradient,
        pointBackgroundColor: gradient,
        pointHoverBackgroundColor: gradient,
        pointHoverBorderColor: gradient,
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };
};

export const lineChartOptions = (lineChart) => ({
  maintainAspectRatio: true,
  responsive: true,
  legend: { display: false },
  tooltips: {
    displayColors: false,
    bodyFontSize: 16,
    xPadding: 10,
    yPadding: 10,
    backgroundColor: '#7268cd',
    callbacks: {
      label: ({ value }) =>
        `${value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100)} €`,
      title: () => '',
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          display: !!(
            lineChart &&
            lineChart.length > 0 &&
            lineChart.reduce((sum, chart) => sum + chart, 0) > 0
          ),
          callback: (value) =>
            value < 1000 && value > 0 ? asCurrency(value / 100) : abbreviate(value / 100),
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: '#fff',
          zeroLineColor: '#fff',
        },
      },
    ],
  },
});

export const horizontalBarChartData = (horizontalChart) => ({
  labels: horizontalChart.labels,
  datasets: [
    {
      data:
        horizontalChart.dataSet[0] !== null &&
        horizontalChart.dataSet[0] === 0 &&
        horizontalChart.dataSet[1] !== null &&
        horizontalChart.dataSet[1] === 0
          ? [1, 1]
          : horizontalChart.dataSet,
      backgroundColor:
        horizontalChart.dataSet[0] !== null &&
        horizontalChart.dataSet[0] === 0 &&
        horizontalChart.dataSet[1] !== null &&
        horizontalChart.dataSet[1] === 0
          ? ['#7268cd80', '#7268cd80']
          : ['#7268cd', '#7268cd'],
      borderColor: ['#7268cd', '#7268cd'],
      hoverBackgroundColor: ['#7268cd80', '#7268cd80'],
    },
  ],
});

export const horizontalBarChartOptions = () => ({
  maintainAspectRatio: true,
  responsive: true,
  legend: { display: false },
  tooltips: {
    enabled: false,
  },
  scales: {
    yAxes: [
      {
        barPercentage: 0.65,
        gridLines: {
          display: false,
        },
        ticks: {
          callback: (value) =>
            value ? (value.length > 23 ? `${value.substr(0, 23)}...` : value) : value,
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 0.1,
        gridLines: {
          display: false,
        },
        ticks: {
          callback: () => '',
          beginAtZero: true,
        },
      },
    ],
  },
});
