/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { Button, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import { useMediaQuery } from '@react-hook/media-query';
import i18n from '../../../i18n/config';
import refreshIcon from '../../shop/Bookings/icons/refresh.png';
import { useStateValue } from '../../../core/context/StateProvider';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import { getCashBookExcel, getMonthlyReportPDF, getZBons } from '../../../api/BookingAPI';
import { deleteDSFinVKExport, getCPCExportLink, getOrCreateCPCExport } from '../../../api/CPCAPI';
import { deleteTSEExport, getOrCreateTSEExport, getTSEExportLink } from '../../../api/TSEAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';

const ExportsScreen = (props) => {
  const [{ token, shop }] = useStateValue();
  const [rotations, setRotations] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [zBons, setZBons] = useState({ data: [] });
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [exportsData, setExportsData] = useState({
    hasCPCExport: false,
    cpcExportId: null,
    hasTSEExport: false,
    tseExportId: null,
  });
  const [isLoadingCPCExport, setIsLoadingCPCExport] = useState(false);
  const [isLoadingTSEExport, setIsLoadingTSEExport] = useState(false);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  useEffect(() => {
    loadZBons(moment());
  }, []);

  const loadZBons = async (date) => {
    try {
      setIsLoading(true);
      setSelectedMonth(moment(date));
      const res = await getZBons(token, shop._id, moment(date).format());
      setIsLoading(false);
      if (res && res.success) {
        setZBons({ data: res.zBons });
        setExportsData({
          hasCPCExport: res.hasCPCExport || false,
          cpcExportId: res.cpcExportId || null,
          hasTSEExport: res.hasTSEExport || false,
          tseExportId: res.tseExportId || null,
        });
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('exportsError'),
        });
      }
    } catch (error) {
      setIsLoading(false);
      setErrorWarningModalOptions({ open: true, message: i18n.t('exportsError') });
    }
  };

  const downloadMonthlyReport = async () => {
    try {
      await getMonthlyReportPDF(token, shop._id, moment(selectedMonth).format());
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const downloadCashBook = async () => {
    try {
      await getCashBookExcel(token, moment(selectedMonth).format());
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('monthlyReportError'),
      });
    }
  };

  const downloadDSFinVK = async () => {
    try {
      setIsLoadingCPCExport(true);
      const firstRes = await getOrCreateCPCExport(token, selectedMonth.unix(), shop._id);
      if (firstRes.success === false && firstRes.error && firstRes.error.includes('Pending CPC')) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError5'),
        });
        return;
      }
      if (firstRes.success === false && firstRes.error && firstRes.error.includes('Error CPC')) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError6'),
        });
        return;
      }
      if (firstRes.success === false || firstRes.exportId == null) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError2'),
        });
        return;
      }
      const { exportId } = firstRes;
      const secondRes = await getCPCExportLink(token, exportId, shop._id);
      if (secondRes.success === false) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError3'),
        });
        return;
      }
      if (secondRes.success === true && secondRes.isPending === true) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError4'),
        });
      }
      if (secondRes.success === true && secondRes.downloaded === true) {
        setExportsData({ ...exportsData, hasCPCExport: true, cpcExportId: exportId });
      }
    } catch (error) {
      console.error(error);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('cpcDownloadError1')}`,
      });
    } finally {
      setIsLoadingCPCExport(false);
    }
  };

  const resetDSFinVK = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('resetDsfinvkWarningTitle'),
      message: i18n.t('resetDsfinvkWarning1'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsLoadingCPCExport(true);
        try {
          const res = await deleteDSFinVKExport(token, selectedMonth.unix(), shop._id);
          if (res == null || res.sucess === false) {
            throw new Error('DSFinVK Export reset failed or returned invalid response.');
          }
          setExportsData({ ...exportsData, hasCPCExport: false, cpcExportId: null });
        } catch (error) {
          console.error(error);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('exportResetError'),
          });
        } finally {
          setIsLoadingCPCExport(false);
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const downloadTSE = async () => {
    try {
      setIsLoadingTSEExport(true);
      const firstRes = await getOrCreateTSEExport(token, selectedMonth.unix(), shop._id);
      if (firstRes.success === false || firstRes.exportId == null) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError2'),
        });
        return;
      }
      const { exportId } = firstRes;
      const secondRes = await getTSEExportLink(token, exportId, shop._id);
      if (secondRes.success === false) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError3'),
        });
        return;
      }
      if (secondRes.success === true && secondRes.isPending === true) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('cpcDownloadError4'),
        });
      }
      if (secondRes.success === true && secondRes.downloaded === true) {
        setExportsData({ ...exportsData, hasTSEExport: true, tseExportId: exportId });
      }
    } catch (error) {
      console.error(error);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('cpcDownloadError1')}`,
      });
    } finally {
      setIsLoadingTSEExport(false);
    }
  };

  const resetTSE = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('resetTsekWarningTitle'),
      message: i18n.t('resetTsekWarning1'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsLoadingTSEExport(true);
        try {
          const res = await deleteTSEExport(token, selectedMonth.unix(), shop._id);
          if (res == null || res.sucess === false) {
            throw new Error('TSE Export reset failed or returned invalid response.');
          }
          setExportsData({ ...exportsData, hasTSEExport: false, tseExportId: null });
        } catch (error) {
          console.error(error);
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('exportResetError'),
          });
        } finally {
          setIsLoadingTSEExport(false);
          closeWarningModal();
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const parseExports = () => (
    <>
      <div style={{ display: 'flex', marginTop: matches ? 0 : 16 }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginTop: 0,
            }}
          >
            <div className="bookings-items-toolbar">
              <img
                src={refreshIcon}
                alt=""
                style={{
                  cursor: 'pointer',
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  transform: `rotate(${rotations * 360 * -1}deg)`,
                  transition: 'transform 0.5s ease',
                }}
                onClick={async () => {
                  setRotations((prev) => prev + 1);
                  await loadZBons();
                }}
              />
            </div>
          </div>
          <div
            className="bookings-container-grid"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 0,
              height: 'unset',
            }}
          >
            <div
              className="bookings-header-container"
              style={{
                justifyContent: 'space-between',
                paddingBottom: 4,
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 700, fontSize: 20, marginBottom: 0 }}>{shop.name}</p>
              <Popup
                trigger={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <p style={{ color: '#7268cd', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>
                    {moment(selectedMonth).format('MMM YYYY').toUpperCase().replace('.', '')}
                    <Icon name="caret down" />
                  </p>
                }
                style={{ height: 220 }}
                on="click"
                position="bottom left"
              >
                <Grid centered columns={1}>
                  <Grid.Column textAlign="center">
                    <p style={{ fontWeight: 600 }}>{i18n.t('monthSelection')}</p>
                    <div style={{ display: 'table', margin: '0 auto', height: 220 }}>
                      <ReactDatePicker
                        inline
                        locale="de"
                        container
                        wrapperClassName="datepicker"
                        showMonthYearPicker
                        selected={moment(selectedMonth).toDate()}
                        minDate={moment(shop && shop.created ? shop.created : null)
                          .startOf('month')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={async (selectedDate) => {
                          setSelectedMonth(moment(selectedDate));
                          await loadZBons(moment(selectedDate));
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
              </Popup>
            </div>
          </div>
        </div>
      </div>
      <div className="bookings-grid-items-owner" style={{ height: 'calc(100vh - 175px)' }}>
        <Grid stackable columns={2} stretched>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>
                  {i18n.t('monthlyReport')}
                </p>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('monthlyReportSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                  <Button
                    content={i18n.t('appPaySuccess7')}
                    color="teal"
                    fluid
                    onClick={async () => {
                      await downloadMonthlyReport();
                    }}
                    disabled={zBons.data.length === 0}
                    style={{ fontFamily: 'Montserrat' }}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>
                  {i18n.t('dailySummaryCashBook')}
                </p>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('cashbookSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
                  <Button
                    content={i18n.t('appPaySuccess7')}
                    color="teal"
                    fluid
                    onClick={async () => {
                      await downloadCashBook();
                    }}
                    disabled={
                      zBons.data.filter((zBon) => zBon.cashBook != null).length === 0 ||
                      shop.allowCashBook === false
                    }
                    style={{ fontFamily: 'Montserrat' }}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  DSFinV-K
                  <Popup
                    on={['hover', 'click']}
                    // eslint-disable-next-line prettier/prettier
                    trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                    content={i18n.t('infoDSFinVK')}
                    popper={<div style={{ filter: 'none' }} />}
                  />
                </div>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('dsfinvkSubtitle')}
                </p>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader active={isLoading} inline inverted className="bookings-create-loader" />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                  <Popup
                    on={['hover', 'click']}
                    content={
                      zBons.data.length === 0
                        ? i18n.t('dsfinvkError1')
                        : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                        ? i18n.t('dsfinvkError2')
                        : selectedMonth.isSame(moment(), 'month')
                        ? i18n.t('dsfinvkError3')
                        : i18n.t('dsfinvkError4')
                    }
                    basic
                    position="top right"
                    popper={<div style={{ filter: 'none' }} />}
                    disabled={
                      zBons.data.length !== 0 &&
                      zBons.data.find((zbon) => zbon.cpcClosingId != null) != null &&
                      selectedMonth.isSame(moment(), 'month') === false
                    }
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <div style={{ width: '100%' }}>
                        <Button
                          content={i18n.t('appPaySuccess7')}
                          color="teal"
                          fluid
                          loading={isLoadingCPCExport}
                          onClick={async () => {
                            await downloadDSFinVK();
                          }}
                          disabled={
                            zBons.data.length === 0 ||
                            zBons.data.find((zbon) => zbon.cpcClosingId != null) == null ||
                            selectedMonth.isSame(moment(), 'month')
                          }
                          style={{ fontFamily: 'Montserrat' }}
                        />
                      </div>
                    }
                  />
                  <Popup
                    on={['hover', 'click']}
                    content={
                      zBons.data.length === 0
                        ? i18n.t('dsfinvkError1')
                        : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                        ? i18n.t('dsfinvkError2')
                        : selectedMonth.isSame(moment(), 'month')
                        ? i18n.t('dsfinvkError3')
                        : exportsData.hasCPCExport === false
                        ? i18n.t('dsfinvkError5')
                        : i18n.t('dsfinvkError4')
                    }
                    basic
                    position="top right"
                    popper={<div style={{ filter: 'none' }} />}
                    disabled={
                      zBons.data.length !== 0 &&
                      zBons.data.find((zbon) => zbon.cpcClosingId != null) != null &&
                      selectedMonth.isSame(moment(), 'month') === false &&
                      exportsData.hasCPCExport === true
                    }
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <div style={{ width: '100%' }}>
                        <Button
                          content={i18n.t('reset')}
                          color="red"
                          fluid
                          onClick={async () => {
                            await resetDSFinVK();
                          }}
                          loading={isLoadingCPCExport}
                          disabled={
                            zBons.data.length === 0 ||
                            zBons.data.find((zbon) => zbon.cpcClosingId != null) == null ||
                            selectedMonth.isSame(moment(), 'month') ||
                            exportsData.hasCPCExport === false
                          }
                          style={{ fontFamily: 'Montserrat' }}
                        />
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            verticalAlign="top"
            stretched
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 14,
                boxShadow: 'rgb(114 104 205 / 20%) 0px 1px 5px',
                transitionProperty: 'box-shadow, transform',
                padding: 16,
                transform: 'translateZ(0)',
                wordBreak: 'break-all',
                whiteSpace: 'initial',
                minHeight: 140,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginBottom: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  TSE
                  <Popup
                    on={['hover', 'click']}
                    // eslint-disable-next-line prettier/prettier
                    trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                    content={i18n.t('infoTSE')}
                    popper={<div style={{ filter: 'none' }} />}
                  />
                </div>
                <p style={{ color: '#666', fontWeight: 500, fontSize: 13 }}>
                  {i18n.t('tseSubtitle')}
                </p>
              </div>
              <div style={{ display: 'flex', gap: 4, justifyContent: 'space-between' }}>
                <Popup
                  on={['hover', 'click']}
                  content={
                    zBons.data.length === 0
                      ? i18n.t('dsfinvkError1')
                      : selectedMonth.isSame(moment(), 'month')
                      ? i18n.t('dsfinvkError3')
                      : i18n.t('dsfinvkError4')
                  }
                  basic
                  position="top right"
                  popper={<div style={{ filter: 'none' }} />}
                  disabled={
                    zBons.data.length !== 0 && selectedMonth.isSame(moment(), 'month') === false
                  }
                  trigger={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <div style={{ width: '100%' }}>
                      <Button
                        content={i18n.t('appPaySuccess7')}
                        color="teal"
                        fluid
                        loading={isLoadingTSEExport}
                        onClick={async () => {
                          await downloadTSE();
                        }}
                        disabled={
                          zBons.data.length === 0 || selectedMonth.isSame(moment(), 'month')
                        }
                        style={{ fontFamily: 'Montserrat' }}
                      />
                    </div>
                  }
                />
                <Popup
                  on={['hover', 'click']}
                  content={
                    zBons.data.length === 0
                      ? i18n.t('dsfinvkError1')
                      : zBons.data.find((zbon) => zbon.cpcClosingId != null) == null
                      ? i18n.t('dsfinvkError2')
                      : selectedMonth.isSame(moment(), 'month')
                      ? i18n.t('dsfinvkError3')
                      : exportsData.hasTSEExport === false
                      ? i18n.t('dsfinvkError6')
                      : i18n.t('dsfinvkError4')
                  }
                  basic
                  position="top right"
                  popper={<div style={{ filter: 'none' }} />}
                  disabled={
                    zBons.data.length !== 0 &&
                    selectedMonth.isSame(moment(), 'month') === false &&
                    exportsData.hasTSEExport === true
                  }
                  trigger={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <div style={{ width: '100%' }}>
                      <Button
                        content={i18n.t('reset')}
                        color="red"
                        fluid
                        loading={isLoadingTSEExport}
                        onClick={async () => {
                          await resetTSE();
                        }}
                        disabled={
                          zBons.data.length === 0 ||
                          selectedMonth.isSame(moment(), 'month') ||
                          exportsData.hasTSEExport === false
                        }
                        style={{ fontFamily: 'Montserrat' }}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );

  return (
    <>
      <div
        className="cockpit-title"
        style={{ position: matches ? '' : 'absolute', marginTop: matches ? 0 : 11 }}
      >
        <p className="cockpit-title">{i18n.t('exportsTitle')}</p>
        <p className="cockpit-subtitle">{i18n.t('exportsSubtitle')}</p>
      </div>
      {parseExports()}
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
    </>
  );
};

export default ExportsScreen;
