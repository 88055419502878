/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { useStateValue } from '../../../core/context/StateProvider';
import i18n from '../../../i18n/config';
import sidebarIcon from './Icons/sidebar-logo.png';

/// Sidebar component.
/// Takes props as an array of SidebarItem objects, used as 'items'
const Sidebar = (props) => {
  const { items, currentIndex, isAdmin } = props;
  const [selectedIndex, setSelectedIndex] = useState(currentIndex);
  const [click, setClick] = useState(false);
  const [{ token, user, shop }, dispatch] = useStateValue();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  let sidebar = click ? 'sidebar active' : 'sidebar';
  const sidebarMenuIcon =
    user && (user.role === 'employee' || user.role === 'manager')
      ? 'sidebar-menu-icon-employee'
      : 'sidebar-menu-icon';
  if (user && (user.role === 'employee' || user.role === 'manager')) {
    sidebar = click ? 'sidebar-employee active' : 'sidebar-employee';
  }

  return (
    <>
      <nav>
        <div className={sidebarMenuIcon} onClick={handleClick}>
          <Icon name={click ? 'close' : 'bars'} />
        </div>
        <div className={sidebar}>
          <div
            style={{ display: click ? 'none' : 'flex', width: '100%', justifyContent: 'center' }}
          >
            <img src={sidebarIcon} alt="moaby-logo" style={{ height: 44, marginTop: 10 }} />
          </div>
          <ul className={click ? 'sidebar-list active' : 'sidebar-list'}>
            {items.map((item, index) => (
              <li key={item.title} className="sidebar-item">
                <Link
                  to={item.linkTo}
                  className="sidebar-link"
                  onClick={() => {
                    setSelectedIndex(index);
                    closeMobileMenu();
                  }}
                >
                  <Popup
                    style={{
                      padding: 8,
                      borderRadius: 8,
                      backgroundColor: '#eeecfc',
                      color: '#6c7688',
                      border: 'none',
                      fontWeight: 500,
                    }}
                    on={['hover', 'click']}
                    content={item.title}
                    basic
                    position="right center"
                    popper={<div style={{ filter: 'none' }} />}
                    hoverable
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <div
                        style={{
                          margin: 2,
                          borderRadius: 8,
                          padding: 6,
                          paddingBottom: 1,
                          backgroundColor: selectedIndex === index ? '#eeecfc' : '',
                        }}
                        className="sidebar-item-box"
                      >
                        <img
                          src={selectedIndex === index ? item.iconSelected : item.iconUnselected}
                          alt={item.title}
                          className="sidebar-icon"
                        />
                      </div>
                    }
                  />
                </Link>
                {isAdmin === true && (
                  <>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: 10,
                        cursor: 'pointer',
                        wordBreak: 'break-all',
                      }}
                    >
                      {item.title}
                    </p>
                  </>
                )}
              </li>
            ))}
            <div
              className="mobile-buttons"
              style={{
                overflow: 'hidden',
              }}
            >
              <li key="logout1" className="sidebar-item">
                <Button
                  id="sidebar-btn"
                  size="large"
                  className="sidebar-btn"
                  content={(
                    sessionStorage.getItem('language') ||
                    navigator.language.substring(0, 2) ||
                    ''
                  ).toUpperCase()}
                  onClick={() => {
                    const currentLang =
                      sessionStorage.getItem('language') || navigator.language.substring(0, 2);
                    i18n.changeLanguage(currentLang === 'en' ? 'de' : 'en');
                    sessionStorage.setItem('language', currentLang === 'en' ? 'de' : 'en');
                    window.location.reload();
                  }}
                />
              </li>
              {user.role === 'owner' && !user.isAdmin ? (
                <>
                  <li key="help" className="sidebar-item">
                    <Button
                      size="huge"
                      id="sidebar-btn"
                      className="sidebar-btn"
                      onClick={() => {
                        window.location.href = `mailto:info@moaby.app?subject=Hilfe%3A%20%5BDein%20Thema%5D&body=Hallo%20moaby%20Team%2C%0D%0A%0D%0AIch%20brauche%20hilfe%20mit%20%5BDeine%20Beschreibung%5D.%0D%0A%0D%0AShop%3A%20${
                          shop ? shop.name : ''
                        }%0D%0AShop%20ID%3A%20${
                          shop ? shop._id : ''
                        }%0D%0A%0D%0AVielen%20Dank%2C%0D%0A${
                          shop ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}` : ''
                        }`;
                      }}
                      icon
                    >
                      <Icon name="help circle" />
                    </Button>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li key="logout" className="sidebar-item">
                <Button
                  id="sidebar-btn"
                  size="huge"
                  className="sidebar-btn"
                  onClick={() => {
                    dispatch({
                      type: 'logout',
                    });
                    window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_SHOPS_SUBDOMAIN}.${process.env.REACT_APP_QR_WEBSITE}/login`;
                  }}
                  icon
                >
                  <Icon name="sign out" />
                </Button>
              </li>
            </div>
          </ul>
          <p
            style={{
              width: 60,
              position: 'fixed',
              bottom: 10,
              textAlign: 'center',
              fontSize: 9,
              fontWeight: 400,
            }}
          >
            {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
