/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import { Modal, Button, Dropdown, Icon } from 'semantic-ui-react';
import useScanDetection from 'use-scan-detection';
import { isEqual } from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';
import { getCategories, getAllAllergens } from './ItemsScreenData';
import { useStateValue } from '../../../core/context/StateProvider';
import './ItemsScreen.css';
import { createItem, deleteItem, getOFFProduct, updateItem } from '../../../api/ItemAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import PriceInput from '../../../components/general/PriceInput/PriceInput';
import BarcodeView from '../../../components/general/BarcodeView/BarcodeView';
import AvailabilityButtons from './AvailabilityButtons';

const NewItemsScreenModal = (props) => {
  const { item, onClose, open } = props;
  const [{ config, token, shop }] = useStateValue();
  const availabilityOptions = {
    not: 'not',
    all: 'all',
    terminal: 'terminal',
    qr: 'qr',
  };
  const [data, setData] = useState({
    item: {
      title: '',
      price: 0,
      description: '',
      barcode: '',
      ingredients: [],
      allergens: [],
      optionGroups: [],
      specialSelections: [],
      inHouseTaxRate: 0.19,
      takeAwayTaxRate: 0.19,
      availability: availabilityOptions.all,
      categoryName: '',
      categoryId: '',
      picture: '',
      pfand: 0,
      shopId: '',
      isDynamicPriced: false,
      dynamicPriceMin: 0,
      dynamicPriceMax: 0,
    },
  });
  const [dropDownData, setDropDownData] = useState({ categories: [], allergens: [] });
  const [newIngredient, setNewIngredient] = useState('');
  const [newSpecialSelection, setNewSpecialSelection] = useState({ name: '', price: 0 });
  const [newOptionGroup, setNewOptionGroup] = useState({ title: '', options: [] });
  const [newOption, setNewOption] = useState([]);
  const [image, setImage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const barcodeRef = useRef();

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (open && item) {
      setData({ item });
    }
  }, [item, open]);

  useEffect(() => {
    if (
      data.item.title.length !== 0 &&
      (data.item.price !== null || data.item.price !== undefined) &&
      data.item.categoryName.length !== 0 &&
      data.item.categoryId.length !== 0 &&
      (item == null ||
        (item != null &&
          (item.title !== data.item.title ||
            item.price !== data.item.price ||
            item.description !== data.item.description ||
            item.barcode !== data.item.barcode ||
            item.inHouseTaxRate !== data.item.inHouseTaxRate ||
            item.takeAwayTaxRate !== data.item.takeAwayTaxRate ||
            item.availability !== data.item.availability ||
            item.categoryName !== data.item.categoryName ||
            item.categoryId !== data.item.categoryId ||
            item.picture !== data.item.picture ||
            item.pfand !== data.item.pfand ||
            item.isDynamicPriced !== data.item.isDynamicPriced ||
            item.dynamicPriceMin !== data.item.dynamicPriceMin ||
            item.dynamicPriceMax !== data.item.dynamicPriceMax ||
            !isEqual(item.ingredients, data.item.ingredients) ||
            !isEqual(item.allergens, data.item.allergens) ||
            !isEqual(item.optionGroups, data.item.optionGroups) ||
            !isEqual(item.specialSelections, data.item.specialSelections))))
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [data.item]);

  const closeModal = (refreshNeeded) => {
    setData({
      item: {
        title: '',
        price: 0,
        description: '',
        barcode: '',
        ingredients: [],
        allergens: [],
        optionGroups: [],
        specialSelections: [],
        inHouseTaxRate: 0.19,
        takeAwayTaxRate: 0.19,
        availability: availabilityOptions.all,
        categoryName: '',
        categoryId: '',
        picture: '',
        shopId: '',
        isDynamicPriced: false,
        dynamicPriceMin: 0,
        dynamicPriceMax: 0,
      },
    });
    setNewIngredient('');
    setNewSpecialSelection({ name: '', price: 0 });
    setNewOptionGroup({ title: '', options: [] });
    setNewOption([]);
    setImage(null);
    setErrorWarningModalOptions({ open: false, message: '' });
    setIsSaveDisabled(true);
    setIsLoading(false);
    closeWarningModal();
    setIsDeleteLoading(false);
    barcodeRef.current = null;
    onClose(refreshNeeded);
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  useScanDetection({
    minLength: 8,
    onComplete: async (barcode) => {
      setData({ item: { ...data.item, barcode } });
      try {
        const res = await getOFFProduct(barcode, token);
        if (res && res.success) {
          const newItem = { ...item };
          const resItem = res.item;
          if (resItem.barcode) {
            newItem.barcode = resItem.barcode;
          }
          if (resItem.title && resItem.title.length > 0) {
            newItem.title = resItem.title;
          }
          if (
            resItem.categoryName &&
            resItem.categoryName.length > 0 &&
            resItem.categoryId &&
            resItem.categoryId.length > 0
          ) {
            newItem.categoryName = resItem.categoryName;
            newItem.categoryId = resItem.categoryId;
          }
          if (resItem.description && resItem.description.length > 0) {
            newItem.description = resItem.description;
          }
          if (resItem.ingredients && resItem.ingredients.length > 0) {
            newItem.ingredients = resItem.ingredients;
          }
          if (resItem.allergens && resItem.allergens.length > 0) {
            newItem.allergens = resItem.allergens;
          }
          setData({ item: { ...data.item, ...newItem } });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDelete = async () => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('itemsDeleteWarningTitle'),
      message: i18n.t('itemsDeleteWarningMessage'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        setIsDeleteLoading(true);
        const res = await deleteItem(token, item._id);
        if (res && res.success) {
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('itemDeleteError'),
          });
          setIsDeleteLoading(false);
          setWarningModalOptions({
            open: false,
            title: '',
            message: '',
            ok: false,
            onOk: null,
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        }
      },
      no: true,
      onNo: () => {
        setIsDeleteLoading(false);
        setWarningModalOptions({
          open: false,
          title: '',
          message: '',
          ok: false,
          onOk: null,
          yes: false,
          onYes: null,
          no: false,
          onNo: null,
        });
      },
    });
  };

  const onSave = async () => {
    setIsLoading(true);
    let change = { shopId: shop._id };
    if (data.item.isDynamicPriced) {
      if (
        data.item.dynamicPriceMin >= data.item.dynamicPriceMax &&
        !(data.item.dynamicPriceMin === 0 && data.item.dynamicPriceMax === 0)
      ) {
        setErrorWarningModalOptions({
          open: true,
          message: `${i18n.t('minMaxPriceError')}`,
        });
        setIsLoading(false);
        return;
      }
    }
    if (item) {
      if (item.title !== data.item.title) {
        change.title = data.item.title;
      }
      if (item.price !== data.item.price) {
        change.price = data.item.price;
      }
      if (item.description !== data.item.description) {
        change.description = data.item.description;
      }
      if (item.barcode !== data.item.barcode) {
        change.barcode = data.item.barcode;
      }
      if (item.inHouseTaxRate !== data.item.inHouseTaxRate) {
        change.inHouseTaxRate = data.item.inHouseTaxRate;
      }
      if (item.takeAwayTaxRate !== data.item.takeAwayTaxRate) {
        change.takeAwayTaxRate = data.item.takeAwayTaxRate;
      }
      if (item.availability !== data.item.availability) {
        change.availability = data.item.availability;
      }
      if (item.categoryName !== data.item.categoryName) {
        change.categoryName = data.item.categoryName;
      }
      if (item.categoryId !== data.item.categoryId) {
        change.categoryId = data.item.categoryId;
      }
      if (item.picture !== data.item.picture) {
        change.picture = data.item.picture;
      }
      if (item.pfand !== data.item.pfand) {
        change.pfand = data.item.pfand;
      }
      if (item.isDynamicPriced !== data.item.isDynamicPriced) {
        change.isDynamicPriced = data.item.isDynamicPriced;
      }
      if (item.dynamicPriceMin !== data.item.dynamicPriceMin) {
        change.dynamicPriceMin = data.item.dynamicPriceMin;
      }
      if (item.dynamicPriceMax !== data.item.dynamicPriceMax) {
        change.dynamicPriceMax = data.item.dynamicPriceMax;
      }
      if (!isEqual(item.ingredients, data.item.ingredients)) {
        change.ingredients = data.item.ingredients;
      }
      if (!isEqual(item.allergens, data.item.allergens)) {
        change.allergens = data.item.allergens;
      }
      if (!isEqual(item.optionGroups, data.item.optionGroups)) {
        change.optionGroups = data.item.optionGroups;
      }
      if (!isEqual(item.specialSelections, data.item.specialSelections)) {
        change.specialSelections = data.item.specialSelections;
      }
    } else {
      change = { ...change, ...data.item };
      change.shopId = shop._id;
    }
    if (change._id) {
      change._id = null;
      delete change._id;
    }
    if (change.optionGroups && change.optionGroups.length > 0) {
      change.optionGroups = change.optionGroups.filter(
        (group) => group.options && group.options.length > 0
      );
    }
    try {
      const res = item
        ? await updateItem(token, item._id, change)
        : await createItem(token, change);
      setIsLoading(false);
      if (res && res.success) {
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: item
            ? `${i18n.t('itemUpdateError')} ${res.error}`
            : `${i18n.t('itemCreateError')} ${res.error}`,
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: item ? `${i18n.t('itemUpdateError')}` : `${i18n.t('itemCreateError')}`,
      });
      setIsLoading(false);
    }
  };

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (dropDownData.categories != null) {
      const category = dropDownData.categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (dropDownData.categories !== null) {
      const category = dropDownData.categories.find((category) => {
        if (category.translations) {
          return category.translations.find((translation) => translation.val === categoryName);
        }
        return null;
      });
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  const getDropdownData = async () => {
    const cat = await getCategories(token);
    const all = await getAllAllergens();
    if (cat && all && cat.success && all.success) {
      setDropDownData({
        categories: cat.categories,
        allergens: all.allergens,
      });
    } else {
      setDropDownData({ categories: [] });
    }
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const shopDefinedCategories = categories
        .filter((cat) => cat.shopId != null)
        .map((category) => getCategoryTranslation(category))
        .sort();
      const generalCategories = categories
        .filter((cat) => cat.shopId == null)
        .map((category) => getCategoryTranslation(category))
        .sort();
      if (shopDefinedCategories.length > 0) {
        options.push({
          key: 'shopDefinedCategories',
          text: (
            <div style={{ color: '#7268cd', fontWeight: 700, opacity: 1, pointerEvents: 'none' }}>
              {i18n.t('shopDefinedCategories')}
            </div>
          ),
          value: 'shopDefinedCategories',
          disabled: true,
        });
        shopDefinedCategories.forEach((category, index) => {
          const object = {};
          object.key = `${category}-${index}`;
          object.value = category;
          object.text = category;
          options.push(object);
        });
        options.push({
          key: 'generalDefinedCategories',
          text: (
            <div style={{ color: '#7268cd', fontWeight: 700, opacity: 1, pointerEvents: 'none' }}>
              {i18n.t('generalDefinedCategories')}
            </div>
          ),
          value: 'generalDefinedCategories',
          disabled: true,
        });
      }
      generalCategories.forEach((category, index) => {
        const object = {};
        object.key = `${category}-${index}`;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const allergensDropdownOptions = (allergens) => {
    if (allergens) {
      const options = [];
      allergens.forEach((allergen, index) => {
        const object = {};
        object.key = index;
        object.value = allergen;
        object.text = allergen;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const onDropdownChange = (value) => {
    if (value) {
      const category = dropDownData.categories.find(
        (category) => category.name === getOriginalCategoryFromName(value)
      );
      setData({ item: { ...data.item, categoryName: category.name, categoryId: category._id } });
    }
  };

  const onAllergensDropdownChange = (value) => {
    if (value) {
      setData({ item: { ...data.item, allergens: value } });
    }
  };

  const getEANValue = (value) => {
    if (value && value.length === 13) {
      return value.slice(0, 12);
    }
    if (value && value.length === 8) {
      return value.slice(0, 7);
    }
    return value;
  };

  const onFileUpload = (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 500000) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('itemImageError'),
        });
      } else {
        setImage(URL.createObjectURL(e.target.files[0]));
        const reader = new FileReader();
        reader.onload = (upload) =>
          setData({ item: { ...data.item, picture: upload.target.result } });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const parseImageSection = () => (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        minWidth: 300,
        maxWidth: '100%',
        padding: 12,
      }}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: 0,
          justifyContent: 'space-between',
        }}
      >
        <p className="items-modal-section-title">{i18n.t('itemsPicture')}</p>
        <Icon
          name="trash alternate outline"
          color="red"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setImage(null);
            setData({ item: { ...data.item, picture: 'none' } });
          }}
          disabled={data.item.picture.length === 0 || data.item.picture === 'none'}
        />
      </div>
      <label htmlFor="file">
        <div
          style={{
            height: 260,
            border: '1px dashed #dedede',
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 16,
            color: '#767676',
            fontWeight: 500,
            backgroundColor: '#f5f5f9',
            cursor: 'pointer',
            overflow: 'hidden',
          }}
        >
          {(data.item.picture.length > 0 && data.item.picture !== 'none') || image != null ? (
            <>
              <img
                alt="Product"
                src={
                  image ||
                  (data.item.picture !== 'none'
                    ? `${config.cloudinaryUrlPrefix}${data.item.picture}`
                    : null)
                }
                id="items-modal-image"
                htmlFor="file"
                style={{ objectFit: 'cover', height: 260, width: '100%' }}
              />
            </>
          ) : (
            <>
              <Icon name="image outline" size="big" color="grey" />
              <p>{i18n.t('itemsUploadImage')}</p>
            </>
          )}
        </div>
      </label>
      <input type="file" id="file" onChange={onFileUpload} accept="image/*" />
    </div>
  );

  const parseGeneralSection = () => (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        width: '100%',
        padding: 12,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: matches ? 'nowrap' : 'wrap',
        }}
      >
        <p className="items-modal-section-title">{i18n.t('generalSettingsTitle')}</p>
        <Button.Group
          style={{
            fontFamily: 'Montserrat',
            border: '1px solid #d7d3f6',
            borderRadius: 6,
            height: 26,
            overflow: 'hidden',
          }}
        >
          <Button
            className="item-modal-buttongroup-button"
            style={{
              borderRight: '1px solid #d7d3f6',
              backgroundColor: data.item.isDynamicPriced ? 'white' : '#e6e3fa',
              color: data.item.isDynamicPriced ? '#7268cdc2' : '#7268cd',
            }}
            onClick={() => {
              setData({
                item: {
                  ...data.item,
                  isDynamicPriced: false,
                },
              });
            }}
          >
            Fix
          </Button>
          <Button
            className="item-modal-buttongroup-button"
            style={{
              backgroundColor: data.item.isDynamicPriced ? '#e6e3fa' : 'white',
              color: data.item.isDynamicPriced ? '#7268cd' : '#7268cdc2',
              height: 20,
            }}
            onClick={() => {
              setData({
                item: {
                  ...data.item,
                  isDynamicPriced: true,
                  availability:
                    data.item.availability === availabilityOptions.all ||
                    data.item.availability === availabilityOptions.qr
                      ? availabilityOptions.terminal
                      : data.item.availability,
                },
              });
            }}
          >
            {i18n.t('flexPrice')}
          </Button>
        </Button.Group>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 22,
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 20,
          flexWrap: matches ? 'nowrap' : 'wrap',
        }}
      >
        <div style={{ width: '100%' }}>
          <p style={{ fontWeight: 600 }}>{i18n.t('itemsTitle')}*</p>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsTitle')}
            value={data.item.title}
            onChange={(e) => setData({ item: { ...data.item, title: e.target.value } })}
          />
        </div>
        <div style={{ width: matches ? '50%' : '100%' }}>
          {data.item.isDynamicPriced ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600 }}>Min</p>
                <PriceInput
                  price={data.item.dynamicPriceMin}
                  onChange={(value) => setData({ item: { ...data.item, dynamicPriceMin: value } })}
                />
              </div>
              <div style={{ width: '100%' }}>
                <p style={{ fontWeight: 600 }}>Max</p>
                <PriceInput
                  price={data.item.dynamicPriceMax}
                  onChange={(value) => setData({ item: { ...data.item, dynamicPriceMax: value } })}
                />
              </div>
            </div>
          ) : (
            <>
              <p style={{ fontWeight: 600 }}>{i18n.t('itemsPrice')}*</p>
              <PriceInput
                price={data.item.price}
                onChange={(value) => setData({ item: { ...data.item, price: value } })}
              />
            </>
          )}
        </div>
      </div>
      <p style={{ fontWeight: 600 }}>{i18n.t('itemsDescription')}</p>
      <textarea
        style={{
          fontFamily: 'Montserrat',
          width: '100%',
          border: '1px solid #dedede',
          backgroundColor: '#f5f5f9',
          borderRadius: 10,
          resize: 'none',
          padding: 10,
          height: '100%',
        }}
        value={data.item.description}
        className="item-modal-textarea"
        placeholder={i18n.t('itemsDescription')}
        onChange={(e) => setData({ item: { ...data.item, description: e.target.value } })}
      />
    </div>
  );

  const parseLeftSide = () => (
    <div
      style={{
        minWidth: 300,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsCategory')}*</p>
        <Dropdown
          selection
          placeholder={i18n.t('itemsCategories')}
          fluid
          value={getCategoryTranslationForName(data.item.categoryName)}
          onChange={(_, data) => onDropdownChange(data.value)}
          options={dropdownOptions(dropDownData.categories)}
          loading={dropDownData.categories.length === 0}
          className="items-modal-dropdown-new"
          id="items-dropdown"
          style={{ marginTop: 0 }}
        />
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('taxRate')}</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 14,
            alignItems: 'baseline',
          }}
        >
          <p style={{ fontWeight: 600, margin: 0 }}>In-House</p>
          <Button.Group
            style={{
              fontFamily: 'Montserrat',
              border: '1px solid #d7d3f6',
              borderRadius: 6,
              height: 28,
              overflow: 'hidden',
              marginBottom: 8,
            }}
          >
            <Button
              className="item-modal-buttongroup-button"
              style={{
                borderRight: '1px solid #d7d3f6',
                backgroundColor: data.item.inHouseTaxRate === 0.07 ? 'white' : '#e6e3fa',
                color: data.item.inHouseTaxRate === 0.07 ? '#7268cdc2' : '#7268cd',
              }}
              onClick={() => {
                setData({
                  item: {
                    ...data.item,
                    inHouseTaxRate: 0.19,
                  },
                });
              }}
            >
              19%
            </Button>
            <Button
              className="item-modal-buttongroup-button"
              style={{
                backgroundColor: data.item.inHouseTaxRate === 0.07 ? '#e6e3fa' : 'white',
                color: data.item.inHouseTaxRate === 0.07 ? '#7268cd' : '#7268cdc2',
              }}
              onClick={() => {
                setData({
                  item: {
                    ...data.item,
                    inHouseTaxRate: 0.07,
                  },
                });
              }}
            >
              7%
            </Button>
          </Button.Group>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 14,
            alignItems: 'baseline',
          }}
        >
          <p style={{ fontWeight: 600, margin: 0 }}>Take-Away</p>
          <Button.Group
            style={{
              fontFamily: 'Montserrat',
              border: '1px solid #d7d3f6',
              borderRadius: 6,
              height: 28,
              overflow: 'hidden',
              marginBottom: 8,
            }}
          >
            <Button
              className="item-modal-buttongroup-button"
              style={{
                borderRight: '1px solid #d7d3f6',
                backgroundColor: data.item.takeAwayTaxRate === 0.07 ? 'white' : '#e6e3fa',
                color: data.item.takeAwayTaxRate === 0.07 ? '#7268cdc2' : '#7268cd',
              }}
              onClick={() => {
                setData({
                  item: {
                    ...data.item,
                    takeAwayTaxRate: 0.19,
                  },
                });
              }}
            >
              19%
            </Button>
            <Button
              className="item-modal-buttongroup-button"
              style={{
                backgroundColor: data.item.takeAwayTaxRate === 0.07 ? '#e6e3fa' : 'white',
                color: data.item.takeAwayTaxRate === 0.07 ? '#7268cd' : '#7268cdc2',
              }}
              onClick={() => {
                setData({
                  item: {
                    ...data.item,
                    takeAwayTaxRate: 0.07,
                  },
                });
              }}
            >
              7%
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('generalAvailability')}</p>
        <AvailabilityButtons
          data={data}
          setData={setData}
          i18n={i18n}
          availabilityOptions={availabilityOptions}
          disabledIndices={data.item.isDynamicPriced ? [0, 2] : []}
        />
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">Pfand</p>
        <PriceInput
          price={data.item.pfand}
          onChange={(value) => setData({ item: { ...data.item, pfand: value } })}
        />
      </div>
      <div className="items-modal-group-section">
        <div
          style={{
            display: 'flex',
            marginBottom: 20,
            justifyContent: 'space-between',
          }}
        >
          <p className="items-modal-section-title">Barcode</p>
          <Icon
            name="download"
            style={{ cursor: 'pointer', color: '#7268cd' }}
            onClick={() => {
              if (barcodeRef && barcodeRef.current) {
                barcodeRef.current.downloadBarcode('PNG');
              }
            }}
            disabled={data.item.barcode == null || data.item.barcode === ''}
          />
        </div>
        <input
          className="item-modal-input"
          placeholder="Barcode"
          onKeyDown={(e) => {
            const notAllowed = ['.', '-', ',', '/', ' '];
            if (notAllowed.includes(e.key)) e.preventDefault();
          }}
          defaultValue={data.item.barcode}
          onChange={(e) => {
            setData({ item: { ...data.item, barcode: e.target.value } });
          }}
        />
        {data.item.barcode && data.item.barcode.length > 0 ? (
          <BarcodeView
            ref={barcodeRef}
            value={getEANValue(data.item.barcode)}
            name={data.item.title}
            options={{
              format:
                getEANValue(data.item.barcode).length === 7
                  ? 'EAN8'
                  : getEANValue(data.item.barcode).length === 12
                  ? 'EAN13'
                  : '',
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  const parseRightSide = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsSpecialSelections')}</p>
        {data.item.specialSelections.map((specialSelection, index) => (
          <Fragment key={`${specialSelection.name}-${index}`}>
            <div style={{ display: 'flex', gap: 16, marginBottom: 14 }}>
              <input className="item-modal-input" defaultValue={specialSelection.name} readOnly />
              <PriceInput price={specialSelection.price} readOnly />
              <button
                type="button"
                className="item-modal-button-remove"
                onClick={() => {
                  setData({
                    item: {
                      ...data.item,
                      specialSelections: data.item.specialSelections.filter((_, i) => i !== index),
                    },
                  });
                }}
              >
                ×
              </button>
            </div>
          </Fragment>
        ))}
        <div style={{ display: 'flex', gap: 16 }}>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsTitle')}
            value={newSpecialSelection.name}
            onChange={(e) => {
              setNewSpecialSelection({ ...newSpecialSelection, name: e.target.value });
            }}
          />
          <PriceInput
            price={newSpecialSelection.price}
            onChange={(value) => {
              setNewSpecialSelection({ ...newSpecialSelection, price: value });
            }}
          />
          <button
            type="button"
            className="item-modal-button-add"
            disabled={newSpecialSelection.name == null || newSpecialSelection.name.length === 0}
            onClick={() => {
              setData({
                item: {
                  ...data.item,
                  specialSelections: [...data.item.specialSelections, newSpecialSelection],
                },
              });
              setNewSpecialSelection({ name: '', price: 0 });
            }}
          >
            +
          </button>
        </div>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('optionGroups')}</p>
        {data.item.optionGroups.map((group, index) => (
          <Fragment key={`${group.title}-${index}`}>
            <div style={{ display: 'flex', gap: 16, marginBottom: 14 }}>
              <input className="item-modal-input" defaultValue={group.title} readOnly />
              <button
                type="button"
                className="item-modal-button-remove"
                onClick={() => {
                  setData({
                    item: {
                      ...data.item,
                      optionGroups: data.item.optionGroups.filter((_, i) => i !== index),
                    },
                  });
                }}
              >
                ×
              </button>
            </div>
            {group.options.map((option, optionIndex) => (
              <Fragment key={`${option.name}-${optionIndex}-${index}`}>
                <div style={{ display: 'flex', gap: 16, marginBottom: 14, alignItems: 'center' }}>
                  <Icon name="dot circle" fitted style={{ color: '#7268cd', paddingLeft: 6 }} />
                  <input className="item-modal-input" defaultValue={option.name} readOnly />
                  <PriceInput price={option.price} readOnly />
                  <button
                    type="button"
                    className="item-modal-button-remove"
                    onClick={() => {
                      const optionGroups = data.item.optionGroups.map((oGroup, idx) => {
                        if (idx === index) {
                          const tmpOGroup = oGroup;
                          tmpOGroup.options = tmpOGroup.options.filter((_, i) => i !== optionIndex);
                          return tmpOGroup;
                        }
                        return oGroup;
                      });
                      setData({
                        item: {
                          ...data.item,
                          optionGroups: [...optionGroups],
                        },
                      });
                    }}
                  >
                    ×
                  </button>
                </div>
              </Fragment>
            ))}
            <div style={{ display: 'flex', gap: 16, marginBottom: 14, alignItems: 'center' }}>
              <Icon name="dot circle" fitted style={{ color: '#7268cd', paddingLeft: 6 }} />
              <input
                className="item-modal-input"
                placeholder={i18n.t('itemsTitle')}
                value={newOption[index] ? newOption[index].name : ''}
                onChange={(e) => {
                  newOption[index] = {
                    name: e.target.value,
                    price: newOption[index] ? newOption[index].price : '',
                  };
                  setNewOption([...newOption]);
                }}
              />
              <PriceInput
                price={newOption[index] ? newOption[index].price : ''}
                onChange={(value) => {
                  newOption[index] = {
                    name: newOption[index] ? newOption[index].name : '',
                    price: value,
                  };
                  setNewOption([...newOption]);
                }}
              />
              <button
                type="button"
                className="item-modal-button-add"
                disabled={
                  newOption[index]
                    ? newOption[index].name == null || newOption[index].name.length === 0
                    : true
                }
                onClick={() => {
                  const tmpNewOption = newOption[index];
                  const optionGroup = data.item.optionGroups[index];
                  optionGroup.options.push(tmpNewOption);
                  setData({
                    item: {
                      ...data.item,
                      optionGroups: [...data.item.optionGroups],
                    },
                  });
                  setNewOption([...newOption.filter((_, i) => i !== index)]);
                }}
              >
                +
              </button>
            </div>
          </Fragment>
        ))}
        <div style={{ display: 'flex', gap: 16 }}>
          <input
            className="item-modal-input"
            placeholder={i18n.t('groupName')}
            value={newOptionGroup.title}
            onChange={(e) => setNewOptionGroup({ ...newOptionGroup, title: e.target.value })}
          />
          <button
            type="button"
            className="item-modal-button-add"
            disabled={newOptionGroup.title == null || newOptionGroup.title.length === 0}
            onClick={() => {
              setData({
                item: { ...data.item, optionGroups: [...data.item.optionGroups, newOptionGroup] },
              });
              setNewOptionGroup({ title: '', options: [] });
            }}
          >
            +
          </button>
        </div>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsIngredients')}</p>
        {data.item.ingredients.map((ingredient, index) => (
          <Fragment key={`${ingredient}-${index}`}>
            <div style={{ display: 'flex', gap: 16, marginBottom: 14 }}>
              <input className="item-modal-input" defaultValue={ingredient} readOnly />
              <button
                type="button"
                className="item-modal-button-remove"
                onClick={() => {
                  setData({
                    item: {
                      ...data.item,
                      ingredients: data.item.ingredients.filter((_, i) => i !== index),
                    },
                  });
                }}
              >
                ×
              </button>
            </div>
          </Fragment>
        ))}
        <div style={{ display: 'flex', gap: 16 }}>
          <input
            className="item-modal-input"
            placeholder={i18n.t('itemsIngredient')}
            value={newIngredient}
            onChange={(e) => setNewIngredient(e.target.value)}
          />
          <button
            type="button"
            className="item-modal-button-add"
            disabled={newIngredient == null || newIngredient.length === 0}
            onClick={() => {
              setData({
                item: { ...data.item, ingredients: [...data.item.ingredients, newIngredient] },
              });
              setNewIngredient('');
            }}
          >
            +
          </button>
        </div>
      </div>
      <div className="items-modal-group-section">
        <p className="items-modal-section-title">{i18n.t('itemsAllergens')}</p>
        <Dropdown
          selection
          placeholder={i18n.t('itemsAllergens')}
          fluid
          multiple
          value={data.item.allergens}
          options={allergensDropdownOptions(dropDownData.allergens)}
          onChange={(_, data) => onAllergensDropdownChange(data.value)}
          className="items-modal-dropdown-new"
          id="items-dropdown"
          style={{ marginTop: 0 }}
        />
      </div>
    </div>
  );

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size="large"
        closeOnEscape
        closeOnDimmerClick
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            wordBreak: 'break-all',
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            paddingBottom: 4,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '100%', marginBottom: 0 }}>
              {data.item.title || i18n.t('itemsNewItemTitle')}
            </p>
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(false)}
              floated="right"
              className="items-close-btn"
              circular
            />
          </div>
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ backgroundColor: '#f5f5f9', scrollbarWidth: 'none' }}
          className="no-scrollbar"
        >
          <div className="items-modal-section-group">
            {parseImageSection()}
            {parseGeneralSection()}
          </div>
          <div className="items-modal-section-group">
            {parseLeftSide()}
            {parseRightSide()}
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9', paddingTop: 12 }}>
          <Button
            color="teal"
            content={i18n.t('bookingModalEditSave')}
            onClick={() => onSave()}
            disabled={isSaveDisabled || isLoading || isDeleteLoading}
            loading={isLoading}
          />
          {item ? (
            <Button
              floated="left"
              color="red"
              content={i18n.t('itemsDelete')}
              onClick={() => onDelete()}
              disabled={item == null || isLoading || isDeleteLoading}
              loading={isDeleteLoading}
            />
          ) : (
            <></>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default NewItemsScreenModal;
