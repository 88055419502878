/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import i18n from 'i18next';
import {
  Modal,
  Button,
  Loader,
  Icon,
  Grid,
  TextArea,
  Dropdown,
  Popup,
  Input,
  Radio,
} from 'semantic-ui-react';
import { useInView } from 'react-cool-inview';
import { isEqual, countBy, findLastIndex, cloneDeep, some, sortBy } from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import useScanDetection from 'use-scan-detection';
import Checkbox from '../../../components/general/Checkbox/Checkbox';
import { asCurrency } from '../../../utils/NumberFormatter';
import './BookingsScreen.css';
import { useStateValue } from '../../../core/context/StateProvider';
import { Printer } from '../../../utils/Printer';
import { getItemsPageSortedTerminal } from '../../../api/ItemAPI';
import { getAllCategoriesOfShopTerminal } from '../../../api/CategoryAPI';
import { getTables, getTablesOfUser } from '../../../api/ShopAPI';
import {
  createCardPaymentTerminalOther,
  createCardPaymentTerminalOtherPin,
  createCardTerminalPayment,
  createCardTerminalPaymentPin,
  createCashPaymentTerminal,
  createCashPaymentTerminalPin,
  createPayLaterPaymentTerminal,
  createPayLaterPaymentTerminalPin,
  fulfillCardTerminalPayment,
  fulfillCardTerminalPaymentPin,
  getPayLaterBookingOfTable,
  updateLaterPayment,
  updateLaterPaymentPin,
} from '../../../api/PaymentAPI';
import PinNumPad from '../../../components/shop/PinNumPad/PinNumPad';
import { splitBooking, splitBookingPin } from '../../../api/BookingAPI';
import NumPad from '../../../components/shop/NumPad/NumPad';
import { showToast } from '../../../components/general/Toast/Toast';
import DiverseProductModal from './DiverseProductModal';
import addButtonIcon from './icons/add.png';
import BookingsDiscountModal from './BookingsDiscountModal';
import readerIcon from './icons/terminal.png';
import allProductsIcon from './icons/all.png';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const BookingsCreateModal = (props) => {
  const [{ shop, user, token, config }] = useStateValue();
  const {
    open,
    onClose,
    terminal,
    tableId,
    onBackgroundRefresh,
    bookingId,
    resetBookingId,
    isDiscoveringTerminal,
    openCardsModal,
    setTableIdToNull,
    logoCanvasContext,
  } = props;
  const [isGettingInitialData, setIsGettingInitialData] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [isLoadingPaymentData, setIsLoadingPaymentData] = useState(false);
  const [isSettingUpTerminal, setIsSettingUpTerminal] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [preSelectedTableWasSet, setPreSelectedTableWasSet] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [checkedSelectedTableIndex, setCheckedSelectedTableIndex] = useState(null);
  const [selectedTableIndex, setSelectedTableIndex] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [cart, setCart] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedBookingIndex, setSelectedBookingIndex] = useState(null);
  const [pinOptions, setPinOptions] = useState({ open: false, onSendPin: null });
  const [discountModalOptions, setDiscountModalOptions] = useState({
    open: false,
    cartWithoutToAdd: [],
    toAdd: [],
  });
  const [searchValue, setSearchValue] = useState('');
  const [newBookedItems, setNewBookedItems] = useState([]);
  const [oldBookedItems, setOldBookedItems] = useState([]);
  const [numPadNumChange, setNumPadNumChange] = useState('0');
  const [numPadNumTips, setNumPadNumTips] = useState('0');
  const [isSaveNewBookingDisabled, setIsSaveNewBookingDisabled] = useState(true);
  const [selectedGeneralDiscount, setSelectedGeneralDiscount] = useState(null);
  const [diverseProductModalOptions, setDiverseProductModalOptions] = useState({
    open: false,
    categories: [],
  });
  const [bookingsSplitModal, setBookingsSplitModalOptions] = useState({
    open: false,
    booking: null,
  });
  const [isLoadingSplitBooking, setIsLoadingSplitBooking] = useState(false);
  const [paymentModalOptions, setPaymentModalOptions] = useState({
    open: false,
    amount: 0,
    type: null,
  });
  const [itemSettings, setItemSettings] = useState({
    totalPrice: 0,
    specialSelections: [],
    notes: '',
    optionGroups: [],
  });
  const [itemDetailsModalOptions, setItemDetailsModalOptions] = useState({
    open: false,
    item: null,
    dynamicPrice: null,
  });
  const [defineDynamicPriceModalOptions, setDefineDynamicPriceModalOptions] = useState({
    open: false,
    item: null,
  });
  const [dynamicPrice, setDynamicPrice] = useState('0');
  const searchRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [cartData, setCartData] = useState({ toAdd: [], toCancel: [] });
  const [payLaterModalOptions, setPayLaterModalOptions] = useState({ open: false });
  const [data, setData] = useState({
    tables: [],
    allTables: [],
    rooms: [],
    allItems: [],
    items: [],
    categories: [],
    totalPages: 1,
    currentPage: 1,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const inputFieldOptions = {
    change: 'CHANGE',
    tips: 'TIPS',
  };
  const [currentInputField, setCurrentInputField] = useState(inputFieldOptions.change);
  const [selectedRoom, setSelectedRoom] = useState(i18n.t('allTables'));
  const changeInputRef = useRef(null);

  const closeModal = (refreshNeeded) => {
    setPaymentModalOptions({
      open: false,
      amount: 0,
      type: null,
    });
    setItemSettings({
      totalPrice: 0,
      specialSelections: [],
      notes: '',
      optionGroups: [],
    });
    setItemDetailsModalOptions({
      open: false,
      item: null,
      dynamicPrice: null,
    });
    setDefineDynamicPriceModalOptions({ open: false, item: null });
    setDynamicPrice('0');
    setData({
      tables: [],
      allTables: [],
      rooms: [],
      items: [],
      allItems: [],
      categories: [],
      totalPages: 1,
      currentPage: 1,
    });
    setErrorWarningModalOptions({
      open: false,
      message: '',
    });
    setPinOptions({ open: false, onSendPin: null });
    setPayLaterModalOptions({ open: false });
    setDiscountModalOptions({ open: false, cartWithoutToAdd: [], toAdd: [] });
    setDiverseProductModalOptions({ open: false, categories: [] });
    setIsGettingInitialData(false);
    setIsLoadingInitialData(false);
    setIsLoadingPaymentData(false);
    setPreSelectedTableWasSet(false);
    setIsLoadingBooking(false);
    setIsSettingUpTerminal(false);
    setShowSearch(false);
    setSelectedGeneralDiscount(null);
    setCartData({ toAdd: [], toCancel: [] });
    setNumPadNumChange('0');
    setNumPadNumTips('0');
    setSearchValue('');
    setCurrentInputField(inputFieldOptions.change);
    setSelectedRoom(i18n.t('allTables'));

    setSelectedCategoryIndex(0);
    setSelectedTableIndex(null);
    setCheckedSelectedTableIndex(null);
    setHasMoreItems(true);
    setCart([]);
    setBookings([]);
    setSelectedBookingIndex(null);
    setDropdownOptions([]);
    onClose(refreshNeeded);
  };

  const closeCurrentModalButKeepTable = () => {
    selectCategory(0);
    setPaymentModalOptions({
      open: false,
      amount: 0,
      type: null,
    });
    setItemSettings({
      totalPrice: 0,
      specialSelections: [],
      notes: '',
      optionGroups: [],
    });
    setItemDetailsModalOptions({
      open: false,
      item: null,
      dynamicPrice: null,
    });
    setDefineDynamicPriceModalOptions({ open: false, item: null });
    setDynamicPrice('0');
    setErrorWarningModalOptions({
      open: false,
      message: '',
    });
    setPinOptions({ open: false, onSendPin: null });
    setPayLaterModalOptions({ open: false });
    setDiscountModalOptions({ open: false, cartWithoutToAdd: [], toAdd: [] });
    setDiverseProductModalOptions({ open: false, categories: [] });
    setIsLoadingInitialData(false);
    setIsLoadingPaymentData(false);
    setIsLoadingBooking(false);
    setIsSettingUpTerminal(false);
    setShowSearch(false);
    setSelectedGeneralDiscount(null);
    setNumPadNumChange('0');
    setNumPadNumTips('0');
    setSearchValue('');
    setCurrentInputField(inputFieldOptions.change);

    setSelectedCategoryIndex(0);
    setCheckedSelectedTableIndex(null);
    setHasMoreItems(true);
    setCart([]);
    setCartData({ toAdd: [], toCancel: [] });
    setBookings([]);
    setSelectedBookingIndex(null);
    setDropdownOptions([]);
    onBackgroundRefresh();
    showDefaultOnDisplay();
  };

  const showDefaultOnDisplay = () => {
    if (user && user.role === 'manager') {
      const displayPrinters = shop.printers
        ? shop.printers.filter((printer) => printer.hasCustomerDisplay === true)
        : [];
      displayPrinters.forEach((printer) => {
        const printerDevice = new Printer(printer);
        if (
          (printer.firstLine && printer.firstLine.length > 0) ||
          (printer.firstLine && printer.firstLine.length > 0)
        ) {
          printerDevice.display2Lines(printer.firstLine, printer.secondLine);
        } else {
          printerDevice.display2Lines('Herzlich Willkommen!', null);
        }
      });
    }
  };

  const { observe } = useInView({
    rootMargin: '10px 0px',
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      getMoreItems().then(() => {
        observe();
      });
    },
  });

  const getMoreItems = async () => {
    data.currentPage += 1;
    if (data.currentPage > data.totalPages) {
      setHasMoreItems(false);
    } else {
      let res;
      if (selectedCategoryIndex === 0) {
        res = await getItemsPageSortedTerminal(
          shop._id,
          data.currentPage,
          0,
          'title',
          'ascending',
          null,
          token
        );
      } else {
        res = await getItemsPageSortedTerminal(
          shop._id,
          data.currentPage,
          0,
          'title',
          'ascending',
          data.categories[selectedCategoryIndex]._id,
          token
        );
      }
      setData({
        categories: data.categories,
        allItems: data.allItems,
        items: data.items.concat(res.items),
        tables: data.tables,
        allTables: data.allTables,
        rooms: data.rooms,
        totalPages: res.totalPages,
        currentPage: data.currentPage,
      });
    }
  };

  const cleanupBookedItems = (bookedItems) => {
    const clean = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return clean;
  };

  const onCloseDiscountModal = (newCartWithoutAdd, newToAdd) => {
    if (newCartWithoutAdd && newToAdd) {
      const refundsAndCancelsFromCart = cart.filter((item) => item.isRefund === true);
      const newCWA = newCartWithoutAdd.concat(newToAdd).concat(refundsAndCancelsFromCart);
      setCart(newCWA);
      setCartData({ ...cartData, toAdd: newToAdd });
      setSelectedGeneralDiscount(0);
    }
    const totalCart = (newCartWithoutAdd || []).concat(newToAdd || []);
    if (
      totalCart.find(
        (item) => item.discount && (item.discount.percentage > 0 || item.discount.amount > 0)
      ) === undefined &&
      cart.find(
        (item) => item.discount && (item.discount.percentage > 0 || item.discount.amount > 0)
      ) === undefined
    ) {
      setSelectedGeneralDiscount(null);
    } else {
      if (totalCart.every((item) => item.discount != null && item.discount.percentage === 0.5)) {
        setSelectedGeneralDiscount(0.5);
      }
      if (totalCart.every((item) => item.discount != null && item.discount.percentage === 0.2)) {
        setSelectedGeneralDiscount(0.2);
      }
      if (totalCart.every((item) => item.discount != null && item.discount.percentage === 0.1)) {
        setSelectedGeneralDiscount(0.1);
      }
    }
    setDiscountModalOptions({ open: false, cartWithoutToAdd: [], toAdd: [] });
    showOnDisplay(i18n.t('sum'), calculateCartAmount() * 100);
  };

  const onSplitBookingPin = async () => {
    setPinOptions({
      open: true,
      onSendPin: async (pin) => {
        try {
          setIsLoadingSplitBooking(true);
          const res = await splitBookingPin(
            bookingsSplitModal.booking._id,
            oldBookedItems,
            newBookedItems,
            token,
            pin
          );
          setIsLoadingSplitBooking(false);
          if (res && res.success) {
            setOldBookedItems([]);
            setNewBookedItems([]);
            setBookingsSplitModalOptions({ open: false, booking: null });
            closeCurrentModalButKeepTable();
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('bookingSplitError'),
            });
          }
          onClosePinModal();
        } catch (error) {
          setIsLoadingSplitBooking(false);
          setErrorWarningModalOptions({
            open: true,
            message: `${i18n.t('bookingSplitError')} ${error}`,
          });
          onClosePinModal();
        }
      },
    });
  };

  const onSplitBooking = async () => {
    try {
      setIsLoadingSplitBooking(true);
      const res = await splitBooking(
        bookingsSplitModal.booking._id,
        oldBookedItems,
        newBookedItems,
        token
      );
      setIsLoadingSplitBooking(false);
      if (res && res.success) {
        setOldBookedItems([]);
        setNewBookedItems([]);
        setBookingsSplitModalOptions({ open: false, booking: null });
        closeCurrentModalButKeepTable();
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('bookingSplitError'),
        });
      }
    } catch (error) {
      setIsLoadingSplitBooking(false);
      setErrorWarningModalOptions({
        open: true,
        message: `${i18n.t('bookingSplitError')} ${error}`,
      });
    }
  };

  useScanDetection({
    onComplete: (barcode) => {
      if (shop.hasBarcodeScanner === true && open === true) {
        let code = barcode;
        if (barcode.startsWith('Enter')) {
          code = barcode.replace('Enter', '');
        }
        const item = data.items.find((item) => item.barcode === code);
        if (item) {
          if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
            const bookedItem = {
              itemId: item._id,
              categoryName: item.categoryName,
              itemTitle: item.title,
              isRefund: false,
              specialSelections: [],
              notes: '',
              amount: Math.round(item.price),
              taxRate:
                data.tables[selectedTableIndex].isTakeAway === 'true' ||
                data.tables[selectedTableIndex].isTakeAway === true
                  ? item.takeAwayTaxRate
                  : item.inHouseTaxRate,
            };
            const { toAdd } = cartData;
            toAdd.push(bookedItem);
            setCartData({ ...cartData, toAdd });
            setCart([...cart, bookedItem]);
            setItemDetailsModalOptions({ open: false, item: null });
            setItemSettings({
              totalPrice: 0,
              specialSelections: [],
              notes: '',
              optionGroups: [],
            });
          }
        } else {
          showToast({
            error: true,
            message: i18n.t('itemNotFound'),
            title: 'Barcode Scanner',
            id: `barcode${barcode}`,
          });
        }
      }
    },
    minLength: 8,
  });

  useEffect(() => {
    if (open === true && tableId === null && selectedTableIndex === null) {
      setSelectedTableIndex(0);
    }
    if (isSaveNewBookingDisabled === true && newBookedItems.length > 0) {
      setIsSaveNewBookingDisabled(false);
    }
    if (isSaveNewBookingDisabled === false && newBookedItems.length === 0) {
      setIsSaveNewBookingDisabled(true);
    }
    if (
      cartData.toAdd.length > 0 &&
      cartData.toAdd.length === cartData.toCancel.length &&
      isEqual(cleanupBookedItems(cartData.toAdd), cleanupBookedItems(cartData.toCancel))
    ) {
      setCartData({ toAdd: [], toCancel: [] });
      setCart(
        bookings.length > 0 && selectedBookingIndex !== null
          ? bookings[selectedBookingIndex].bookedItems
          : []
      );
    }
    if (
      (data.totalPages === 0 || data.totalPages === 1 || data.currentPage === data.totalPages) &&
      hasMoreItems === true
    ) {
      setHasMoreItems(false);
    }
    if (data.currentPage < data.totalPages && hasMoreItems === false) {
      setHasMoreItems(true);
    }
    if (
      data.categories.length === 0 &&
      data.items.length === 0 &&
      data.tables.length === 0 &&
      !isGettingInitialData &&
      open === true
    ) {
      setIsGettingInitialData(true);
      getInitialData();
    }
    if (
      data.categories.length > 0 &&
      data.items.length > 0 &&
      data.tables.length > 0 &&
      tableId !== null &&
      isLoadingInitialData === false &&
      open === true &&
      preSelectedTableWasSet === false &&
      isLoadingBooking === false &&
      cart.length === 0
    ) {
      const tableIndex = data.tables.findIndex(
        (table) =>
          table.description !== 'In-House' &&
          table.description !== 'Take-Away' &&
          table._id.toString() === tableId
      );
      setSelectedTableIndex(tableIndex);
      setPreSelectedTableWasSet(true);
    }
    if (
      data.categories.length > 0 &&
      data.items.length > 0 &&
      data.tables.length > 0 &&
      checkedSelectedTableIndex !== selectedTableIndex &&
      isLoadingBooking === false &&
      bookings.length === 0 &&
      open === true
    ) {
      checkTableForLaterBooking();
      setCheckedSelectedTableIndex(selectedTableIndex);
    }
  });

  useEffect(() => {
    if (document.getElementById('selectedtable')) {
      document
        .getElementById('selectedtable')
        .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [selectedTableIndex]);

  useEffect(() => {
    if (changeInputRef && changeInputRef.current) {
      changeInputRef.current.focus();
    }
  }, [paymentModalOptions]);

  const checkTableForLaterBooking = async () => {
    if (
      data.tables[selectedTableIndex] &&
      data.tables[selectedTableIndex].description !== 'In-House' &&
      data.tables[selectedTableIndex].description !== 'Take-Away'
    ) {
      try {
        setIsLoadingBooking(true);
        const res = await getPayLaterBookingOfTable(
          token,
          shop._id,
          data.tables[selectedTableIndex]._id
        );
        if (res && res.success) {
          if (res.bookings && res.bookings.length > 0) {
            const cpyBookings = cloneDeep(
              res.bookings.filter(
                (booking) => booking.isRequest === false || booking.isRequest == null
              )
            );
            const cpyBookings2 = cloneDeep(
              res.bookings.filter(
                (booking) => booking.isRequest === false || booking.isRequest == null
              )
            );
            setBookings(cpyBookings);
            const options = cloneDeep(res.bookings)
              .filter((booking) => booking.isRequest === false || booking.isRequest == null)
              .map((booking, index) => ({
                key: `bk${index}`,
                text: booking.isFromTerminal
                  ? `Terminal - ${booking.bookingId} - ${asCurrency(booking.amount / 100)} €`
                  : `QR - ${booking.bookingId} - ${asCurrency(booking.amount / 100)} €`,
                value: index,
              }));
            setDropdownOptions(options);
            if (
              bookingId !== null &&
              cpyBookings2.find((booking) => booking.bookingId === bookingId)
            ) {
              const index = cpyBookings2.findIndex((booking) => booking.bookingId === bookingId);
              setSelectedBookingIndex(index);
              setCart(cpyBookings2[index].bookedItems);
              document
                .getElementById('selectedtable')
                .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
              showOnDisplay(
                i18n.t('sum'),
                cpyBookings2[index].bookedItems.reduce(
                  (sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                  0
                )
              );
            }
          }
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('savedBookingNotFound'),
          });
        }
        setIsLoadingBooking(false);
      } catch (error) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('savedBookingNotFound'),
        });
        setIsLoadingBooking(false);
      }
    }
  };

  const calculateChange = (value) => {
    const amount = cart.reduce(
      (sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
      0
    );
    const change = value - amount / 100;
    return change;
  };

  const subtractBookedItem = async (index) => {
    const cartDataCpy = cloneDeep(cartData);
    cartDataCpy.toCancel.push(cart[index]);
    const cartCpy = cloneDeep(cart);
    cartCpy.splice(index, 1);
    setCart(cartCpy);
    const cleanCancel = cleanupBookedItems(cartDataCpy.toCancel);
    const cleanAdd = cleanupBookedItems(cartDataCpy.toAdd);
    const foundIndexCartData = cleanAdd.findIndex((item) =>
      some(cleanCancel, (element) => isEqual(item, element))
    );
    if (foundIndexCartData !== -1) {
      const newAdd = cartDataCpy.toAdd;
      newAdd.splice(foundIndexCartData, 1);
      setCartData({ ...cartData, toAdd: newAdd });
    } else {
      cartDataCpy.toCancel[cartDataCpy.toCancel.length - 1].discount = { amount: 0, percentage: 0 };
      setCartData({ ...cartData, toCancel: cartDataCpy.toCancel });
    }
    if (
      cartCpy.length === 0 ||
      cartCpy.find(
        (item) => item.discount && (item.discount.percentage > 0 || item.discount.amount > 0)
      ) === undefined
    ) {
      setSelectedGeneralDiscount(null);
    }
    showOnDisplay(cart[index].itemTitle, cart[index].amount !== 0 ? cart[index].amount * -1 : 0);
  };

  const addSameBookedItem = async (index) => {
    if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
      const cartDataCpy = cloneDeep(cartData);
      cartDataCpy.toAdd.push(cart[index]);
      const cleanAdd = cleanupBookedItems(cartDataCpy.toAdd);
      const cleanCancel = cleanupBookedItems(cartDataCpy.toCancel);
      const foundIndexCartData = cleanCancel.findIndex((item) =>
        some(cleanAdd, (element) => isEqual(item, element))
      );
      if (foundIndexCartData !== -1) {
        const newCancel = cartDataCpy.toCancel;
        newCancel.splice(foundIndexCartData, 1);
        setCartData({ ...cartData, toCancel: newCancel });
      } else {
        setCartData({ ...cartData, toAdd: cartDataCpy.toAdd });
      }
      const bookedItem = cart[index];
      const tmpBookedItem = cloneDeep(bookedItem);
      // check if added items number (of same item) is lower than the actual bookings items (of that item)
      // to not print them, because those have been printed already
      if (bookings.length > 0 && selectedBookingIndex !== null) {
        const cleanBookingItems = cleanupBookedItems(
          bookings[selectedBookingIndex].bookedItems.filter((item) => item.isRefund === false)
        );
        const cleanCart = cleanupBookedItems(cart);
        const countAddedInOldBooking = countBy(cleanBookingItems, (element) =>
          isEqual(element, cleanCart[index])
        ).true;
        if (countAddedInOldBooking != null) {
          const cleanCart2 = cleanupBookedItems(cart.filter((item) => item.isRefund === false));

          const elementIndexCart2 = cleanCart.findIndex((item) =>
            isEqual(item, cleanupBookedItems([cart[index]])[0])
          );

          const countAddedInOldBooking1 = countBy(cleanCart2, (element) =>
            isEqual(element, cleanCart[elementIndexCart2])
          ).true;
          if (countAddedInOldBooking1 != null && countAddedInOldBooking1 < countAddedInOldBooking) {
            tmpBookedItem.isInternallyPrinted = true;
          } else {
            tmpBookedItem.isInternallyPrinted = false;
          }
        } else {
          tmpBookedItem.isInternallyPrinted = false;
        }
      } else {
        tmpBookedItem.isInternallyPrinted = false;
      }

      tmpBookedItem._id = null;
      delete tmpBookedItem._id;
      setCart([...cart, tmpBookedItem]);
      showOnDisplay(tmpBookedItem.itemTitle, tmpBookedItem.amount);
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
    onClosePinModal();
  };

  const onFinalErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
    closeModal(false);
  };

  const showOnDisplay = (title, amount) => {
    const displayPrinters = shop.printers
      ? shop.printers.filter((printer) => printer.hasCustomerDisplay === true)
      : [];
    displayPrinters.forEach((printer) => {
      const printerDevice = new Printer(printer);
      printerDevice.display2Lines(title, `${asCurrency(amount / 100)}€`, true, false);
    });
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (!item._id) {
        newItem._id = item._id;
      }
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const getInitialData = async () => {
    try {
      setIsLoadingInitialData(true);
      const itemsResponse = await getItemsPageSortedTerminal(
        shop._id,
        1,
        0,
        'title',
        'ascending',
        null,
        token
      );
      if (itemsResponse && itemsResponse.success) {
        const categoryResponse = await getAllCategoriesOfShopTerminal(shop._id, token);
        let tablesResponse = null;
        if (user.role === 'owner' || (user.tables.length === 1 && user.tables[0] === 'all')) {
          tablesResponse = await getTables(token);
        } else {
          tablesResponse = await getTablesOfUser(token);
        }
        if (
          itemsResponse &&
          itemsResponse.success &&
          categoryResponse &&
          categoryResponse.success &&
          tablesResponse &&
          tablesResponse.success
        ) {
          const categoriesTmp =
            categoryResponse.categories && categoryResponse.categories.length > 0
              ? categoryResponse.categories.sort((a, b) =>
                  getCategoryTranslation(a).localeCompare(getCategoryTranslation(b))
                )
              : [];
          const firstCategory = {
            name: i18n.t('appAllCategories'),
            key: 0,
            image: 'image',
          };
          const allCategories = [firstCategory].concat(categoriesTmp);
          const tablesTmp = tablesResponse.tables;
          let allTables = [];
          if (shop.isInHouseTableAllowed && shop.isInHouseTableAllowed === true) {
            allTables.push({ description: 'In-House', isTakeAway: 'false' });
          }
          if (shop.isTakeAwayTableAllowed && shop.isTakeAwayTableAllowed === true) {
            allTables.push({ description: 'Take-Away', isTakeAway: 'true' });
          }
          allTables = allTables.concat(sortBy(tablesTmp, 'number'));
          setData({
            categories: allCategories,
            allItems: itemsResponse.items,
            items: itemsResponse.items,
            tables: allTables,
            allTables,
            rooms: tablesResponse.rooms ? tablesResponse.rooms : [],
            totalPages: itemsResponse.totalPages,
            currentPage: 1,
          });
          data.totalPages = itemsResponse.totalPages;
          setIsLoadingInitialData(false);
        } else {
          setIsGettingInitialData(false);
          setIsLoadingInitialData(false);
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('itemsNotLoadedError'),
        });
      }
    } catch (error) {
      setIsGettingInitialData(false);
      setIsLoadingInitialData(false);
    }
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorBookingsCreate${printerDevice.printer.description}`,
    });
  };

  const onCloseDiverseProductModal = (item, notes) => {
    if (item != null) {
      if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
        const bookedItem = {
          itemId: item._id,
          categoryName: item.categoryName,
          itemTitle: item.title,
          isRefund: false,
          specialSelections: [],
          notes,
          amount: item.price,
          taxRate: item.inHouseTaxRate,
        };
        const { toAdd } = cartData;
        toAdd.push(bookedItem);
        setCartData({ ...cartData, toAdd });
        setCart([...cart, bookedItem]);
        showOnDisplay(bookedItem.itemTitle, bookedItem.amount);
      }
    }
    setDiverseProductModalOptions({ open: false, categories: [] });
  };

  const printCustomerBon = (booking) => {
    if (
      shop.rooms &&
      shop.rooms.length > 0 &&
      booking.table != null &&
      booking.table.tableNumber != null
    ) {
      const room = shop.rooms.find((room) =>
        room.tables.find((table) => table.number === booking.table.tableNumber)
      );
      const shopPrinter = room
        ? shop.printers.find(
            (printer) =>
              (printer.rooms.includes(room.name) || printer.rooms.includes('all')) &&
              printer.isMain === true
          )
        : null;
      if (room && shopPrinter) {
        const printerDevice = new Printer(shopPrinter);
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize = shopPrinter.paperSize === 80 ? 42 : 30;
        printerDevice.printCustomer(booking, shop, paperSize, null, null, logoCanvasContext);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('noReceiptPrinterForRoomError'),
        });
      }
    } else if (shop.printers.find((printer) => printer.isMain === true)) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      const paperSize =
        shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
      printerDevice.printCustomer(booking, shop, paperSize, null, null, logoCanvasContext);
    } else {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('noReceiptPrinterError'),
      });
    }
  };

  const payByCardFlow = async (isPinRequired, pin, bookedItems, tableDTO) => {
    try {
      setIsSettingUpTerminal(true);
      let res;
      if (isPinRequired) {
        res = await createCardTerminalPaymentPin(
          shop._id,
          bookedItems,
          null,
          tableDTO,
          bookings.length > 0 && selectedBookingIndex !== null
            ? bookings[selectedBookingIndex]._id
            : null,
          token,
          pin
        );
      } else {
        res = await createCardTerminalPayment(
          shop._id,
          bookedItems,
          null,
          tableDTO,
          bookings.length > 0 && selectedBookingIndex !== null
            ? bookings[selectedBookingIndex]._id
            : null,
          token
        );
      }
      if (res && res.success) {
        onClosePinModal();
        const collectPaymentMethodResult = await terminal.collectPaymentMethod(res.clientSecret, {
          config_override: {
            skip_tipping: shop.tipsAllowed !== true,
          },
        });
        if (collectPaymentMethodResult.error) {
          console.log(collectPaymentMethodResult.error);
          if (collectPaymentMethodResult.error.code !== 'canceled') {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentError1'),
            });
            setIsSettingUpTerminal(false);
            setIsLoadingPaymentData(false);
          }
        } else {
          setIsLoadingPaymentData(true);
          const processPaymentResult = await terminal.processPayment(
            collectPaymentMethodResult.paymentIntent
          );
          if (processPaymentResult.error) {
            console.log(processPaymentResult.error);
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentError1'),
            });
            setIsSettingUpTerminal(false);
            setIsLoadingPaymentData(false);
          } else if (processPaymentResult.paymentIntent) {
            let fulfillCardTerminalPaymentResponse;
            if (isPinRequired) {
              fulfillCardTerminalPaymentResponse = await fulfillCardTerminalPaymentPin(
                shop._id,
                tableDTO,
                res.paymentIntentId,
                processPaymentResult.paymentIntent.charges.data[0].payment_method_details
                  .card_present,
                processPaymentResult.paymentIntent.charges.data[0].payment_method,
                bookedItems,
                bookings.length > 0 && selectedBookingIndex !== null
                  ? bookings[selectedBookingIndex]._id
                  : null,
                processPaymentResult.paymentIntent.amount_details.tip.amount,
                cartData.toAdd,
                cartData.toCancel,
                token,
                pin
              );
            } else {
              fulfillCardTerminalPaymentResponse = await fulfillCardTerminalPayment(
                shop._id,
                tableDTO,
                res.paymentIntentId,
                processPaymentResult.paymentIntent.charges.data[0].payment_method_details
                  .card_present,
                processPaymentResult.paymentIntent.charges.data[0].payment_method,
                bookedItems,
                bookings.length > 0 && selectedBookingIndex !== null
                  ? bookings[selectedBookingIndex]._id
                  : null,
                processPaymentResult.paymentIntent.amount_details.tip.amount,
                cartData.toAdd,
                cartData.toCancel,
                token
              );
            }
            if (fulfillCardTerminalPaymentResponse && fulfillCardTerminalPaymentResponse.success) {
              if (shop.printers && shop.printers.length > 0 && shop.autoPrintCustomer === true) {
                printCustomerBon(fulfillCardTerminalPaymentResponse.booking);
              }
              onClosePinModal();
              closeCurrentModalButKeepTable();
            } else {
              setErrorWarningModalOptions({
                open: true,
                message: i18n.t('paymentOrPinError'),
              });
              setIsSettingUpTerminal(false);
              setIsLoadingPaymentData(false);
            }
          }
        }
        setIsSettingUpTerminal(false);
        setIsLoadingPaymentData(false);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentOrPinError'),
        });
        setIsLoadingPaymentData(false);
        setIsSettingUpTerminal(false);
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('paymentError1'),
      });
      setIsLoadingPaymentData(false);
      setIsSettingUpTerminal(false);
    }
  };

  const payByCardOther = async () => {
    const bookedItems = cart;
    const tableDTO =
      data.tables[selectedTableIndex] &&
      (data.tables[selectedTableIndex].description === 'In-House' ||
        data.tables[selectedTableIndex].description === 'Take-Away')
        ? {
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
            tableDescription: data.tables[selectedTableIndex].description,
          }
        : {
            tableId: data.tables[selectedTableIndex]._id.toString(),
            tableNumber: data.tables[selectedTableIndex].number,
            tableDescription: data.tables[selectedTableIndex].description,
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
          };

    if (shop.isPinRequired === true && user.role !== 'owner') {
      setPinOptions({
        open: true,
        onSendPin: async (pin) => {
          try {
            setIsLoadingPaymentData(true);
            const res = await createCardPaymentTerminalOtherPin(
              shop._id,
              null,
              null,
              tableDTO,
              bookedItems,
              null,
              bookings.length > 0 && selectedBookingIndex !== null
                ? bookings[selectedBookingIndex]._id
                : null,
              cartData.toAdd,
              cartData.toCancel,
              Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
              token,
              pin
            );
            if (res && res.success) {
              if (shop.printers && shop.printers.length > 0 && shop.autoPrintCustomer === true) {
                printCustomerBon(res.booking);
              }
              onClosePinModal();
              setIsLoadingPaymentData(false);
              closeCurrentModalButKeepTable();
            } else {
              if (res.error && res.error.includes('Employee is not allowed to cancel')) {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('employeeCancelError'),
                });
              } else {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('paymentOrPinError2'),
                });
              }
              setIsLoadingPaymentData(false);
            }
          } catch (error) {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentOrPinError3'),
            });
            setIsLoadingPaymentData(false);
          }
        },
      });
    } else {
      try {
        setIsLoadingPaymentData(true);
        const res = await createCardPaymentTerminalOther(
          shop._id,
          null,
          null,
          tableDTO,
          bookedItems,
          null,
          bookings.length > 0 && selectedBookingIndex !== null
            ? bookings[selectedBookingIndex]._id
            : null,
          cartData.toAdd,
          cartData.toCancel,
          Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
          token
        );
        if (res && res.success) {
          if (shop.printers && shop.printers.length > 0 && shop.autoPrintCustomer === true) {
            printCustomerBon(res.booking);
          }
          setIsLoadingPaymentData(false);
          closeCurrentModalButKeepTable();
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('paymentError1'),
          });
          setIsLoadingPaymentData(false);
        }
      } catch (error) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentError1'),
        });
        setIsLoadingPaymentData(false);
      }
    }
  };

  const payByCash = async () => {
    const bookedItems = cart;
    const tableDTO =
      data.tables[selectedTableIndex] &&
      (data.tables[selectedTableIndex].description === 'In-House' ||
        data.tables[selectedTableIndex].description === 'Take-Away')
        ? {
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
            tableDescription: data.tables[selectedTableIndex].description,
          }
        : {
            tableId: data.tables[selectedTableIndex]._id.toString(),
            tableNumber: data.tables[selectedTableIndex].number,
            tableDescription: data.tables[selectedTableIndex].description,
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
          };

    if (shop.isPinRequired === true && user.role !== 'owner') {
      setPinOptions({
        open: true,
        onSendPin: async (pin) => {
          try {
            setIsLoadingPaymentData(true);
            const res = await createCashPaymentTerminalPin(
              shop._id,
              null,
              null,
              tableDTO,
              bookedItems,
              null,
              bookings.length > 0 && selectedBookingIndex !== null
                ? bookings[selectedBookingIndex]._id
                : null,
              cartData.toAdd,
              cartData.toCancel,
              Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
              token,
              pin
            );
            if (res && res.success) {
              if (shop.printers && shop.printers.length > 0 && shop.autoPrintCustomer === true) {
                printCustomerBon(res.booking);
              }
              onClosePinModal();
              setIsLoadingPaymentData(false);
              closeCurrentModalButKeepTable();
            } else {
              if (res.error && res.error.includes('Employee is not allowed to cancel')) {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('employeeCancelError'),
                });
              } else {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('paymentOrPinError2'),
                });
              }
              setIsLoadingPaymentData(false);
            }
          } catch (error) {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('paymentOrPinError3'),
            });
            setIsLoadingPaymentData(false);
          }
        },
      });
    } else {
      try {
        setIsLoadingPaymentData(true);
        const res = await createCashPaymentTerminal(
          shop._id,
          null,
          null,
          tableDTO,
          bookedItems,
          null,
          bookings.length > 0 && selectedBookingIndex !== null
            ? bookings[selectedBookingIndex]._id
            : null,
          cartData.toAdd,
          cartData.toCancel,
          Math.round(parseFloat(numPadNumTips.replace(',', '.')) * 100),
          token
        );
        if (res && res.success) {
          if (shop.printers && shop.printers.length > 0 && shop.autoPrintCustomer === true) {
            printCustomerBon(res.booking);
          }
          setIsLoadingPaymentData(false);
          closeCurrentModalButKeepTable();
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('paymentError1'),
          });
          setIsLoadingPaymentData(false);
        }
      } catch (error) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('paymentError1'),
        });
        setIsLoadingPaymentData(false);
      }
    }
  };

  const payByCard = async () => {
    const bookedItems = cart;
    const tableDTO =
      data.tables[selectedTableIndex] &&
      (data.tables[selectedTableIndex].description === 'In-House' ||
        data.tables[selectedTableIndex].description === 'Take-Away')
        ? {
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
            tableDescription: data.tables[selectedTableIndex].description,
          }
        : {
            tableId: data.tables[selectedTableIndex]._id.toString(),
            tableNumber: data.tables[selectedTableIndex].number,
            tableDescription: data.tables[selectedTableIndex].description,
            isTakeAway: data.tables[selectedTableIndex].isTakeAway,
          };
    if (shop.isPinRequired === true && user.role !== 'owner') {
      setPinOptions({
        open: true,
        onSendPin: (pin) => payByCardFlow(true, pin, bookedItems, tableDTO),
      });
    } else {
      payByCardFlow(false, null, bookedItems, tableDTO);
    }
  };

  const payLater = async () => {
    const bookedItems = cart;
    const tableDTO = {
      tableId: data.tables[selectedTableIndex]._id.toString(),
      tableNumber: data.tables[selectedTableIndex].number,
      tableDescription: data.tables[selectedTableIndex].description,
      isTakeAway: data.tables[selectedTableIndex].isTakeAway,
    };
    if (shop.isPinRequired === true && user.role !== 'owner') {
      setPinOptions({
        open: true,
        onSendPin: async (pin) => {
          try {
            setIsLoadingPaymentData(true);
            let res;
            if (bookings.length > 0 && selectedBookingIndex !== null) {
              res = await updateLaterPaymentPin(
                bookings[selectedBookingIndex]._id,
                bookedItems,
                cartData.toAdd,
                cartData.toCancel,
                token,
                pin
              );
            } else {
              res = await createPayLaterPaymentTerminalPin(
                shop._id,
                null,
                null,
                tableDTO,
                bookedItems,
                null,
                token,
                pin
              );
            }
            if (res && res.success) {
              onClosePinModal();
              setIsLoadingPaymentData(false);
              setPayLaterModalOptions({ open: false });
              closeCurrentModalButKeepTable();
            } else {
              if (res.error && res.error.includes('Employee is not allowed to cancel')) {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('employeeCancelError'),
                });
              } else {
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('createBookingOrPinError1'),
                });
              }
              setIsLoadingPaymentData(false);
            }
          } catch (error) {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('createBookingOrPinError1'),
            });
            setIsLoadingPaymentData(false);
          }
        },
      });
    } else {
      try {
        setIsLoadingPaymentData(true);
        let res;
        if (bookings.length > 0 && selectedBookingIndex !== null) {
          res = await updateLaterPayment(
            bookings[selectedBookingIndex]._id,
            bookedItems,
            cartData.toAdd,
            cartData.toCancel,
            token
          );
        } else {
          res = await createPayLaterPaymentTerminal(
            shop._id,
            null,
            null,
            tableDTO,
            bookedItems,
            null,
            token
          );
        }
        if (res && res.success) {
          setIsLoadingPaymentData(false);
          setPayLaterModalOptions({ open: false });
          closeCurrentModalButKeepTable();
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('createBookingError'),
          });
          setIsLoadingPaymentData(false);
        }
      } catch (error) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('createBookingError'),
        });
        setIsLoadingPaymentData(false);
      }
    }
  };

  const selectCategory = async (index) => {
    try {
      if (index === 0) {
        setData({
          categories: data.categories,
          allItems: data.allItems,
          items: data.allItems,
          tables: data.tables,
          allTables: data.allTables,
          rooms: data.rooms,
          totalPages: data.totalPages,
          currentPage: 1,
        });
      } else {
        setData({
          categories: data.categories,
          items: data.allItems.filter((item) => item.categoryId === data.categories[index]._id),
          allItems: data.allItems,
          tables: data.tables,
          allTables: data.allTables,
          rooms: data.rooms,
          totalPages: data.totalPages,
          currentPage: 1,
        });
      }
      if (data.totalPages === 0 || data.totalPages === 1) setHasMoreItems(false);
      setSelectedCategoryIndex(index);
      document.getElementsByClassName('bookings-create-items-scroll')[0].scrollTo(0, 0);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('itemsNotLoadedError'),
      });
      closeModal(true);
    }
  };

  const onClosePinModal = () => {
    setPinOptions({ open: false, onSendPin: null });
  };

  const calculateCartAmount = () =>
    cart.reduce((sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0), 0) /
    100;

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (data.categories != null) {
      const category = data.categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const parseCategories = () => (
    <>
      <div className="bookings-create-category-wrapper not-mobile" style={{ width: 100 }}>
        {data.categories.map((category, index) => (
          <Fragment key={`bc-cat-${index}`}>
            <div
              onClick={() => {
                selectCategory(index);
                setShowSearch(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <div
                className="bookings-create-category-image"
                style={{
                  backgroundColor:
                    index === selectedCategoryIndex
                      ? category.color == null || category.color === '#ffffff'
                        ? '#7268cd'
                        : category.color
                      : index !== selectedBookingIndex &&
                        category.color != null &&
                        category.color !== '#ffffff'
                      ? `${category.color}80`
                      : '',
                  textAlign: 'center',
                  boxShadow:
                    index === selectedCategoryIndex
                      ? category.color == null || category.color === '#ffffff'
                        ? `#7268cd 0px 1px 10px`
                        : `${category.color} 0px 1px 10px`
                      : '',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                {index === 0 ? (
                  <img
                    src={allProductsIcon}
                    style={{
                      height: 35,
                      marginTop: 17,
                      transition: 'transform 0.3s ease',
                      transform: index === selectedCategoryIndex ? 'scale(1.2)' : 'scale(1)',
                    }}
                  />
                ) : (
                  <img
                    src={config.cloudinaryUrlPrefix + category.image}
                    style={{
                      height: 25,
                      marginTop: 22,
                      transition: 'transform 0.3s ease',
                      transform: index === selectedCategoryIndex ? 'scale(1.3)' : 'scale(1)',
                    }}
                  />
                )}
              </div>
              <p
                className="customer-category-text"
                style={{
                  color:
                    index === selectedCategoryIndex
                      ? category.color == null || category.color === '#ffffff'
                        ? '#7268cd'
                        : category.color
                      : '#333',
                  textAlign: 'center',
                  width: '100%',
                  wordBreak: 'break-word',
                  whiteSpace: 'unset',
                  transition: 'color 0.3s ease-in-out',
                }}
              >
                {getCategoryTranslation(category)}
              </p>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );

  const parseDiscountPopup = (bookedItem) => (
    <Popup
      on={['hover', 'click']}
      basic
      // eslint-disable-next-line prettier/prettier
      trigger={<p style={{ height: 18, width: 18, border: '1px solid #7268cd', display: 'inline-block', borderRadius: 250, textAlign: 'center', fontSize: 12, color: '#7268cd', fontWeight: 800}}>%</p>}
      // eslint-disable-next-line prettier/prettier
      content={(
        <>
          <p>
            {i18n.t('originalPrice')}: {`${asCurrency(bookedItem.amount / 100)}`}€
          </p>
          <p>
            {i18n.t('discount')}:
            {bookedItem.discount.percentage > 0
              ? ` ${parseInt(bookedItem.discount.percentage * 100, 10)}%`
              : ` ${asCurrency(bookedItem.discount.amount / 100)}€`}
          </p>
        </>
        // eslint-disable-next-line prettier/prettier
  )}
      position="bottom center"
      popper={<div style={{ filter: 'none' }} />}
    />
  );

  const parseCart = () => {
    const cleanedUpBookedItems = cart.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (!item._id) {
        newItem._id = item._id;
      }
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return (
      <>
        {cart.map((bookedItem, index) => (
          <Fragment key={`bc-crt-${index}`}>
            {bookedItem.isRefund === false ? (
              <>
                {index ===
                  findLastIndex(cleanedUpBookedItems, (element) =>
                    isEqual(element, cleanedUpBookedItems[index])
                  ) && (
                  <>
                    <div style={{ marginBottom: 20, animation: 'fadeIn 0.1s ease-in' }}>
                      <div className="checkout-screen-bookedItem-flex" style={{ fontWeight: 500 }}>
                        <div style={{ display: 'flex', width: '74%' }}>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: bookedItem._id ? 600 : 800,
                              width: 13,
                              color: `#7268cd`,
                              paddingTop: 1,
                              marginRight: 6,
                              textAlign: 'right',
                            }}
                          >
                            {countElement(cart, index)}
                          </p>
                          <p
                            style={{
                              paddingLeft: 6,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontWeight: bookedItem._id ? 500 : 700,
                            }}
                          >
                            {bookedItem.discount &&
                            (bookedItem.discount.percentage > 0 || bookedItem.discount.amount > 0)
                              ? parseDiscountPopup(bookedItem)
                              : ''}
                            {` ${bookedItem.itemTitle}`}
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            fontWeight: bookedItem._id ? 500 : 700,
                          }}
                        >
                          {`${asCurrency(getBookedItemAmount(bookedItem) / 100)} €`}
                        </div>
                      </div>
                      <div className="checkout-screen-specialSelections-count-flex-terminal">
                        <div className="checkout-screen-specialSelections">
                          {bookedItem.notes ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 4,
                                  marginLeft: 2,
                                  marginBottom: 4,
                                  alignItems: 'center',
                                }}
                              >
                                <p className="bookings-extras-options-indicator">H</p>
                                <p
                                  style={{
                                    marginBottom: 0,
                                  }}
                                >
                                  {` ${bookedItem.notes}`}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {bookedItem.options &&
                            bookedItem.options.map((option) => (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: 4,
                                    marginLeft: 2,
                                    marginBottom: 4,
                                    alignItems: 'center',
                                  }}
                                >
                                  <p className="bookings-extras-options-indicator">O</p>
                                  <p
                                    style={{
                                      marginBottom: 0,
                                    }}
                                  >
                                    {` ${option.name}`}
                                  </p>
                                </div>
                              </>
                            ))}
                          {bookedItem.specialSelections.map((specialSelection) => (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 4,
                                  marginLeft: 2,
                                  marginBottom: 4,
                                  alignItems: 'center',
                                }}
                              >
                                <p className="bookings-extras-options-indicator">E</p>
                                <p
                                  style={{
                                    marginBottom: 0,
                                  }}
                                >
                                  {` ${specialSelection.specialSelectionTitle}`}
                                </p>
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="bookings-create-plus-minus not-mobile">
                          <div
                            style={{
                              border: `1px solid #7268cd`,
                              width: '24px',
                              borderRadius: '12px',
                              textAlign: 'center',
                              height: '24px',
                              marginRight: '6px',
                              fontSize: '16px',
                              fontWeight: '600',
                              backgroundColor: 'white',
                              color: `#7268cd`,
                              fontStyle: 'normal',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              subtractBookedItem(index);
                            }}
                          >
                            -
                          </div>
                          <div
                            style={{
                              border: `1px solid #7268cd`,
                              borderRadius: '12px',
                              width: '24px',
                              textAlign: 'center',
                              height: '24px',
                              fontSize: '16px',
                              fontWeight: '600',
                              backgroundColor: 'white',
                              color: `#7268cd`,
                              paddingTop: '1px',
                              fontStyle: 'normal',
                              cursor: 'pointer',
                            }}
                            onClick={() => addSameBookedItem(index)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
        {cartData.toCancel.map((bookedItem, index) => (
          <Fragment key={`bc-crt-cncl-${index}`}>
            <div style={{ marginBottom: 20 }}>
              <div style={{ width: '100%', position: 'relative' }}>
                <div
                  style={{
                    height: 0,
                    width: '100%',
                    borderTop: '1px solid black',
                    position: 'absolute',
                    marginTop: 10,
                  }}
                />
              </div>
              <div className="checkout-screen-bookedItem-flex" style={{ fontWeight: 500 }}>
                <div style={{ display: 'flex', width: '74%' }}>
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      width: 13,
                      color: `#7268cd`,
                      paddingTop: 1,
                      marginRight: 6,
                      textAlign: 'right',
                    }}
                  >
                    1
                  </p>
                  <p
                    style={{
                      paddingLeft: 6,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 500,
                    }}
                  >
                    {` ${bookedItem.itemTitle}`}
                  </p>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    fontWeight: 500,
                  }}
                >
                  {`${asCurrency(getBookedItemAmount(bookedItem) / 100)} €`}
                </div>
              </div>
              <div className="checkout-screen-specialSelections-count-flex-terminal">
                <div className="checkout-screen-specialSelections">
                  {bookedItem.notes ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p
                          className="bookings-extras-options-indicator"
                          style={{ textDecoration: 'line-through', textDecorationColor: 'black' }}
                        >
                          H
                        </p>
                        <p
                          style={{
                            marginBottom: 0,
                            textDecoration: 'line-through',
                          }}
                        >
                          {` ${bookedItem.notes}`}
                        </p>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {bookedItem.options &&
                    bookedItem.options.map((option) => (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p
                            className="bookings-extras-options-indicator"
                            style={{ textDecoration: 'line-through', textDecorationColor: 'black' }}
                          >
                            O
                          </p>
                          <p
                            style={{
                              marginBottom: 0,
                              textDecoration: 'line-through',
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      </>
                    ))}
                  {bookedItem.specialSelections.map((specialSelection) => (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p
                          className="bookings-extras-options-indicator"
                          style={{ textDecoration: 'line-through', textDecorationColor: 'black' }}
                        >
                          E
                        </p>
                        <p
                          style={{
                            marginBottom: 0,
                            textDecoration: 'line-through',
                          }}
                        >
                          {` ${specialSelection.specialSelectionTitle}`}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </Fragment>
        ))}
      </>
    );
  };

  const onChangeCheckbox = (event, index, item) => {
    const specialSelections = [...itemSettings.specialSelections];
    specialSelections[index] = event.target.checked;
    const newPrice = event.target.checked
      ? itemSettings.totalPrice + item.specialSelections[index].price
      : itemSettings.totalPrice - item.specialSelections[index].price;
    setItemSettings({
      ...itemSettings,
      totalPrice: newPrice,
      specialSelections: [...specialSelections],
    });
  };

  const onChangeRadio = (event, indexGroup, indexOption, item) => {
    const optionGroups = [...itemSettings.optionGroups];
    optionGroups[indexGroup] = item.optionGroups[indexGroup];
    optionGroups[indexGroup].options[indexOption].checked = true;
    let priceDeduction = 0;
    optionGroups[indexGroup].options.forEach((option, index) => {
      if (option.checked === true && index !== indexOption) {
        // eslint-disable-next-line no-param-reassign
        option.checked = false;
        priceDeduction += option.price;
      }
    });
    const newPrice =
      itemSettings.totalPrice -
      priceDeduction +
      optionGroups[indexGroup].options[indexOption].price;
    setItemSettings({ ...itemSettings, totalPrice: newPrice, optionGroups: [...optionGroups] });
  };

  const handleSearch = async (value) => {
    if (value.length > 0) {
      if (selectedCategoryIndex !== 0) {
        setSelectedCategoryIndex(0);
      }
      const searchByTitle = (query) =>
        data.allItems.filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
      setData({ ...data, items: searchByTitle(value) });
    } else {
      setData({ ...data, items: data.allItems });
    }
  };

  const renderIngredients = (item) => (
    <>
      <p className="customer-item-screen-textlist">{item.ingredients.join(', ')}</p>
    </>
  );

  const renderAllergens = (item) => (
    <>
      <p className="customer-item-screen-textlist">{item.allergens.join(', ')}</p>
    </>
  );

  const renderSpecialSelections = (item) =>
    item.specialSelections ? (
      item.specialSelections.map((specialSelection, index) => (
        <Fragment key={`bc-ss-${index}`}>
          <div className="customer-item-screen-special-selection-container">
            <Checkbox
              text={specialSelection.name}
              onChange={(event) => onChangeCheckbox(event, index, item)}
              checked={
                itemSettings.specialSelections.length > 0 && itemSettings.specialSelections[index]
              }
              labelStyle={{
                paddingLeft: '10px',
                fontSize: '14px',
                fontWeight: 500,
                wordBreak: 'break-all',
                width: '100%',
              }}
            />
            <p style={{ fontSize: '14px', fontWeight: 500, width: 110, textAlign: 'right' }}>
              {`${asCurrency(specialSelection.price / 100)} €`}
            </p>
          </div>
        </Fragment>
      ))
    ) : (
      <></>
    );

  const renderOptionGroups = (item) => {
    if (
      itemSettings.optionGroups.length === 0 &&
      item.optionGroups &&
      item.optionGroups.length > 0 &&
      itemSettings.optionGroups.length !== item.optionGroups.length
    ) {
      itemSettings.optionGroups = item.optionGroups;
      let newPrice = 0;
      itemSettings.optionGroups.forEach((optionGroup) => {
        optionGroup.options.forEach((option, index) => {
          if (index === 0) {
            // eslint-disable-next-line no-param-reassign
            option.checked = true;
            newPrice += option.price;
          } else {
            // eslint-disable-next-line no-param-reassign
            option.checked = false;
          }
        });
      });
      setItemSettings({
        ...itemSettings,
        optionGroups: itemSettings.optionGroups,
        totalPrice: itemSettings.totalPrice + newPrice,
      });
    }
    return item.optionGroups ? (
      item.optionGroups.map((optionGroup, index) => (
        <Fragment key={`bc-og-${index}`}>
          <p className="customer-item-screen-subtitles">{optionGroup.title}</p>
          {optionGroup.options.map((option, idx) => (
            <div className="customer-item-screen-special-selection-container">
              <Radio
                label={option.name}
                onChange={(event) => onChangeRadio(event, index, idx, item)}
                checked={
                  itemSettings.optionGroups &&
                  itemSettings.optionGroups.length > 0 &&
                  itemSettings.optionGroups[index].options[idx].checked === true
                }
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  wordBreak: 'break-all',
                  width: '100%',
                  height: 32,
                }}
              />
              <p style={{ fontSize: '14px', fontWeight: 500, width: 110, textAlign: 'right' }}>
                {`${asCurrency(option.price / 100)} €`}
              </p>
            </div>
          ))}
        </Fragment>
      ))
    ) : (
      <></>
    );
  };

  const getCategoryColor = (item) => {
    const category = data.categories.find((cat) => cat._id === item.categoryId);
    if (category) {
      if (category.color == null || category.color === '#ffffff') {
        return '#7268cd';
      }
      return category.color;
    }
    return '#7268cd';
  };

  const getCategoryBorderColor = (item) => {
    const category = data.categories.find((cat) => cat._id === item.categoryId);
    if (category) {
      if (category.color == null || category.color === '#ffffff') {
        return '#ffffff';
      }
      return category.color;
    }
    return '#ffffff';
  };

  const parseTablesAndItems = () => (
    <>
      <div
        className="not-mobile"
        style={{
          width: 'calc(100vw - 570px)',
          maxWidth: 'calc(100vw - 570px)',
          minWidth: 'calc(100vw - 570px)',
        }}
      >
        {data.tables.length === 1 &&
        ((shop.isInHouseTableAllowed === true && shop.isTakeAwayTableAllowed === false) ||
          (shop.isTakeAwayTableAllowed === true && shop.isInHouseTableAllowed === false)) ? (
          // eslint-disable-next-line react/jsx-indent
          <></>
        ) : (
          <>
            <div style={{ display: 'flex', overflow: 'unset' }}>
              {data.rooms.length > 0 &&
              data.rooms.some((room) => room.tables && room.tables.length > 0) ? (
                <>
                  <div
                    style={{
                      width: 130,
                      borderRadius: 16,
                      textAlign: 'center',
                      display: 'table',
                      flexShrink: 0,
                      backgroundColor: '#7268cd',
                      boxShadow: `#7268cd 0px 1px 10px`,
                      color: 'white',
                      cursor: 'pointer',
                      margin: 10,
                      marginRight: 4,
                    }}
                  >
                    <Dropdown
                      className="bookings-create-room-selection-dropdown-container"
                      style={{
                        fontWeight: 700,
                        fontSize: 18,
                        textAlign: 'center',
                        height: '70px',
                        verticalAlign: 'middle',
                        display: 'table-cell',
                        zIndex: 21474836,
                        position: 'relative',
                        textOverflow: 'ellipsis',
                        maxWidth: 160,
                        whiteSpace: 'normal',
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      inline
                      scrolling
                      text={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <p
                          style={{
                            fontSize: 17,
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            width: 160,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            marginBottom: -10,
                            paddingLeft: 1,
                            paddingRight: 1,
                          }}
                        >
                          {selectedRoom}
                        </p>
                      }
                    >
                      <Dropdown.Menu
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          boxShadow: 'none',
                          padding: 0,
                          borderRadius: 10,
                        }}
                      >
                        <Dropdown.Item
                          className="bookingscreate-dropdown-item"
                          onClick={(x, d) => {
                            setTableIdToNull();
                            setSelectedRoom(d.value);
                            setData({ ...data, tables: data.allTables });
                            setSelectedTableIndex(0);
                            setCheckedSelectedTableIndex(null);
                            setBookings([]);
                            setCart([]);
                            setCartData({ toAdd: [], toCancel: [] });
                            setDropdownOptions([]);
                            resetBookingId();
                            setSelectedGeneralDiscount(null);
                            setSelectedBookingIndex(null);
                            showDefaultOnDisplay();
                          }}
                          value={i18n.t('allTables')}
                        >
                          <p
                            style={{
                              fontSize: 16,
                              color: 'white',
                              backgroundColor: '#867ecc',
                              height: 40,
                              textAlign: 'center',
                              padding: 10,
                              borderRadius: 10,
                              marginBottom: 5,
                              fontWeight: 600,
                              textOverflow: 'ellipsis',
                              width: 160,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              boxShadow: '#7268cd 0px 2px 1px',
                              marginTop: 4,
                            }}
                          >
                            {i18n.t('allTables')}
                          </p>
                        </Dropdown.Item>
                        {data.rooms
                          .filter((room) => room.tables && room.tables.length > 0)
                          .map((room, rIndex) => (
                            <Fragment key={`dropdown-rooms-${rIndex}`}>
                              <Dropdown.Item
                                className="bookingscreate-dropdown-item"
                                onClick={(x, d) => {
                                  setTableIdToNull();
                                  setSelectedRoom(d.value);
                                  const newTables = data.allTables.filter((table) =>
                                    room.tables.some((tTable) => table.number === tTable.number)
                                  );
                                  setSelectedTableIndex(0);
                                  setCheckedSelectedTableIndex(null);
                                  setBookings([]);
                                  setSelectedBookingIndex(null);
                                  setCart([]);
                                  setCartData({ toAdd: [], toCancel: [] });
                                  setDropdownOptions([]);
                                  resetBookingId();
                                  setSelectedGeneralDiscount(null);
                                  setData({ ...data, tables: newTables });
                                  showDefaultOnDisplay();
                                }}
                                value={room.name}
                              >
                                <p
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: 'white',
                                    backgroundColor: '#867ecc',
                                    height: 40,
                                    textAlign: 'center',
                                    padding: 10,
                                    borderRadius: 10,
                                    marginBottom: 5,
                                    marginTop: 7,
                                    textOverflow: 'ellipsis',
                                    width: 160,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    boxShadow: '#7268cd 0px 2px 1px',
                                  }}
                                >
                                  {room.name}
                                </p>
                              </Dropdown.Item>
                            </Fragment>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                style={{
                  display: 'flex',
                  height: 90,
                  gap: 10,
                  padding: 10,
                  overflowY: 'hidden',
                  marginRight: 20,
                }}
                className="bookings-create-tables-items-container"
              >
                {data.tables.map((table, index) => (
                  <div
                    id={open === true && index === selectedTableIndex ? 'selectedtable' : ''}
                    key={`bc-tbl-${index}`}
                    style={{
                      width: 160,
                      borderRadius: 16,
                      textAlign: 'center',
                      display: 'table',
                      flexShrink: 0,
                      backgroundColor:
                        open === true && index === selectedTableIndex ? '#7268cd' : '#dfe0e3',
                      boxShadow:
                        open === true && index === selectedTableIndex ? `#7268cd 0px 1px 10px` : '',
                      color: 'white',
                      cursor: 'pointer',
                      scrollMargin: 10,
                      transition: 'all 0.3s ease-in-out',
                    }}
                    onClick={() => {
                      setCart([]);
                      setCartData({ toAdd: [], toCancel: [] });
                      setSelectedTableIndex(index);
                      setBookings([]);
                      setSelectedBookingIndex(null);
                      setDropdownOptions([]);
                      resetBookingId();
                      setSelectedGeneralDiscount(null);
                      showDefaultOnDisplay();
                    }}
                  >
                    <p
                      style={{
                        height: '70px',
                        verticalAlign: 'middle',
                        display: 'table-cell',
                        fontWeight: 700,
                        fontSize: 18,
                        padding: 5,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {table.description + (table.number ? ` (${table.number})` : '')}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <div>
          {showSearch === true ? (
            <>
              <div
                style={{
                  padding:
                    data.tables.length === 1 &&
                    ((shop.isInHouseTableAllowed === true &&
                      shop.isTakeAwayTableAllowed === false) ||
                      (shop.isTakeAwayTableAllowed === true &&
                        shop.isInHouseTableAllowed === false))
                      ? 6
                      : 12,
                  paddingBottom: 0,
                  paddingRight: 6,
                  width: '100%',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <Input
                  ref={searchRef}
                  placeholder={i18n.t('bookingSearch')}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  value={searchValue}
                  icon={
                    searchValue.length > 0 ? (
                      <Icon
                        name="delete"
                        link
                        onClick={() => {
                          setSearchValue('');
                          handleSearch('');
                        }}
                      />
                    ) : (
                      <Icon name="search" />
                    )
                  }
                  className="search-bookings-create-input"
                  fluid
                  autoFocus
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <Grid
            stackable
            columns={3}
            stretched
            style={{
              alignContent: 'flex-start',
              paddingBottom: 0,
              marginTop: 10,
              marginLeft: 0,
              height:
                data.items && data.items.length > 0 && data.items.length < 6
                  ? ''
                  : showSearch === true
                  ? 'calc(100vh - 315px)'
                  : 'calc(100vh - 260px)',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
            className="bookings-create-items-scroll"
          >
            {shop.allowDiverseProducts === true ? (
              <Grid.Column
                style={{
                  height: 160,
                  padding: 12,
                  paddingTop:
                    data.tables.length === 1 &&
                    ((shop.isInHouseTableAllowed === true &&
                      shop.isTakeAwayTableAllowed === false) ||
                      (shop.isTakeAwayTableAllowed === true &&
                        shop.isInHouseTableAllowed === false))
                      ? 4
                      : 12,
                }}
              >
                <div
                  style={{
                    height: 30,
                    borderRadius: 20,
                    boxShadow: '#7268cd 0px 1px 5px',
                    background: 'white',
                    color: '#7268cd',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
                      const [, ...categories] = data.categories;
                      setDiverseProductModalOptions({
                        open: true,
                        categories,
                      });
                    }
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <img src={addButtonIcon} width={50} />
                  </div>
                </div>
              </Grid.Column>
            ) : (
              <></>
            )}
            {data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => (
                <Fragment key={`bc-itms-${index}`}>
                  {(item.availability === 'terminal' || item.availability === 'all') && (
                    <Grid.Column
                      style={{
                        height: 160,
                        padding: 12,
                        paddingTop:
                          data.tables.length === 1 &&
                          ((shop.isInHouseTableAllowed === true &&
                            shop.isTakeAwayTableAllowed === false) ||
                            (shop.isTakeAwayTableAllowed === true &&
                              shop.isInHouseTableAllowed === false))
                            ? 4
                            : 12,
                        animation: 'fadeIn187 0.2s ease-in-out',
                        opacity: 1,
                      }}
                    >
                      <div
                        ref={observe}
                        style={{
                          height: 130,
                          borderRadius: 20,
                          padding: 12,
                          paddingTop: 14,
                          fontWeight: 700,
                          fontSize: 16,
                          wordBreak: 'break-all',
                          textAlign: 'center',
                          boxShadow: `${getCategoryColor(item)}47 0px 1px 5px`,
                          background: 'white',
                          border: `2px solid ${getCategoryBorderColor(item)}70`,
                          color: getCategoryColor(item),
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setItemSettings({
                            totalPrice: item.price,
                            specialSelections: [],
                            notes: '',
                            optionGroups: [],
                          });
                          setItemDetailsModalOptions({ open: true, item });
                        }}
                      >
                        <div
                          style={{
                            margin: '0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            textAlign: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p
                            style={{
                              display: '-webkit-box',
                              lineClamp: 3,
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              whiteSpace: 'initial',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 3,
                              fontSize: 21,
                            }}
                          >
                            {item && item.title ? item.title : ''}
                          </p>
                          <p
                            style={{
                              bottom: 20,
                              position: 'absolute',
                              width: '100%',
                              left: 0,
                              color: 'black',
                            }}
                          >
                            {item && item.price ? `${asCurrency(item.price / 100)}€` : ''}
                          </p>
                        </div>
                      </div>
                    </Grid.Column>
                  )}
                </Fragment>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );

  const openCashDrawer = () => {
    if (
      shop.printers &&
      shop.printers.length > 0 &&
      shop.printers.find((printer) => printer.isMain === true)
    ) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => showPrinterError(printerDevice);
      printerDevice.openDrawer();
    }
  };

  const evaluateKeyDown = (e, state, update) => {
    const allowedKeysAdd = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'];
    const allowedKeysDelete = ['Backspace'];
    if (allowedKeysAdd.includes(e.key) === false && allowedKeysDelete.includes(e.key) === false) {
      return;
    }
    let num = [...state];
    const maxDigits = 8;
    const nNum = e.key;
    if (allowedKeysDelete.includes(e.key)) {
      if (num.every((n) => n === '0')) {
        num = [];
      }
      if (num.length > 0) {
        num.splice(num.length - 1, 1);
        num = [...num];
        if (num.length > 0 && num[num.length - 1] === ',') {
          num.splice(num.length - 1, 1);
          num = [...num];
        }
        update(num.join(''));
      }
      return;
    }
    if (num.includes(',') && num[num.findIndex((n) => n === ',') + 2]) return;
    if (nNum === 0 && num.every((n) => n === '0')) return;
    if (nNum === ',' && num.every((n) => n === '0')) {
      num.push('0');
      num.push(',');
      num = [...num];
    }
    if (nNum === ',' && num.includes(',') === false && num.length < maxDigits && num.length > 0) {
      num.push(nNum);
      num = [...num];
    } else if (nNum !== ',' && num.length < maxDigits) {
      num.push(nNum);
      num = [...num];
    }
    update(num.join(''));
  };

  const getBookedItemAmount = (item) => {
    if (item.discount && item.discount.amount > 0) {
      const discountedAmount = item.amount - item.discount.amount;
      return Object.is(discountedAmount, -0) ? 0 : Math.round(discountedAmount);
    }
    if (item.discount && item.discount.percentage > 0) {
      return Math.round(item.amount * (1 - item.discount.percentage));
    }
    return Math.round(item.amount);
  };

  const onSelectGeneralDiscount = (discount) => {
    if (selectedGeneralDiscount === discount && discount !== 0) {
      setSelectedGeneralDiscount(null);
      const tempCart = cart.map((item) => {
        const tempItem = item;
        tempItem.discount = {};
        tempItem.discount.percentage = 0;
        tempItem.discount.amount = 0;
        return tempItem;
      });
      const tempToAdd = cartData.toAdd.map((item) => {
        const tempItem = item;
        tempItem.discount = {};
        tempItem.discount.percentage = 0;
        tempItem.discount.amount = 0;
        return tempItem;
      });
      setCart(tempCart);
      setCartData({ ...cartData, toAdd: tempToAdd });
    } else {
      setSelectedGeneralDiscount(discount);
      if (discount === 0) {
        let cartWithoutToAdd = [];
        if (bookings.length > 0 && selectedBookingIndex !== null) {
          for (let i = 0; i < bookings[selectedBookingIndex].bookedItems.length; i++) {
            const tmpItem = bookings[selectedBookingIndex].bookedItems[i];
            const itemFromCart = cart.find((item) => item._id === tmpItem._id);
            if (itemFromCart) {
              bookings[selectedBookingIndex].bookedItems[i] = itemFromCart;
            }
          }
          cartWithoutToAdd = bookings[selectedBookingIndex].bookedItems;
          cartWithoutToAdd = cartWithoutToAdd.filter(
            (item) => cartData.toCancel.find((itm) => itm._id === item._id) == null
          );
        }
        setDiscountModalOptions({
          open: true,
          cartWithoutToAdd:
            cartWithoutToAdd && cartWithoutToAdd.length > 0
              ? cartWithoutToAdd.filter((item) => item.isRefund === false)
              : [],
          toAdd: cartData.toAdd.filter((item) => item.isRefund === false),
        });
      } else {
        const tempCart = cart.map((item) => {
          if (item.isRefund === false || item.isRefund == null) {
            const tempItem = item;
            tempItem.discount = {};
            tempItem.discount.percentage = discount;
            tempItem.discount.amount = 0;
            return tempItem;
          }
          return item;
        });
        const tempToAdd = cartData.toAdd.map((item) => {
          if (item.isRefund === false || item.isRefund == null) {
            const tempItem = item;
            tempItem.discount = {};
            tempItem.discount.percentage = discount;
            tempItem.discount.amount = 0;
            return tempItem;
          }
          return item;
        });
        setCart(tempCart);
        setCartData({ ...cartData, toAdd: tempToAdd });
        showOnDisplay(
          i18n.t('sum'),
          cart.reduce(
            (sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
            0
          )
        );
      }
    }
  };

  const discountAndSplitStyle = () => {
    if (
      bookings.length > 0 &&
      selectedBookingIndex != null &&
      bookings[selectedBookingIndex] &&
      bookings[selectedBookingIndex].bookedItems.filter((item) => item.isRefund === false).length >
        1 &&
      cart.filter((item) => item.isRefund === false).length > 1 &&
      user.isDiscounting === true &&
      shop.allowDiscounts === true
    ) {
      return {
        display: 'flex',
        gap: 5,
        marginTop: 4,
      };
    }
    return { display: 'unset' };
  };

  const parseDiscountSelection = () => {
    if (
      bookings.length > 0 &&
      selectedBookingIndex != null &&
      bookings[selectedBookingIndex] &&
      bookings[selectedBookingIndex].bookedItems.filter((item) => item.isRefund === false).length >
        1 &&
      cart.filter((item) => item.isRefund === false).length > 1 &&
      user.isDiscounting === true &&
      shop.allowDiscounts === true &&
      cart.filter(
        (item) => item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)
      ).length === 0
    ) {
      let cartWithoutToAdd = [];
      if (bookings.length > 0 && selectedBookingIndex !== null) {
        for (let i = 0; i < bookings[selectedBookingIndex].bookedItems.length; i++) {
          const tmpItem = bookings[selectedBookingIndex].bookedItems[i];
          const itemFromCart = cart.find((item) => item._id === tmpItem._id);
          if (itemFromCart) {
            bookings[selectedBookingIndex].bookedItems[i] = itemFromCart;
          }
        }
        cartWithoutToAdd = bookings[selectedBookingIndex].bookedItems;
        cartWithoutToAdd = cartWithoutToAdd.filter(
          (item) => cartData.toCancel.find((itm) => itm._id === item._id) == null
        );
      }
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            border: '1px solid #7268cd',
            borderRadius: 12,
            padding: 5,
            fontWeight: 700,
            fontSize: 15,
            color: '#7268cd',
            cursor: 'pointer',
            backgroundColor: '#7268cd12',
          }}
          onClick={() => {
            setDiscountModalOptions({
              open: true,
              cartWithoutToAdd:
                cartWithoutToAdd && cartWithoutToAdd.length > 0
                  ? cartWithoutToAdd.filter((item) => item.isRefund === false)
                  : [],
              toAdd: cartData.toAdd.filter((item) => item.isRefund === false),
            });
          }}
        >
          {i18n.t('discount').toUpperCase()}
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: 7,
          marginTop:
            selectedBookingIndex !== null &&
            bookings[selectedBookingIndex].bookedItems &&
            bookings[selectedBookingIndex].bookedItems.length > 1
              ? 0
              : 4,
        }}
      >
        <div
          className={
            selectedGeneralDiscount === 0.1
              ? 'bookings-create-discount-buttons'
              : 'bookings-create-discount-buttons-unselected'
          }
          onClick={() => onSelectGeneralDiscount(0.1)}
        >
          10%
        </div>
        <div
          className={
            selectedGeneralDiscount === 0.2
              ? 'bookings-create-discount-buttons'
              : 'bookings-create-discount-buttons-unselected'
          }
          onClick={() => onSelectGeneralDiscount(0.2)}
        >
          20%
        </div>
        <div
          className={
            selectedGeneralDiscount === 0.5
              ? 'bookings-create-discount-buttons'
              : 'bookings-create-discount-buttons-unselected'
          }
          onClick={() => onSelectGeneralDiscount(0.5)}
        >
          50%
        </div>
        <div
          className={
            selectedGeneralDiscount === 0
              ? 'bookings-create-discount-buttons'
              : 'bookings-create-discount-buttons-unselected'
          }
          style={{ fontWeight: 800 }}
          onClick={() => onSelectGeneralDiscount(0)}
        >
          %
        </div>
      </div>
    );
  };

  const parseSummary = () => (
    <>
      <div style={{ width: 390, padding: 10, paddingTop: 0 }}>
        <div
          style={{ height: 'calc(100vh - 160px)', overflowY: 'hidden', padding: 8 }}
          className="bookings-create-summary"
        >
          <div
            style={{
              height: 'calc(100vh - 440px)',
              maxHeight: 980,
              minHeight: 180,
              borderRadius: 20,
              padding: 10,
              background: 'white',
              boxShadow: '#7268cd4a 0px 1px 5px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
              {bookings && bookings.length > 0 && (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    backgroundColor: '#7268cd',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 500,
                    marginTop: 2,
                    paddingTop: 2,
                    fontSize: 12,
                  }}
                >
                  {
                    bookings.filter(
                      (booking) => booking.isRequest === false || booking.isRequest == null
                    ).length
                  }
                </div>
              )}
              <p style={{ textAlign: 'center', fontWeight: 700, fontSize: 17, marginBottom: 6 }}>
                {`${i18n.t('appCheckoutTitle')}`}
              </p>
            </div>
            {bookings &&
              bookings.length > 0 &&
              data.tables &&
              data.tables[selectedTableIndex] &&
              data.tables[selectedTableIndex].description !== 'In-House' &&
              data.tables[selectedTableIndex].description !== 'Take-Away' && (
                <Dropdown
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    textAlign: 'center',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1003,
                  }}
                  scrolling
                  placeholder={i18n.t('chooseBooking')}
                  options={dropdownOptions}
                  clearable
                  value={selectedBookingIndex}
                  onChange={(_, data) => {
                    if (data.value !== null && data.value.length !== 0) {
                      setSelectedBookingIndex(data.value);
                      setCart(
                        bookings[data.value].bookedItems.map((item) => {
                          const tmp = item;
                          tmp.discount = { amount: 0, percentage: 0 };
                          return tmp;
                        })
                      );
                      setCartData({ toAdd: [], toCancel: [] });
                      setSelectedGeneralDiscount(null);
                      setDiscountModalOptions({ open: false, cartWithoutToAdd: [], toAdd: [] });
                      showOnDisplay(
                        i18n.t('sum'),
                        bookings[data.value].bookedItems.reduce(
                          (sum, item) =>
                            sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                          0
                        )
                      );
                    } else {
                      setSelectedBookingIndex(null);
                      setCart([]);
                      setCartData({ toAdd: [], toCancel: [] });
                      setSelectedGeneralDiscount(null);
                      setDiscountModalOptions({ open: false, cartWithoutToAdd: [], toAdd: [] });
                      showDefaultOnDisplay();
                    }
                  }}
                />
              )}
            {isLoadingBooking === true && (
              <>
                <br />
                <br />
                <Loader
                  inline="centered"
                  inverted
                  className="bookings-create-loader"
                  active={isLoadingBooking}
                  style={{ margin: '0 auto' }}
                />
              </>
            )}
            {isLoadingBooking === false && (
              <>
                <div
                  style={{
                    padding: 8,
                    overflowY: 'scroll',
                    height: `${
                      bookings.length > 0 || selectedBookingIndex != null
                        ? 'calc(100% - 90px)'
                        : bookings.length === 0 &&
                          shop.allowDiscounts === true &&
                          user.isDiscounting === true
                        ? 'calc(100% - 67px)'
                        : 'calc(100% - 44px)'
                    }`,
                  }}
                  className="bookings-create-summary-inside-container"
                >
                  {parseCart()}
                </div>
                <div style={discountAndSplitStyle()}>
                  {shop.allowDiscounts === true &&
                  user.isDiscounting === true &&
                  cart.filter((item) => item.isRefund === false).length > 0 ? (
                    <>{parseDiscountSelection()}</>
                  ) : (
                    <></>
                  )}
                  {bookings.length > 0 &&
                  selectedBookingIndex != null &&
                  bookings[selectedBookingIndex] &&
                  bookings[selectedBookingIndex].bookedItems.filter(
                    (item) => item.isRefund === false
                  ).length > 1 &&
                  cart.filter(
                    (item) =>
                      item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)
                  ).length === 0 &&
                  cart.filter((item) => item.isRefund === false).length > 1 ? (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        border: '1px solid #7268cd',
                        borderRadius: 12,
                        padding: 5,
                        fontWeight: 700,
                        fontSize: 15,
                        color: '#7268cd',
                        cursor: 'pointer',
                        backgroundColor: '#7268cd12',
                      }}
                      onClick={() => {
                        setOldBookedItems(cloneDeep(bookings[selectedBookingIndex].bookedItems));
                        setBookingsSplitModalOptions({
                          open: true,
                          booking: bookings[selectedBookingIndex],
                        });
                      }}
                    >
                      {i18n.t('split').toUpperCase()}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            style={{
              background: 'white',
              boxShadow: '#7268cd4a 0px 1px 5px',
              height: 245,
              position: 'relative',
              top: 20,
              borderRadius: 20,
              padding: 10,
            }}
          >
            <p
              style={{
                textAlign: 'center',
                fontWeight: 800,
                fontSize: 28,
                paddingTop: 5,
                marginBottom: 10,
              }}
            >
              {`${asCurrency(calculateCartAmount())} €`}
            </p>
            <div
              className="customer-checkout-payment-button"
              style={{
                cursor: 'pointer',
                backgroundColor:
                  isLoadingBooking === false &&
                  user.isPaying === true &&
                  cart.filter((item) => item.isRefund === false).length > 0
                    ? '#7268cd'
                    : '#7268cd40',
                boxShadow:
                  isLoadingBooking === false &&
                  user.isPaying === true &&
                  cart.filter((item) => item.isRefund === false).length > 0
                    ? `#7268cd47 0px 1px 5px`
                    : `#7268cd40 0px 1px 5px`,
                fontWeight: 700,
                marginBottom: 16,
                letterSpacing: 2,
                fontSize: 20,
                paddingTop: 8,
                borderRadius: 12,
              }}
              onClick={() => {
                if (
                  isLoadingBooking === false &&
                  user.isPaying === true &&
                  cart.filter((item) => item.isRefund === false).length > 0
                ) {
                  showOnDisplay(i18n.t('sum'), calculateCartAmount() * 100);
                  openCashDrawer();
                  setPaymentModalOptions({
                    open: true,
                    amount: cart.reduce(
                      (sum, item) =>
                        sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                      0
                    ),
                    type: 'cash',
                  });
                }
              }}
            >
              {i18n.t('dailySummaryBar').toUpperCase()}
            </div>
            <div
              style={{
                display: 'flex',
                gap: 10,
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor:
                  cart.length > 0 &&
                  shop &&
                  ((terminal != null && terminal.connectionStatus === 'connected') ||
                    shop.isCardPaymentFromOtherAllowed === true) &&
                  cart.reduce(
                    (sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                    0
                  ) > 50 &&
                  isLoadingBooking === false &&
                  user.isPaying === true
                    ? '#7268cd'
                    : '#7268cd40',
                boxShadow:
                  cart.length > 0 &&
                  shop &&
                  ((terminal != null && terminal.connectionStatus === 'connected') ||
                    shop.isCardPaymentFromOtherAllowed === true) &&
                  cart.reduce(
                    (sum, item) => sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                    0
                  ) > 50 &&
                  isLoadingBooking === false &&
                  user.isPaying === true
                    ? `#7268cd47 0px 1px 5px`
                    : `#7268cd40 0px 1px 5px`,
                marginBottom: 16,
                fontWeight: 600,
                borderRadius: 12,
              }}
            >
              <p
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: 45,
                  textAlign: 'center',
                  lineHeight: '45px',
                  fontWeight: 700,
                  fontSize: 20,
                  color: 'white',
                  letterSpacing: 2,
                }}
              >
                {i18n.t('dailySummaryKarte').toUpperCase()}
              </p>
              <div
                className="customer-checkout-payment-button"
                style={{ zIndex: 5001 }}
                onClick={() => {
                  if (
                    cart.length > 0 &&
                    shop &&
                    ((terminal != null && terminal.connectionStatus === 'connected') ||
                      shop.isCardPaymentFromOtherAllowed === true) &&
                    cart.reduce(
                      (sum, item) =>
                        sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                      0
                    ) > 50 &&
                    isLoadingBooking === false &&
                    user.isPaying === true
                  ) {
                    showOnDisplay(i18n.t('sum'), calculateCartAmount() * 100);
                    setPaymentModalOptions({
                      open: true,
                      amount: cart.reduce(
                        (sum, item) =>
                          sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                        0
                      ),
                      type: 'card',
                    });
                    if (shop.isCardPaymentFromOtherAllowed === true && terminal == null) {
                      setCurrentInputField(inputFieldOptions.tips);
                    }
                    if (
                      (terminal != null && terminal.connectionStatus === 'connected') ||
                      shop.isCardPaymentFromOtherAllowed === true
                    ) {
                      const cleanedUpBookedItems = cart
                        .filter((item) => item.isRefund === false)
                        .map((item) => {
                          const newItem = {
                            itemTitle: item.itemTitle,
                            categoryName: item.categoryName,
                            specialSelections: item.specialSelections.map((special) => ({
                              specialSelectionId: special.specialSelectionId,
                              specialSelectionTitle: special.specialSelectionTitle,
                              specialSelectionPrice: special.specialSelectionPrice,
                            })),
                            options:
                              item.options && item.options.length > 0
                                ? item.options.map((option) => ({
                                    name: option.name,
                                    price: option.price,
                                  }))
                                : null,
                            amount: Math.round(item.amount),
                            taxRate: item.taxRate,
                            isRefund: item.isRefund,
                          };
                          if (item.discount)
                            newItem.discount = {
                              amount: item.discount.amount,
                              percentage: item.discount.percentage,
                            };
                          if (item.notes) newItem.notes = item.notes;
                          return newItem;
                        });
                      const readerDisplayCart = cleanedUpBookedItems.map((item, index) => {
                        if (
                          index ===
                          findLastIndex(cleanedUpBookedItems, (element) =>
                            isEqual(element, cleanedUpBookedItems[index])
                          )
                        ) {
                          return {
                            description: item.itemTitle,
                            amount: getBookedItemAmount(item),
                            quantity: countElement(cleanedUpBookedItems, index),
                          };
                        }
                        return null;
                      });
                      if (terminal != null) {
                        terminal.setReaderDisplay({
                          type: 'cart',
                          cart: {
                            line_items: readerDisplayCart.filter((value) => value),
                            total: cart.reduce(
                              (sum, item) =>
                                sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                              0
                            ),
                            currency: 'eur',
                          },
                        });
                      }
                    }
                  }
                }}
              />
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 4,
                  zIndex: 5000,
                  backgroundColor:
                    terminal && terminal.connectionStatus === 'connected'
                      ? 'mediumseagreen'
                      : isDiscoveringTerminal === true
                      ? 'orange'
                      : 'red',
                  marginLeft: 16,
                  marginTop: 6,
                  position: 'absolute',
                  right: 21,
                  display:
                    cart.length > 0 &&
                    shop &&
                    shop.terminalReaders &&
                    shop.terminalReaders.length > 0 &&
                    cart.reduce(
                      (sum, item) =>
                        sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                      0
                    ) > 50 &&
                    isLoadingBooking === false &&
                    user.isPaying === true
                      ? 'initial'
                      : 'none',
                }}
              />
              <div
                style={{
                  width: 64,
                  backgroundColor: '#7268cd',
                  borderRadius: '0px 12px 12px 0px',
                  borderLeft: '1px solid white',
                  display:
                    cart.length > 0 &&
                    shop &&
                    shop.terminalReaders &&
                    shop.terminalReaders.length > 0 &&
                    cart.reduce(
                      (sum, item) =>
                        sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                      0
                    ) > 50 &&
                    isLoadingBooking === false &&
                    user.isPaying === true
                      ? 'initial'
                      : 'none',
                  zIndex: 4999,
                }}
                onClick={() => {
                  if (
                    cart.length > 0 &&
                    shop &&
                    shop.terminalReaders &&
                    shop.terminalReaders.length > 0 &&
                    cart.reduce(
                      (sum, item) =>
                        sum + (item.isRefund === false ? getBookedItemAmount(item) : 0),
                      0
                    ) > 50 &&
                    isLoadingBooking === false &&
                    user.isPaying === true
                  ) {
                    openCardsModal();
                  }
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  src={readerIcon}
                  height={22}
                  style={{ display: 'block', margin: '0 auto', marginTop: 12, marginRight: 16 }}
                />
              </div>
            </div>
            <div
              className="customer-checkout-payment-button"
              style={{
                cursor: 'pointer',
                backgroundColor:
                  cart.length > 0 &&
                  data.tables[selectedTableIndex] &&
                  data.tables[selectedTableIndex].description !== 'In-House' &&
                  data.tables[selectedTableIndex].description !== 'Take-Away' &&
                  isLoadingBooking === false &&
                  (user.isPaying === true || user.isOrdering === true) &&
                  (cartData.toAdd.length > 0 || cartData.toCancel.length > 0) &&
                  cart.filter((item) => item.isRefund === false).length > 0 &&
                  cart.filter(
                    (item) =>
                      item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)
                  ).length === 0
                    ? '#7268cd'
                    : '#7268cd40',
                boxShadow:
                  cart.length > 0 &&
                  data.tables[selectedTableIndex] &&
                  data.tables[selectedTableIndex].description !== 'In-House' &&
                  data.tables[selectedTableIndex].description !== 'Take-Away' &&
                  isLoadingBooking === false &&
                  (user.isPaying === true || user.isOrdering === true) &&
                  (cartData.toAdd.length > 0 || cartData.toCancel.length > 0) &&
                  cart.filter((item) => item.isRefund === false).length > 0 &&
                  cart.filter(
                    (item) =>
                      item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)
                  ).length === 0
                    ? `#7268cd47 0px 1px 5px`
                    : `#7268cd40 0px 1px 5px`,
                fontWeight: 700,
                letterSpacing: 2,
                fontSize: 20,
                paddingTop: 8,
                borderRadius: 12,
              }}
              onClick={() => {
                if (
                  cart.length > 0 &&
                  data.tables[selectedTableIndex] &&
                  data.tables[selectedTableIndex].description !== 'In-House' &&
                  data.tables[selectedTableIndex].description !== 'Take-Away' &&
                  isLoadingBooking === false &&
                  (user.isPaying === true || user.isOrdering === true) &&
                  (cartData.toAdd.length > 0 || cartData.toCancel.length > 0) &&
                  cart.filter((item) => item.isRefund === false).length > 0 &&
                  cart.filter(
                    (item) =>
                      item.discount && (item.discount.amount > 0 || item.discount.percentage > 0)
                  ).length === 0
                ) {
                  showOnDisplay(i18n.t('sum'), calculateCartAmount() * 100);
                  setPayLaterModalOptions({ open: true });
                }
              }}
            >
              {i18n.t('bookingModalEditSave').toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (shop && user && open === true) {
    return (
      <>
        <AnimatedModal
          open={open}
          dimmer="blurring"
          closeOnEscape
          closeOnDimmerClick
          size="fullscreen"
          className="bookingscreatemodal"
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              padding: 12,
              backgroundColor: '#F5F5F9',
              height: 42,
              fontFamily: 'Montserrat',
            }}
          >
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => closeModal(false)}
              floated="right"
              className="bookings-btn"
              circular
            />
            <Button
              icon={{ name: 'search', color: showSearch ? 'grey' : '', inverted: showSearch }}
              color={showSearch ? 'teal' : 'black'}
              basic={!showSearch}
              style={{ color: 'red' }}
              className="bookings-btn-split-empl"
              onClick={() => {
                if (searchRef.current) {
                  searchRef.current.focus();
                }
                setSearchValue('');
                setShowSearch(!showSearch);
                setData({ ...data, items: data.allItems });
                setSelectedCategoryIndex(0);
              }}
              circular
            />
          </Modal.Header>
          <Modal.Content
            className="bookings-create-content unselectable"
            style={{ padding: 12, paddingTop: 0 }}
          >
            {isLoadingInitialData === true && (
              <>
                <Loader
                  size="massive"
                  active={isLoadingInitialData}
                  inverted
                  className="bookings-create-loader"
                />
              </>
            )}
            {isLoadingInitialData === false && data.tables.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                }}
                className="bookings-create-content-mobile"
              >
                {parseCategories()}
                {parseTablesAndItems()}
                {parseSummary()}
              </div>
            )}
            {isLoadingInitialData === false &&
              (data.allTables.length === 0 ||
                // eslint-disable-next-line prettier/prettier
                (tableId && data.allTables.find((table) => table.description !== "In-House" && table.description !== "Take-Away" && table._id.toString() === tableId) == null)) && (
                <>
                  {/* eslint-disable-next-line react/jsx-indent */}
                  <AnimatedModal dimmer="blurring" size="tiny" open>
                    <Modal.Header style={{ borderBottom: 'none', fontFamily: 'Montserrat' }}>
                      <p>{i18n.t('newPrinterErrorTitle')}</p>
                    </Modal.Header>
                    <Modal.Content className="unselectable">
                      <p style={{ fontWeight: 600 }}>
                        {tableId &&
                        data.allTables.find(
                          (table) =>
                            table.description !== 'In-House' &&
                            table.description !== 'Take-Away' &&
                            table._id.toString() === tableId
                        ) == null
                          ? i18n.t('tableNotAvailableError1')
                          : i18n.t('tableNotAvailableError2')}
                      </p>
                    </Modal.Content>
                    <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
                      <Button content="OK" color="teal" onClick={onFinalErrorWarningModalOk} />
                    </Modal.Actions>
                  </AnimatedModal>
                </>
              )}
          </Modal.Content>
        </AnimatedModal>
        <AnimatedModal
          open={itemDetailsModalOptions.open}
          size="small"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{
              background: '#f5f5f9',
              borderBottom: 'none',
              wordBreak: 'break-all',
              width: '95%',
              fontFamily: 'Montserrat',
            }}
          >
            {itemDetailsModalOptions.item
              ? `${itemDetailsModalOptions.item.title} - ${getCategoryTranslationForName(
                  itemDetailsModalOptions.item.categoryName
                )}`
              : ''}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setItemSettings({
                  totalPrice: 0,
                  specialSelections: [],
                  notes: '',
                  optionGroups: [],
                });
                setItemDetailsModalOptions({ open: false, item: null });
              }}
              floated="right"
              className="settings-close"
              circular
            />
          </Modal.Header>
          <Modal.Content scrolling style={{ background: '#f5f5f9' }} className="unselectable">
            {itemDetailsModalOptions.item && (
              <>
                {itemDetailsModalOptions.item.optionGroups &&
                  itemDetailsModalOptions.item.optionGroups.length > 0 && (
                    <>{renderOptionGroups(itemDetailsModalOptions.item)}</>
                  )}
                {itemDetailsModalOptions.item.specialSelections &&
                  itemDetailsModalOptions.item.specialSelections.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">
                        {i18n.t('itemsSpecialSelections')}
                      </p>
                      {renderSpecialSelections(itemDetailsModalOptions.item)}
                    </>
                  )}
                {itemDetailsModalOptions.item.ingredients &&
                  itemDetailsModalOptions.item.ingredients.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">{i18n.t('itemsIngredients')}</p>
                      {renderIngredients(itemDetailsModalOptions.item)}
                    </>
                  )}
                {itemDetailsModalOptions.item.allergens &&
                  itemDetailsModalOptions.item.allergens.length > 0 && (
                    <>
                      <p className="customer-item-screen-subtitles">{i18n.t('itemsAllergens')}</p>
                      {renderAllergens(itemDetailsModalOptions.item)}
                    </>
                  )}
                <p className="customer-item-screen-subtitles">{i18n.t('appNotes')}</p>
                <TextArea
                  placeholder={i18n.t('appDescription')}
                  className="customer-item-screen-textarea"
                  defaultValue={itemSettings.notes}
                  maxLength="160"
                  onChange={(e) => {
                    setItemSettings({
                      ...itemSettings,
                      notes: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div
              className="customer-items-checkout-button"
              style={{
                backgroundColor: '#7268cd',
                cursor: 'pointer',
                boxShadow: `#7268cd 0px 4px 12px`,
                position: 'relative',
                width: '100%',
                margin: '10px 0px',
                height: 50,
                paddingTop: 14,
              }}
              onClick={() => {
                if (cart && cart.filter((item) => item.isRefund === false).length < 150) {
                  if (
                    itemDetailsModalOptions.item.isDynamicPriced === true &&
                    itemDetailsModalOptions.dynamicPrice == null
                  ) {
                    setDefineDynamicPriceModalOptions({
                      open: true,
                      item: itemDetailsModalOptions.item,
                    });
                    return;
                  }
                  const bookedItem = {
                    itemId: itemDetailsModalOptions.item._id,
                    categoryName: itemDetailsModalOptions.item.categoryName,
                    itemTitle: itemDetailsModalOptions.item.title,
                    isRefund: false,
                    specialSelections: itemDetailsModalOptions.item.specialSelections
                      .filter((_, index) => itemSettings.specialSelections[index] === true)
                      .map((specialSelection) => ({
                        specialSelectionId: specialSelection._id,
                        specialSelectionTitle: specialSelection.name,
                        specialSelectionPrice: specialSelection.price,
                      })),
                    options: itemSettings.optionGroups.map((optionGroup) => ({
                      name: optionGroup.options.find((option) => option.checked === true).name,
                      price: optionGroup.options.find((option) => option.checked === true).price,
                    })),
                    notes: itemSettings.notes,
                    amount:
                      Math.round(itemSettings.totalPrice) +
                      (itemDetailsModalOptions.dynamicPrice || 0),
                    discount:
                      selectedGeneralDiscount != null
                        ? { percentage: selectedGeneralDiscount, amount: 0 }
                        : { percentage: 0, amount: 0 },
                    taxRate:
                      data.tables[selectedTableIndex].isTakeAway === 'true' ||
                      data.tables[selectedTableIndex].isTakeAway === true
                        ? itemDetailsModalOptions.item.takeAwayTaxRate
                        : itemDetailsModalOptions.item.inHouseTaxRate,
                  };
                  showOnDisplay(bookedItem.itemTitle, bookedItem.amount);
                  const { toAdd } = cartData;
                  toAdd.push(bookedItem);
                  setCartData({ ...cartData, toAdd });
                  setCart([...cart, bookedItem]);
                  setItemDetailsModalOptions({ open: false, item: null });
                  setItemSettings({
                    totalPrice: 0,
                    specialSelections: [],
                    notes: '',
                    optionGroups: [],
                  });
                }
              }}
            >
              {itemDetailsModalOptions.item
                ? itemDetailsModalOptions.item.isDynamicPriced === true &&
                  itemDetailsModalOptions.dynamicPrice == null
                  ? i18n.t('definePrice')
                  : itemDetailsModalOptions.item.isDynamicPriced === true &&
                    itemDetailsModalOptions.dynamicPrice != null
                  ? `${asCurrency(
                      (itemSettings.totalPrice + (itemDetailsModalOptions.dynamicPrice || 0)) / 100
                    )} €`
                  : `${asCurrency(itemSettings.totalPrice / 100)} €`
                : ''}
            </div>
          </Modal.Actions>
        </AnimatedModal>
        <AnimatedModal
          open={defineDynamicPriceModalOptions.open}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Header
            style={{
              background: '#f5f5f9',
              borderBottom: 'none',
              wordBreak: 'break-all',
              fontFamily: 'Montserrat',
            }}
          >
            {i18n.t('definePrice')}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setDefineDynamicPriceModalOptions({ open: false, item: null });
                setDynamicPrice('0');
              }}
              floated="right"
              className="settings-close"
              circular
            />
          </Modal.Header>
          <Modal.Content
            scrolling
            style={{ background: '#f5f5f9', paddingTop: 4, paddingBottom: 4 }}
            className="unselectable"
          >
            {defineDynamicPriceModalOptions.item &&
            (defineDynamicPriceModalOptions.item.dynamicPriceMin > 0 ||
              defineDynamicPriceModalOptions.item.dynamicPriceMax > 0) ? (
              // eslint-disable-next-line react/jsx-indent
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  gap: 8,
                }}
              >
                <div>
                  <p style={{ fontSize: 16, fontWeight: 700, marginBottom: 12 }}>Min</p>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 19,
                      padding: 10,
                      textAlign: 'center',
                      borderRadius: 16,
                      backgroundColor: '#7268cd',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setDynamicPrice(
                        asCurrency(defineDynamicPriceModalOptions.item.dynamicPriceMin / 100)
                      );
                    }}
                  >
                    {asCurrency(defineDynamicPriceModalOptions.item.dynamicPriceMin / 100)}€
                  </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 12,
                      }}
                    >
                      {i18n.t('bookingsPrice')}
                    </p>
                    <p
                      style={{
                        marginRight: 4,
                        border: '1px solid #7268cd',
                        backgroundColor: '#eeecfc',
                        padding: 4,
                        paddingTop: 1,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderRadius: 8,
                        height: 26,
                        cursor: 'pointer',
                        color: '#7268cd',
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                      onClick={() => {
                        if (
                          dynamicPrice &&
                          dynamicPrice !== '0' &&
                          dynamicPrice !== '0,00' &&
                          dynamicPrice !== '-0' &&
                          dynamicPrice !== '-0,00' &&
                          parseFloat(dynamicPrice.replace(',', '.')) !== 0
                        ) {
                          let cleanNumber = dynamicPrice;
                          cleanNumber = cleanNumber.replace('-', '');
                          cleanNumber = cleanNumber.replace(',', '.');
                          const numberValue = parseFloat(cleanNumber);
                          if (!Number.isNaN(numberValue)) {
                            const newValue = numberValue * (dynamicPrice.startsWith('-') ? 1 : -1);
                            setDynamicPrice(asCurrency(newValue));
                          }
                        }
                      }}
                    >
                      -/+
                    </p>
                  </div>
                  <CurrencyInput
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    readOnly
                    disableGroupSeparators
                    value={dynamicPrice}
                    style={{
                      caretColor: 'transparent',
                      fontSize: 24,
                      textAlign: 'right',
                      fontWeight: 700,
                      padding: 10,
                      fontFamily: 'Montserrat',
                      borderRadius: 16,
                      width: '100%',
                      border: 'none',
                      cursor: 'default',
                    }}
                    className="currrencyinput-onfocus"
                    onKeyDown={(e) => {
                      evaluateKeyDown(e, dynamicPrice, setDynamicPrice);
                    }}
                  />
                </div>
                <div>
                  <p style={{ fontSize: 16, fontWeight: 700, marginBottom: 12 }}>Max</p>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 19,
                      padding: 10,
                      textAlign: 'center',
                      borderRadius: 16,
                      backgroundColor: '#7268cd',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setDynamicPrice(
                        asCurrency(defineDynamicPriceModalOptions.item.dynamicPriceMax / 100)
                      );
                    }}
                  >
                    {asCurrency(defineDynamicPriceModalOptions.item.dynamicPriceMax / 100)}€
                  </p>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', marginBottom: 14 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 12,
                      }}
                    >
                      {i18n.t('bookingsPrice')}
                    </p>
                    <p
                      style={{
                        marginRight: 4,
                        border: '1px solid #7268cd',
                        backgroundColor: '#eeecfc',
                        padding: 4,
                        paddingTop: 1,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderRadius: 8,
                        height: 26,
                        cursor: 'pointer',
                        color: '#7268cd',
                        fontWeight: 700,
                        fontSize: 16,
                      }}
                      onClick={() => {
                        if (
                          dynamicPrice &&
                          dynamicPrice !== '0' &&
                          dynamicPrice !== '0,00' &&
                          dynamicPrice !== '-0' &&
                          dynamicPrice !== '-0,00' &&
                          parseFloat(dynamicPrice.replace(',', '.')) !== 0
                        ) {
                          let cleanNumber = dynamicPrice;
                          cleanNumber = cleanNumber.replace('-', '');
                          cleanNumber = cleanNumber.replace(',', '.');
                          const numberValue = parseFloat(cleanNumber);
                          if (!Number.isNaN(numberValue)) {
                            const newValue = numberValue * (dynamicPrice.startsWith('-') ? 1 : -1);
                            setDynamicPrice(asCurrency(newValue));
                          }
                        }
                      }}
                    >
                      -/+
                    </p>
                  </div>
                  <CurrencyInput
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    readOnly
                    disableGroupSeparators
                    value={dynamicPrice}
                    style={{
                      caretColor: 'transparent',
                      fontSize: 24,
                      textAlign: 'right',
                      fontWeight: 700,
                      padding: 10,
                      fontFamily: 'Montserrat',
                      borderRadius: 16,
                      width: '100%',
                      border: 'none',
                      cursor: 'default',
                    }}
                    className="currrencyinput-onfocus"
                    onKeyDown={(e) => {
                      evaluateKeyDown(e, dynamicPrice, setDynamicPrice);
                    }}
                  />
                </div>
              </div>
            )}
            <NumPad
              maxDigits={8}
              currentValue={dynamicPrice}
              onChange={(num) => {
                if (num.length === 0) {
                  setDynamicPrice('0');
                } else {
                  setDynamicPrice(num);
                }
              }}
            />
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div
              className="customer-items-checkout-button"
              style={{
                backgroundColor: '#7268cd',
                cursor: 'pointer',
                boxShadow: `#7268cd 0px 4px 12px`,
                position: 'relative',
                width: '100%',
                margin: '10px 0px',
                height: 50,
                paddingTop: 14,
              }}
              onClick={() => {
                const definedDynamicPrice = Math.round(
                  parseFloat(dynamicPrice.replace(',', '.') * 100)
                );
                if (
                  itemDetailsModalOptions.item.dynamicPriceMax === 0 &&
                  itemDetailsModalOptions.item.dynamicPriceMin === 0
                ) {
                  setItemDetailsModalOptions({
                    ...itemDetailsModalOptions,
                    dynamicPrice: definedDynamicPrice,
                  });
                  setDefineDynamicPriceModalOptions({ open: false, item: null });
                  setDynamicPrice('0');
                  return;
                }
                if (
                  definedDynamicPrice <= itemDetailsModalOptions.item.dynamicPriceMax &&
                  definedDynamicPrice >= itemDetailsModalOptions.item.dynamicPriceMin
                ) {
                  setItemDetailsModalOptions({
                    ...itemDetailsModalOptions,
                    dynamicPrice: definedDynamicPrice,
                  });
                  setDefineDynamicPriceModalOptions({ open: false, item: null });
                  setDynamicPrice('0');
                  return;
                }
                setErrorWarningModalOptions({
                  open: true,
                  message: i18n.t('itemDynamicPriceRangeError'),
                });
              }}
            >
              {asCurrency(parseFloat(dynamicPrice.replace(',', '.')))}€
            </div>
          </Modal.Actions>
        </AnimatedModal>
        <AnimatedModal
          open={paymentModalOptions.open && paymentModalOptions.type !== null}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Content
            scrolling
            style={{
              background: '#f5f5f9',
              paddingTop: 12,
              paddingBottom: 10,
              maxHeight: '80vh',
              scrollbarWidth: 'none',
            }}
            className="unselectable no-scrollbar"
          >
            {paymentModalOptions.amount !== null && paymentModalOptions.type === 'cash' && (
              <>
                <div style={{ display: 'flex', marginBottom: 12 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      {i18n.t('faellig')}
                    </p>
                    <CurrencyInput
                      intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                      readOnly
                      disableGroupSeparators
                      value={asCurrency(paymentModalOptions.amount / 100)
                        .replaceAll('.', '')
                        .replace(',', '.')}
                      style={{
                        caretColor: 'transparent',
                        fontSize: 24,
                        textAlign: 'right',
                        fontWeight: 700,
                        padding: 10,
                        fontFamily: 'Montserrat',
                        borderRadius: 16,
                        width: '100%',
                        border: 'none',
                        cursor: 'default',
                      }}
                      className="currrencyinput-onfocus"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 16,
                    marginBottom: 2,
                  }}
                >
                  {shop.tipsAllowed === true && (
                    <div style={{ width: '100%' }}>
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: 700,
                          marginBottom: 10,
                        }}
                      >
                        {i18n.t('tips2')}
                      </p>
                      <CurrencyInput
                        intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                        disableGroupSeparators
                        allowNegativeValue={false}
                        readOnly
                        id="trkgld"
                        name="trkgld"
                        key="trkgld"
                        maxLength={8}
                        defaultValue={0}
                        value={numPadNumTips}
                        style={{
                          float: 'right',
                          outline:
                            currentInputField === inputFieldOptions.tips
                              ? '1px solid #7268cd'
                              : 'none',
                          fontSize: 24,
                          textAlign: 'right',
                          fontWeight: 700,
                          padding: 10,
                          fontFamily: 'Montserrat',
                          borderRadius: 16,
                          width: '100%',
                          marginBottom: 12,
                          border: 'none',
                        }}
                        onFocus={(event) => {
                          setCurrentInputField(inputFieldOptions.tips);
                        }}
                        onKeyDown={(e) => {
                          evaluateKeyDown(e, numPadNumTips, setNumPadNumTips);
                        }}
                      />
                    </div>
                  )}
                  <div style={{ width: '100%' }}>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      {i18n.t('given')}
                    </p>
                    <CurrencyInput
                      intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                      disableGroupSeparators
                      allowNegativeValue={false}
                      readOnly
                      id="rckgld"
                      name="rckgld"
                      key="rckgld"
                      maxLength={8}
                      defaultValue={0}
                      value={numPadNumChange}
                      ref={changeInputRef}
                      style={{
                        caretColor: 'transparent',
                        float: 'right',
                        outline:
                          currentInputField === inputFieldOptions.change
                            ? '1px solid #7268cd'
                            : 'none',
                        fontSize: 24,
                        textAlign: 'right',
                        fontWeight: 700,
                        padding: 10,
                        fontFamily: 'Montserrat',
                        borderRadius: 16,
                        width: '100%',
                        marginBottom: 12,
                        border: 'none',
                      }}
                      onFocus={() => {
                        setCurrentInputField(inputFieldOptions.change);
                      }}
                      onKeyDown={(e) => {
                        evaluateKeyDown(e, numPadNumChange, setNumPadNumChange);
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 0, width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      {i18n.t('change2')}
                    </p>
                    <CurrencyInput
                      intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                      readOnly
                      value={
                        numPadNumChange === null ||
                        numPadNumChange === 0 ||
                        numPadNumChange === '0' ||
                        numPadNumChange === '0,'
                          ? '0'
                          : `${asCurrency(
                              calculateChange(
                                parseFloat(numPadNumChange.replace(',', '.')) -
                                  parseFloat(numPadNumTips.replace(',', '.'))
                              )
                            )
                              .replaceAll('.', '')
                              .replace(',', '.')}`
                      }
                      style={{
                        caretColor: 'transparent',
                        fontSize: 24,
                        textAlign: 'right',
                        fontWeight: 700,
                        padding: 10,
                        fontFamily: 'Montserrat',
                        borderRadius: 16,
                        width: '100%',
                        border: 'none',
                        cursor: 'default',
                      }}
                      className="currrencyinput-onfocus"
                    />
                  </div>
                </div>
                <NumPad
                  maxDigits={8}
                  currentValue={
                    currentInputField === inputFieldOptions.change ? numPadNumChange : numPadNumTips
                  }
                  onChange={(num) => {
                    if (num.length === 0) {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange('0')
                        : setNumPadNumTips('0');
                    } else {
                      currentInputField === inputFieldOptions.change
                        ? setNumPadNumChange(num)
                        : setNumPadNumTips(num);
                    }
                  }}
                />
              </>
            )}
            {paymentModalOptions.amount !== null &&
              paymentModalOptions.type === 'card' &&
              terminal != null &&
              terminal.connectionStatus === 'connected' &&
              shop.isCardPaymentFromOtherAllowed === false && (
                <>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      paddingTop: 4,
                      fontSize: 19,
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    {i18n.t('warningCardPayment1')}
                  </p>
                </>
              )}
            {paymentModalOptions.amount !== null &&
              paymentModalOptions.type === 'card' &&
              terminal == null &&
              shop.isCardPaymentFromOtherAllowed === true && (
                <>
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      paddingTop: 4,
                      fontSize: 17,
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    {i18n.t('warningCardPayment2')}
                  </p>
                  <p style={{ fontSize: 12, width: '100%', textAlign: 'center' }}>
                    <span style={{ fontStyle: 'italic', fontWeight: 700 }}>moaby </span>
                    {i18n.t('warningCardPayment3')}
                  </p>
                  {shop.tipsAllowed === true && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          marginTop: 16,
                        }}
                      >
                        <p
                          style={{
                            float: 'left',
                            fontSize: 14,
                            fontWeight: 700,
                          }}
                        >
                          {i18n.t('tips2')}
                        </p>
                        <CurrencyInput
                          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                          disableGroupSeparators
                          allowNegativeValue={false}
                          readOnly
                          id="trkgld"
                          name="trkgld"
                          key="trkgld"
                          maxLength={8}
                          defaultValue={0}
                          value={numPadNumTips}
                          style={{
                            float: 'right',
                            outline:
                              currentInputField === inputFieldOptions.tips
                                ? '1px solid #7268cd'
                                : 'none',
                            fontSize: 22,
                            textAlign: 'right',
                            fontWeight: 700,
                            padding: 10,
                            fontFamily: 'Montserrat',
                            borderRadius: 16,
                            width: '100%',
                            marginBottom: 8,
                            border: 'none',
                          }}
                          onFocus={(event) => {
                            setCurrentInputField(inputFieldOptions.tips);
                          }}
                          onKeyDown={(e) => {
                            evaluateKeyDown(e, numPadNumTips, setNumPadNumTips);
                          }}
                        />
                      </div>
                      <NumPad
                        maxDigits={8}
                        currentValue={
                          currentInputField === inputFieldOptions.change
                            ? numPadNumChange
                            : numPadNumTips
                        }
                        onChange={(num) => {
                          if (num.length === 0) {
                            currentInputField === inputFieldOptions.change
                              ? setNumPadNumChange('0')
                              : setNumPadNumTips('0');
                          } else {
                            currentInputField === inputFieldOptions.change
                              ? setNumPadNumChange(num)
                              : setNumPadNumTips(num);
                          }
                        }}
                      />
                    </>
                  )}
                </>
              )}
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: isLoadingPaymentData === true ? '1px solid #ddd' : '1px solid red',
                  position: 'relative',
                  width: '100%',
                  margin: '20px 0px',
                  height: 44,
                  verticalAlign: 'middle',
                  color: isLoadingPaymentData === true ? '#ddd' : 'red',
                  padding: 10,
                  fontSize: 20,
                  borderRadius: 14,
                }}
                onClick={() => {
                  setNumPadNumChange('0');
                  setNumPadNumTips('0');
                  setCurrentInputField(inputFieldOptions.change);
                  if (isLoadingPaymentData === false) {
                    setPaymentModalOptions({ open: false, amount: 0, type: null });
                    setIsLoadingPaymentData(false);
                    if (
                      paymentModalOptions.type === 'card' &&
                      shop.isCardPaymentFromOtherAllowed === false
                    ) {
                      setIsSettingUpTerminal(false);
                      terminal.cancelCollectPaymentMethod();
                      terminal.clearReaderDisplay();
                    }
                  }
                  if (
                    isLoadingPaymentData === false &&
                    isSettingUpTerminal === true &&
                    paymentModalOptions.type === 'card' &&
                    shop.isCardPaymentFromOtherAllowed === false
                  ) {
                    setPaymentModalOptions({ open: false, amount: 0, type: null });
                    setIsLoadingPaymentData(false);
                    setIsSettingUpTerminal(false);
                    terminal.cancelCollectPaymentMethod();
                    terminal.clearReaderDisplay();
                  }
                  if (
                    isLoadingPaymentData === false &&
                    isSettingUpTerminal === true &&
                    paymentModalOptions.type === 'card' &&
                    shop.isCardPaymentFromOtherAllowed === true
                  ) {
                    setPaymentModalOptions({ open: false, amount: 0, type: null });
                    setIsLoadingPaymentData(false);
                  }
                }}
              >
                {i18n.t('appPayCancel')}
              </div>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: '#7268cd',
                  cursor: 'pointer',
                  boxShadow: `#7268cd 0px 4px 12px`,
                  position: 'relative',
                  width: '100%',
                  margin: '20px 0px',
                  height: 44,
                  verticalAlign: 'middle',
                  padding: 10,
                  fontSize: 22,
                  fontWeight: 700,
                  borderRadius: 14,
                }}
                onClick={async () => {
                  if (isLoadingPaymentData === false) {
                    if (paymentModalOptions.type === 'cash') {
                      await payByCash();
                    }
                    if (
                      paymentModalOptions.type === 'card' &&
                      isSettingUpTerminal === false &&
                      shop.isCardPaymentFromOtherAllowed === false
                    ) {
                      await payByCard();
                    }
                    if (
                      paymentModalOptions.type === 'card' &&
                      shop.isCardPaymentFromOtherAllowed === true
                    ) {
                      await payByCardOther();
                    }
                  }
                  setNumPadNumChange('0');
                  setNumPadNumTips('0');
                }}
              >
                {isLoadingPaymentData ||
                (paymentModalOptions.type === 'card' && isSettingUpTerminal === true) ? (
                  <>
                    <Loader active={isLoadingPaymentData} />
                  </>
                ) : (
                  <>
                    {shop.isCardPaymentFromOtherAllowed === true &&
                    paymentModalOptions.type === 'card'
                      ? i18n.t('bookingPaymentPaid')
                      : i18n.t('bookingsModalPay')}
                  </>
                )}
              </div>
            </div>
          </Modal.Actions>
        </AnimatedModal>
        <ErrorModal
          open={errorWarningModalOptions.open}
          title={i18n.t('newPrinterErrorTitle')}
          message={errorWarningModalOptions.message}
          onOk={onErrorWarningModalOk}
        />
        <AnimatedModal
          open={payLaterModalOptions.open === true}
          size="tiny"
          dimmer="blurring"
          style={{ background: '#f5f5f9' }}
        >
          <Modal.Content scrolling style={{ background: '#f5f5f9' }} className="unselectable">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: 4,
                fontSize: 19,
                fontWeight: 700,
                marginBottom: 10,
              }}
            >
              {i18n.t('saveThisOrder')}
            </p>
          </Modal.Content>
          <Modal.Actions
            style={{ background: '#f5f5f9', borderTop: 'none', height: '100px !important' }}
          >
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  color: 'red',
                  padding: 15,
                  fontSize: 17,
                }}
                onClick={() => {
                  if (isLoadingPaymentData === false) {
                    setPayLaterModalOptions({ open: false });
                  }
                }}
              >
                {i18n.t('bookingEditBookingWarningNo')}
              </div>
              <div
                className="customer-items-checkout-button"
                style={{
                  backgroundColor: '#7268cd',
                  cursor: 'pointer',
                  boxShadow: `#7268cd 0px 4px 12px`,
                  position: 'relative',
                  width: '100%',
                  margin: '10px 0px',
                  height: 50,
                  verticalAlign: 'middle',
                  padding: 15,
                  fontSize: 18,
                }}
                onClick={() => {
                  if (
                    isLoadingPaymentData === false &&
                    data.tables[selectedTableIndex] &&
                    data.tables[selectedTableIndex].description !== 'In-House' &&
                    data.tables[selectedTableIndex].description !== 'Take-Away'
                  ) {
                    payLater();
                  }
                }}
              >
                {isLoadingPaymentData ? (
                  <>
                    <Loader active={isLoadingPaymentData} />
                  </>
                ) : (
                  <>{i18n.t('bookingEditBookingWarningYes')}</>
                )}
              </div>
            </div>
          </Modal.Actions>
        </AnimatedModal>
        <AnimatedModal
          dimmer="blurring"
          size="small"
          open={bookingsSplitModal.open}
          style={{ backgroundColor: '#f5f5f9' }}
        >
          <Modal.Header
            style={{ borderBottom: 'white', backgroundColor: '#f5f5f9', fontFamily: 'Montserrat' }}
          >
            {i18n.t('splitOrder')}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setNewBookedItems([]);
                setOldBookedItems([]);
                setBookingsSplitModalOptions({ open: false, booking: null });
              }}
              floated="right"
              circular
              className="items-close-btn"
            />
          </Modal.Header>
          <Modal.Content
            scrolling
            style={{ paddingTop: 10, backgroundColor: '#f5f5f9' }}
            className="unselectable"
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '45%' }}>
                <p style={{ fontWeight: 600, fontSize: 16 }}>
                  {i18n.t('orderTitle')}{' '}
                  {bookingsSplitModal.open === true ? bookingsSplitModal.booking.bookingId : ''}
                </p>
                <div
                  style={{
                    padding: 10,
                    borderRadius: 14,
                    width: '100%',
                    height: 200,
                    overflowY: 'scroll',
                    backgroundColor: 'white',
                    boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  }}
                  className="split-booking-no-scrollbar"
                >
                  {bookingsSplitModal.booking &&
                    oldBookedItems.map((item, index) => (
                      <div
                        key={`${index}split-old`}
                        style={{
                          display: item.isRefund === false ? 'flex' : 'none',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          marginBottom: 12,
                          padding: 4,
                          paddingBottom: 8,
                          borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
                        }}
                        onClick={() => {
                          if (
                            oldBookedItems.filter((item) => item.isRefund === false).length !== 1
                          ) {
                            oldBookedItems.splice(index, 1);
                            setNewBookedItems([...newBookedItems, item]);
                            setOldBookedItems([...oldBookedItems]);
                          }
                        }}
                      >
                        {item.isRefund === false ? (
                          <>
                            <div>
                              <p style={{ fontWeight: 500, margin: 0, marginBottom: 2 }}>
                                {item.itemTitle}
                              </p>
                              {item.options &&
                                item.options.length > 0 &&
                                item.options.map((option) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: 4,
                                      marginLeft: 2,
                                      marginBottom: 4,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p className="bookings-extras-options-indicator">O</p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        fontStyle: 'italic',
                                      }}
                                    >
                                      {` ${option.name}`}
                                    </p>
                                  </div>
                                ))}
                              {item.specialSelections &&
                                item.specialSelections.length > 0 &&
                                item.specialSelections.map((special) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: 4,
                                      marginLeft: 2,
                                      marginBottom: 4,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p className="bookings-extras-options-indicator">E</p>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        fontStyle: 'italic',
                                      }}
                                    >
                                      {` ${special.specialSelectionTitle}`}
                                    </p>
                                  </div>
                                ))}
                            </div>
                            <p style={{ fontWeight: 500, textAlign: 'right' }}>
                              {`${asCurrency(getBookedItemAmount(item) / 100)} €`}
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                </div>
                <p
                  style={{
                    textAlign: 'right',
                    paddingTop: 10,
                    fontSize: 17,
                    paddingRight: 8,
                    fontWeight: 600,
                  }}
                >
                  {`${asCurrency(
                    oldBookedItems
                      .filter((item) => item.isRefund === false)
                      .reduce((sum, { amount }) => sum + amount, 0) / 100
                  )}€`}
                </p>
              </div>
              <div style={{ alignSelf: 'center', fontSize: 36, color: '#7268cd' }}>
                <Icon name="arrow right" />
              </div>
              <div style={{ width: '45%' }}>
                <p style={{ fontWeight: 600, fontSize: 16 }}>{i18n.t('newOrder')}</p>
                <div
                  style={{
                    padding: 10,
                    borderRadius: 14,
                    width: '100%',
                    height: 200,
                    overflowY: 'scroll',
                    backgroundColor: 'white',
                    boxShadow: 'rgb(114 104 205 / 40%) 0px 3px 7px',
                  }}
                  className="split-booking-no-scrollbar"
                >
                  {bookingsSplitModal.booking &&
                    newBookedItems.map((item, index) => (
                      <div
                        key={`${index}split-new`}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          marginBottom: 12,
                          padding: 4,
                          paddingBottom: 8,
                          borderBottom: '1px solid rgba(210, 210, 210, 0.35)',
                        }}
                        onClick={() => {
                          newBookedItems.splice(index, 1);
                          setNewBookedItems([...newBookedItems]);
                          setOldBookedItems([...oldBookedItems, item]);
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: 500, margin: 0 }}>{item.itemTitle}</p>
                          {item.options &&
                            item.options.length > 0 &&
                            item.options.map((option) => (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 4,
                                  marginLeft: 2,
                                  marginBottom: 4,
                                  alignItems: 'center',
                                }}
                              >
                                <p className="bookings-extras-options-indicator">O</p>
                                <p
                                  style={{
                                    marginBottom: 0,
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {` ${option.name}`}
                                </p>
                              </div>
                            ))}
                          {item.specialSelections &&
                            item.specialSelections.length > 0 &&
                            item.specialSelections.map((special) => (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 4,
                                  marginLeft: 2,
                                  marginBottom: 4,
                                  alignItems: 'center',
                                }}
                              >
                                <p className="bookings-extras-options-indicator">E</p>
                                <p
                                  style={{
                                    marginBottom: 0,
                                    fontStyle: 'italic',
                                  }}
                                >
                                  {` ${special.specialSelectionTitle}`}
                                </p>
                              </div>
                            ))}
                        </div>
                        <p style={{ fontWeight: 500, textAlign: 'right' }}>
                          {`${asCurrency(getBookedItemAmount(item) / 100)} €`}
                        </p>
                      </div>
                    ))}
                </div>
                <p
                  style={{
                    textAlign: 'right',
                    paddingTop: 10,
                    fontSize: 17,
                    paddingRight: 8,
                    fontWeight: 600,
                  }}
                >
                  {`${asCurrency(
                    newBookedItems.reduce((sum, { amount }) => sum + amount, 0) / 100
                  )}€`}
                </p>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9', paddingTop: 5 }}>
            <Button
              content={i18n.t('createOrder')}
              color="teal"
              floated="right"
              size="large"
              style={{ borderRadius: 10 }}
              disabled={isSaveNewBookingDisabled || isLoadingSplitBooking}
              onClick={() => {
                if (shop && shop.isPinRequired === true && user && user.role !== 'owner') {
                  onSplitBookingPin();
                } else {
                  onSplitBooking();
                }
              }}
              loading={isLoadingSplitBooking}
            />
          </Modal.Actions>
        </AnimatedModal>
        <BookingsDiscountModal
          open={discountModalOptions.open}
          onClose={onCloseDiscountModal}
          cartWithoutToAdd={discountModalOptions.cartWithoutToAdd}
          toAdd={discountModalOptions.toAdd}
        />
        <PinNumPad
          open={pinOptions.open}
          onClose={onClosePinModal}
          onSendPin={pinOptions.onSendPin}
        />
        <DiverseProductModal
          open={diverseProductModalOptions.open}
          onClose={onCloseDiverseProductModal}
          categories={diverseProductModalOptions.categories}
        />
      </>
    );
  }
  return <></>;
};

export default BookingsCreateModal;
