/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import {
  Modal,
  Button,
  Form,
  Loader,
  Input,
  TextArea,
  Dimmer,
  Label,
  Popup,
  Dropdown,
  Accordion,
} from 'semantic-ui-react';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useStateValue } from '../../../core/context/StateProvider';
import './BookingsScreen.css';
import {
  getExistingZBon,
  getSummary,
  getUnpaidBookings,
  getZBon,
  getZBonPDF,
  zBonSaveCashBook,
} from '../../../api/BookingAPI';
import { Printer } from '../../../utils/Printer';
import { asCurrency, normalizeZero } from '../../../utils/NumberFormatter';
import { showToast } from '../../../components/general/Toast/Toast';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import AnimatedTabs from '../../../components/general/AnimatedTabs/AnimatedTabs';

const currentLang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
const days =
  currentLang === 'de'
    ? ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const months =
  currentLang === 'de'
    ? ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

registerLocale('de', {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
    time: () => 'HH:mm',
  },
});

const ZBonScreenModal = (props) => {
  const { onClose, open, date, withCoins, savedZBon, selectedShop } = props;
  const [{ token }, dispatch] = useStateValue();
  let [{ shop }] = useStateValue();
  const [zBon, setZBon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [change, setChange] = useState(0);
  const [corrections, setCorrections] = useState(0);
  const [otherExplanations, setOtherExplanations] = useState('');
  const tabs = { zbon: 'zbon-tab', cashbook: 'cashbook-tab' };
  const [selectedTab, setSelectedTab] = useState(tabs.zbon);
  const [isProtocolSaved, setIsProtocolSaved] = useState(false);
  const [checkedZBonExistence, setCheckedZBonExistence] = useState(false);
  const [actualPerformance, setActualPerformance] = useState(null);
  const [lastZBon, setLastZBon] = useState(null);
  const [coins, setCoins] = useState({
    2: 0,
    1: 0,
    0.5: 0,
    0.2: 0,
    0.1: 0,
    0.05: 0,
    0.02: 0,
    0.01: 0,
  });
  const [bankNotes, setBankNotes] = useState({
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
    5: 0,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [zBonDate, setZBonDate] = useState({ startTime: null, endTime: null, endDate: null });
  const [isCountingSelected, setIsCountingSelected] = useState(false);
  const [isInputSelected, setIsInputSelected] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [tmpExpense, setTmpExpense] = useState({ title: '', price: 0 });
  const [showedExpenseError, setShowedExpenseError] = useState(false);
  const [showedUnsavedExpenseError, setShowedUnsavedExpenseError] = useState(false);
  const inputRef = useRef(null);
  const [actPerfInpVal, setActPerfInpVal] = useState(0);
  const [zBonWarningModalOptions, setZBonWarningModalOptions] = useState({
    open: false,
    unpaidBookings: [],
  });
  const originalZBonExpenseOptions = [
    { key: 'Abgaben', text: 'Abgaben', value: 'Abgaben' },
    { key: 'Ausstattung', text: 'Ausstattung', value: 'Ausstattung' },
    { key: 'Barentnahme', text: 'Barentnahme', value: 'Barentnahme' },
    { key: 'Einkauf', text: 'Einkauf', value: 'Einkauf' },
    { key: 'Gebühr', text: 'Gebühr', value: 'Gebühr' },
    { key: 'Instandhaltung', text: 'Instandhaltung', value: 'Instandhaltung' },
    { key: 'Lizenzgebühr', text: 'Lizenzgebühr', value: 'Lizenzgebühr' },
    { key: 'Miete', text: 'Miete', value: 'Miete' },
    { key: 'Nebenkosten', text: 'Nebenkosten', value: 'Nebenkosten' },
    { key: 'Personalkosten', text: 'Personalkosten', value: 'Personalkosten' },
    { key: 'Reinigung', text: 'Reinigung', value: 'Reinigung' },
    { key: 'Reparatur', text: 'Reparatur', value: 'Reparatur' },
    { key: 'Transportkosten', text: 'Transportkosten', value: 'Transportkosten' },
    { key: 'Versicherung', text: 'Versicherung', value: 'Versicherung' },
    { key: 'Wareneinkauf', text: 'Wareneinkauf', value: 'Wareneinkauf' },
    { key: 'Werbung', text: 'Werbung', value: 'Werbung' },
  ];
  const [zBonExpenseOptions, setZBonExpenseOptions] = useState(originalZBonExpenseOptions);

  if (selectedShop) {
    shop = selectedShop;
  }

  useEffect(() => {
    if (withCoins) {
      const momentDate = moment(date).startOf('day');
      const momentEndTime = moment(zBonDate.endTime, 'HH:mm');
      const momentStartTime = moment(zBonDate.startTime, 'HH:mm');
      if (
        momentDate.isSame(moment(zBonDate.endDate).startOf('day')) &&
        momentStartTime.isAfter(momentEndTime)
      ) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('startTimeError'),
        });
        setZBonDate({
          ...zBonDate,
          startTime: '',
        });
      }
    }
  }, [zBonDate.startTime]);

  useEffect(() => {
    if (withCoins) {
      const momentDate = moment(date).startOf('day');
      const momentEndTime = moment(zBonDate.endTime, 'HH:mm');
      const momentStartTime = moment(zBonDate.startTime, 'HH:mm');
      if (
        momentDate.isSame(moment(zBonDate.endDate).startOf('day')) &&
        momentEndTime.isBefore(momentStartTime)
      ) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('endTimeError'),
        });
        setZBonDate({
          ...zBonDate,
          endTime: '',
        });
      }
    }
  }, [zBonDate.endTime]);

  useEffect(() => {
    if (open === true && checkedZBonExistence === false) {
      checkForExistingZBon();
    }
  }, [open]);

  useEffect(() => {
    if (
      (Object.values(bankNotes).every((value) => value === 0) &&
        Object.values(coins).every((value) => value === 0)) === false
    ) {
      setActualPerformance(null);
      setActPerfInpVal(0);
    }
  }, [bankNotes, coins]);

  useEffect(() => {
    if (open && savedZBon != null && savedZBon.cashBook) {
      const { cashBook } = savedZBon;
      setBankNotes(cashBook.bankNotes);
      setCoins({
        2: cashBook.coins['2'],
        1: cashBook.coins['1'],
        0.5: cashBook.coins['0']['5'],
        0.2: cashBook.coins['0']['2'],
        0.1: cashBook.coins['0']['1'],
        0.05: cashBook.coins['0']['05'],
        0.02: cashBook.coins['0']['02'],
        0.01: cashBook.coins['0']['01'],
      });
      setActualPerformance(Number((cashBook.cashActual - cashBook.corrections || 0).toFixed(2)));
      setActPerfInpVal((cashBook.cashActual - cashBook.corrections || 0).toFixed(2));
      setCorrections(cashBook.corrections);
      setOtherExplanations(cashBook.explanations);
      setIsProtocolSaved(true);
      setChange(cashBook.change);
      setExpenses(cashBook.expenses);
    }
  }, [savedZBon]);

  useEffect(() => {
    if (actualPerformance != null && savedZBon === null) {
      setBankNotes({
        200: 0,
        100: 0,
        50: 0,
        20: 0,
        10: 0,
        5: 0,
      });
      setCoins({
        2: 0,
        1: 0,
        0.5: 0,
        0.2: 0,
        0.1: 0,
        0.05: 0,
        0.02: 0,
        0.01: 0,
      });
    }
  }, [actualPerformance]);

  const downloadCashPDF = async () => {
    await getSummary(token, shop._id, moment(zBon.issueDateStart).format(), 'cash');
  };

  const downloadCardPDF = async () => {
    await getSummary(token, shop._id, moment(zBon.issueDateStart).format(), 'card');
  };

  const checkForExistingZBon = async () => {
    try {
      setCheckedZBonExistence(true);
      setShowDatePicker(false);
      if (savedZBon != null) {
        setZBon(savedZBon);
        setZBonDate({
          startTime: moment(savedZBon.issueDateStart).format('HH:mm'),
          endDate: moment(savedZBon.issueDateEnd),
          endTime: moment(savedZBon.issueDateEnd).format('HH:mm'),
        });
        return;
      }
      setIsLoading(true);
      const res = await getExistingZBon(shop._id, moment(date).format(), token);
      setIsLoading(false);
      if (res.success) {
        const startDate = res.zBon.issueDateStart;
        const endDate = res.zBon.issueDateEnd;
        setZBon(res.zBon);
        setZBonDate({
          startTime: moment(startDate).format('HH:mm'),
          endDate: moment(endDate),
          endTime: moment(endDate).format('HH:mm'),
        });
        if (shop && shop.allowCashBook === true && res.zBon.cashBook != null) {
          const { cashBook } = res.zBon;
          setCorrections(cashBook.corrections);
          setOtherExplanations(cashBook.explanations);
          const coinsTmp = {
            2: cashBook.coins['2'],
            1: cashBook.coins['1'],
            0.5: cashBook.coins['0']['5'],
            0.2: cashBook.coins['0']['2'],
            0.1: cashBook.coins['0']['1'],
            0.05: cashBook.coins['0']['05'],
            0.02: cashBook.coins['0']['02'],
            0.01: cashBook.coins['0']['01'],
          };
          setExpenses(cashBook.expenses);
          setBankNotes(cashBook.bankNotes);
          setCoins(coinsTmp);
          setActualPerformance(
            Number((cashBook.cashActual - cashBook.corrections || 0).toFixed(2))
          );
          setActPerfInpVal((cashBook.cashActual - cashBook.corrections || 0).toFixed(2));
          setIsProtocolSaved(true);
        }
        if (
          shop &&
          shop.allowCashBook === true &&
          res.lastZBonWithCashBook &&
          res.lastZBonWithCashBook.cashBook
        ) {
          const changeTmp = res.lastZBonWithCashBook.cashBook.remainingChange;
          setChange(changeTmp);
          setLastZBon(res.lastZBon);
        }
      } else {
        if (res.lastZBon != null) {
          setLastZBon(res.lastZBon);
        }
        setShowDatePicker(true);
      }
    } catch (error) {
      console.log(error);
      setShowDatePicker(true);
      setIsLoading(false);
    }
  };

  const loadData = async () => {
    setShowDatePicker(false);
    setIsLoading(true);
    try {
      const res = await getZBon(
        token,
        shop._id,
        moment(date).startOf('day').add(moment.duration(zBonDate.startTime)).format(),
        moment(zBonDate.endDate).startOf('day').add(moment.duration(zBonDate.endTime)).format(),
        false
      );
      setIsLoading(false);
      if (res && res.success) {
        setZBon(res.zBon);
        if (shop.allowCashBook === true && res.lastZBon && res.lastZBon.cashBook) {
          const changeTmp = res.lastZBon.cashBook.remainingChange;
          setChange(changeTmp);
        }
      } else {
        setErrorWarningModalOptions({ open: true, message: i18n.t('zBonError') });
        setShowDatePicker(true);
        setEndDatePickerOpen(false);
        setZBonDate({ startTime: '', endTime: '', endDate: null });
      }
    } catch (error) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('zBonError') });
      setShowDatePicker(true);
      setEndDatePickerOpen(false);
      setZBonDate({ startTime: '', endTime: '', endDate: null });
    }
    setIsLoading(false);
  };

  const closeModal = (fromRevert) => {
    if (
      shop.allowCashBook === true &&
      isProtocolSaved === false &&
      showDatePicker === false &&
      withCoins === true &&
      fromRevert !== true
    ) {
      setWarningModalOptions({
        open: true,
        title: i18n.t('bookingWarningTitle'),
        message: i18n.t('protocolError4'),
        yes: true,
        onYes: () => {
          closeModalComponents();
        },
        no: true,
        onNo: () => {
          setWarningModalOptions({
            open: false,
            title: '',
            message: '',
            ok: false,
            onOk: null,
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
            amount: null,
          });
        },
      });
    } else {
      closeModalComponents();
    }
  };

  const closeModalComponents = () => {
    setCheckedZBonExistence(false);
    setBankNotes({
      200: 0,
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0,
    });
    setCoins({
      2: 0,
      1: 0,
      0.5: 0,
      0.2: 0,
      0.1: 0,
      0.05: 0,
      0.02: 0,
      0.01: 0,
    });
    setZBon(null);
    setIsLoading(false);
    setActualPerformance(null);
    setChange(0);
    setCorrections(0);
    setOtherExplanations('');
    setIsDownloading(false);
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
    setIsProtocolSaved(false);
    setErrorWarningModalOptions({ open: false, message: '' });
    setEndDatePickerOpen(false);
    setZBonDate({ startTime: null, endTime: null, endDate: null });
    setSelectedTab(tabs.zbon);
    setIsCountingSelected(false);
    setIsInputSelected(false);
    setExpenses([]);
    setTmpExpense({ title: '', price: 0 });
    setShowDatePicker(true);
    setShowedExpenseError(false);
    setShowedUnsavedExpenseError(false);
    inputRef.current = null;
    setActPerfInpVal(0);
    setZBonWarningModalOptions({ open: false, unpaidBookings: [] });
    setLastZBon(null);
    setZBonExpenseOptions(originalZBonExpenseOptions);
    onClose();
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const parseZBon = () => (
    <>
      <div className="zBon-container">
        <div
          style={{
            borderRadius: 14,
            width: '100%',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: 10,
            marginBottom: 20,
            marginTop: 4,
          }}
        >
          <p style={{ fontSize: 42, fontWeight: 700, marginBottom: 4 }}>{zBon.number}</p>
          <p style={{ fontWeight: 500 }}>{i18n.t('zBonNr')}</p>
        </div>
        <div
          style={{
            borderRadius: 14,
            width: '100%',
            backgroundColor: 'white',
            padding: 10,
            marginBottom: 20,
          }}
          id="general"
        >
          <Accordion
            defaultActiveIndex={0}
            panels={[
              {
                key: 'general-panel',
                title: {
                  content: (
                    <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                      {i18n.t('general')}
                    </p>
                  ),
                },
                content: {
                  content: (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          justifyContent: 'space-around',
                          textAlign: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <p style={{ fontWeight: 500, marginBottom: 6 }}>
                            {moment(zBon.issueDateStart).format(`HH:mm [${i18n.t('uhrGerman')}]`)}
                          </p>
                          <p style={{ fontWeight: 600, marginBottom: 8 }}>
                            {moment(zBon.issueDateStart).format(`DD.MM.YYYY`)}
                          </p>
                          <p style={{ fontWeight: 700 }}>{i18n.t('reportStart')}</p>
                        </div>
                        <div style={{ width: 1, borderLeft: '1px solid #7268cd', height: 50 }} />
                        <div>
                          <p style={{ fontWeight: 500, marginBottom: 6 }}>
                            {moment(zBon.issueDateEnd).format(`HH:mm [${i18n.t('uhrGerman')}]`)}
                          </p>
                          <p style={{ fontWeight: 600, marginBottom: 8 }}>
                            {moment(zBon.issueDateEnd).format(`DD.MM.YYYY`)}
                          </p>
                          <p style={{ fontWeight: 700 }}>{i18n.t('reportEnd')}</p>
                        </div>
                        {zBon.lastBookingDate != null ? (
                          <>
                            <div
                              style={{ width: 1, borderLeft: '1px solid #7268cd', height: 50 }}
                            />
                            <div>
                              <p style={{ fontWeight: 600, marginBottom: 6 }}>
                                {moment(zBon.lastBookingDate).format(
                                  `HH:mm [${i18n.t('uhrGerman')}]`
                                )}
                              </p>
                              <p style={{ fontWeight: 600, marginBottom: 8 }}>
                                {moment(zBon.lastBookingDate).format(`DD.MM.YYYY`)}
                              </p>
                              <p style={{ fontWeight: 500 }}>{i18n.t('lastBookingDate')}</p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          marginTop: 20,
                          fontSize: 12,
                        }}
                      >
                        <p>
                          {i18n.t('createdAt')}:{' '}
                          {moment(zBon.created).format(`DD.MM.YYYY HH:mm [${i18n.t('uhrGerman')}]`)}
                        </p>
                      </div>
                    </>
                  ),
                },
              },
            ]}
          />
        </div>
        <div
          style={{
            borderRadius: 14,
            width: '100%',
            backgroundColor: 'white',
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Accordion
            panels={[
              {
                key: 'revenue-panel',
                title: {
                  content: (
                    <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                      {i18n.t('revenue')}
                    </p>
                  ),
                },
                content: {
                  content: (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('revenueExclTax')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.totalWithoutTaxes / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('7Tax')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.reducedTaxesTotal / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('19Tax')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.normalTaxesTotal / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('taxTotal')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.taxes / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('revenue7Tax')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.reducedTaxesTotalRevenue / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('revenue19Tax')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.normalTaxesTotalRevenue / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('revenueInclVAT')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.total / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('cashPayments')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.cash / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500 }}>{i18n.t('cardPayments')}</p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.card / 100)}€`}</p>
                      </div>
                    </>
                  ),
                },
              },
            ]}
          />
        </div>
        {shop && shop.tipsAllowed === true ? (
          <div
            style={{
              borderRadius: 14,
              width: '100%',
              backgroundColor: 'white',
              padding: 10,
              marginBottom: 20,
            }}
          >
            <Accordion
              panels={[
                {
                  key: 'tips-panel',
                  title: {
                    content: (
                      <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                        {i18n.t('tips2')}
                      </p>
                    ),
                  },
                  content: {
                    content: (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>{i18n.t('tipsTotal')}</p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.totalTipAmount / 100)}€`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>{i18n.t('tipsCash')}</p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.totalTipCash / 100)}€`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>{i18n.t('tipsCard')}</p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.totalTipCard / 100)}€`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>
                            {i18n.t('tipsEmployer')}
                          </p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.ownerTipsAmount / 100)}€`}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>
                            {i18n.t('tipsEmployee')}
                          </p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.employeeTipsAmount / 100)}€`}</p>
                        </div>
                      </>
                    ),
                  },
                },
              ]}
            />
          </div>
        ) : (
          <></>
        )}
        {shop && shop.allowDiscounts === true ? (
          <div
            style={{
              borderRadius: 14,
              width: '100%',
              backgroundColor: 'white',
              padding: 10,
              marginBottom: 20,
            }}
          >
            <Accordion
              panels={[
                {
                  key: 'discounts-panel',
                  title: {
                    content: (
                      <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                        {i18n.t('discount')}
                      </p>
                    ),
                  },
                  content: {
                    content: (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>
                            {i18n.t('discountCount')}
                          </p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{zBon.discountsCount}</p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            gap: 8,
                          }}
                        >
                          <p style={{ fontWeight: 500, marginBottom: 0 }}>
                            {i18n.t('discountSum')}
                          </p>
                          <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                          <p>{`${asCurrency(zBon.totalDiscountsAmount / 100)}€`}</p>
                        </div>
                      </>
                    ),
                  },
                },
              ]}
            />
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            borderRadius: 14,
            width: '100%',
            backgroundColor: 'white',
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Accordion
            panels={[
              {
                key: 'refunds-panel',
                title: {
                  content: (
                    <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                      {i18n.t('refund1')}
                    </p>
                  ),
                },
                content: {
                  content: (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('partlyRefundCount1')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{zBon.partlyRefundNumber}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('partlyRefundSum1')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.partlyRefund / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('fullRefundCount1')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{zBon.refundNumber}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('fullRefundSum1')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency(zBon.refund / 100)}€`}</p>
                      </div>
                    </>
                  ),
                },
              },
            ]}
          />
        </div>
        <div
          style={{
            borderRadius: 14,
            width: '100%',
            backgroundColor: 'white',
            padding: 10,
            marginBottom: 20,
          }}
        >
          <Accordion
            panels={[
              {
                key: 'cancels-panel',
                title: {
                  content: (
                    <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                      {i18n.t('cancel1')}
                    </p>
                  ),
                },
                content: {
                  content: (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('partlyCancelCount')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{zBon.partlyCancelNumber || 0}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('partlyCancelSum')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency((zBon.partlyCancel || 0) / 100)}€`}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('fullCancelCount')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{zBon.cancelNumber || 0}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          gap: 8,
                        }}
                      >
                        <p style={{ fontWeight: 500, marginBottom: 0 }}>
                          {i18n.t('fullCancelSum')}
                        </p>
                        <p style={{ flex: 1, borderBottom: '1px dotted #aaaaaa7d' }} />
                        <p>{`${asCurrency((zBon.cancel || 0) / 100)}€`}</p>
                      </div>
                    </>
                  ),
                },
              },
            ]}
          />
        </div>
        {zBon.transferredBookings && zBon.transferredBookings.length > 0 ? (
          <div
            style={{
              borderRadius: 14,
              width: '100%',
              backgroundColor: 'white',
              padding: 10,
              marginBottom: 20,
            }}
          >
            <Accordion
              panels={[
                {
                  key: 'transfers-panel',
                  title: {
                    content: (
                      <p style={{ display: 'inline', fontWeight: 700, fontSize: 16 }}>
                        {i18n.t('transferredBookings')}
                        <Popup
                          on={['hover', 'click']}
                          // eslint-disable-next-line prettier/prettier
                          trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                          content={i18n.t('transferredBookingsInfo')}
                          popper={<div style={{ filter: 'none' }} />}
                        />
                      </p>
                    ),
                  },
                  content: {
                    content: (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <p style={{ fontWeight: 600, width: '100%' }}>
                            {i18n.t('bookingModalTitle')}
                          </p>
                          <p style={{ fontWeight: 600, width: '100%' }}>{i18n.t('amount')}</p>
                          <p style={{ fontWeight: 600, width: '100%' }}>{i18n.t('bookingsDate')}</p>
                        </div>
                        {zBon.transferredBookings.map((trans) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ width: '100%' }}>{trans.bookingId}</p>
                            <p style={{ width: '100%' }}>
                              {`${asCurrency((trans.amount || 0) / 100)}€`}
                            </p>
                            <p style={{ width: '100%' }}>
                              {moment(trans.created).format(
                                `DD.MM.YYYY, HH:mm[${i18n.t('uhrGerman')}]`
                              )}
                            </p>
                          </div>
                        ))}
                      </>
                    ),
                  },
                },
              ]}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  const isUsingCounted = () =>
    Object.values(bankNotes).every((value) => value === 0) &&
    Object.values(coins).every((value) => value === 0) &&
    actualPerformance !== null &&
    actualPerformance > 0;

  const isUsingBanknotesAndCoins = () =>
    Object.values(bankNotes).some((value) => value !== 0) ||
    Object.values(coins).some((value) => value !== 0);

  const parseProtocol = () => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 14,
          width: '100%',
          marginTop: 4,
          marginBottom: 20,
          padding: 12,
        }}
      >
        <p id="cashInventorySection" style={{ fontWeight: 700, fontSize: 16 }}>
          {i18n.t('cashInventory')}
        </p>
        <p style={{ fontWeight: 400, fontSize: 12 }}>{i18n.t('cashInventoryDescription')}</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
            gap: 10,
          }}
        >
          <div
            style={{
              border: isUsingCounted() ? '2px solid #aaa' : '2px solid #7268cd',
              backgroundColor: isUsingBanknotesAndCoins() === true ? '#7268cd' : 'white',
              color:
                isUsingBanknotesAndCoins() === true ? 'white' : isUsingCounted() ? '#aaa' : 'black',
              borderRadius: 10,
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 14,
              fontWeight: 600,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (isUsingCounted() !== true) {
                setIsInputSelected(false);
                setIsCountingSelected(!isCountingSelected);
              }
            }}
          >
            <div style={{ display: 'flex', gap: 4 }}>
              <span style={{ width: 10 }}>{`${isCountingSelected === true ? '▾' : '▸'}`}</span>
              {i18n.t('cashInventoryCount')}
            </div>
          </div>
          <div
            style={{
              border: isUsingBanknotesAndCoins() ? '2px solid #aaa' : '2px solid #7268cd',
              borderRadius: 10,
              backgroundColor: actualPerformance != null ? '#7268cd' : 'white',
              color:
                actualPerformance != null ? 'white' : isUsingBanknotesAndCoins() ? '#aaa' : 'black',
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 14,
              fontWeight: 600,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (isUsingBanknotesAndCoins() !== true) {
                setIsInputSelected(!isInputSelected);
                setIsCountingSelected(false);
              }
            }}
          >
            <div style={{ display: 'flex', gap: 4 }}>
              <span style={{ width: 10 }}>{`${isInputSelected === true ? '▾' : '▸'}`}</span>
              {i18n.t('cashInventoryInput')}
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <div
              style={{
                border:
                  (isUsingBanknotesAndCoins() || isUsingCounted()) && isProtocolSaved === false
                    ? '2px solid red'
                    : '2px solid #ccc',
                borderRadius: 8,
                backgroundColor:
                  (isUsingBanknotesAndCoins() || isUsingCounted()) && isProtocolSaved === false
                    ? 'white'
                    : '#eee',
                color:
                  (isUsingBanknotesAndCoins() || isUsingCounted()) && isProtocolSaved === false
                    ? 'red'
                    : '#999',
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 7,
                paddingRight: 7,
                fontSize: 12,
                fontWeight: 800,
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isProtocolSaved === false) {
                  setBankNotes({
                    200: 0,
                    100: 0,
                    50: 0,
                    20: 0,
                    10: 0,
                    5: 0,
                  });
                  setCoins({
                    2: 0,
                    1: 0,
                    0.5: 0,
                    0.2: 0,
                    0.1: 0,
                    0.05: 0,
                    0.02: 0,
                    0.01: 0,
                  });
                  setActualPerformance(null);
                  setActPerfInpVal(0);
                  setIsCountingSelected(false);
                  setIsInputSelected(false);
                }
              }}
            >
              X
            </div>
          </div>
        </div>
        {isCountingSelected === true ? (
          <div style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontWeight: 600, fontSize: 15 }}>{i18n.t('banknotes')}</p>
              <div
                style={{
                  color: isProtocolSaved === false ? '#7268cd' : '#aaa',
                  fontWeight: 700,
                  fontSize: 12,
                  paddingTop: 2,
                  cursor: 'pointer',
                }}
                // eslint-disable-next-line no-return-assign
                onClick={() => {
                  if (isProtocolSaved === false) {
                    setBankNotes({
                      200: 0,
                      100: 0,
                      50: 0,
                      20: 0,
                      10: 0,
                      5: 0,
                    });
                  }
                }}
              >
                {i18n.t('itemsDelete')}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '89%' }}>
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    200€
                  </Label>
                }
                min={0}
                placeholder="0"
                type="number"
                value={bankNotes['200'] ? bankNotes['200'] : ''}
                style={{ width: 70 }}
                readOnly={isProtocolSaved === true}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(200 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 200: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 200: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    100€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={bankNotes['100'] > 0 ? bankNotes['100'] : ''}
                style={{ width: 70 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(100 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 100: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 100: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    50€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={bankNotes['50'] ? bankNotes['50'] : ''}
                style={{ width: 70 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(50 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 50: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 50: 0 });
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '89%',
                marginTop: 20,
              }}
            >
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    20€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={bankNotes['20'] ? bankNotes['20'] : ''}
                style={{ width: 70 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(20 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 20: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 20: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    10€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={bankNotes['10'] ? bankNotes['10'] : ''}
                style={{ width: 70 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(10 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 10: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 10: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 56,
                      textAlign: 'center',
                    }}
                  >
                    5€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={bankNotes['5'] ? bankNotes['5'] : ''}
                style={{ width: 70 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(5 * parseInt(e.target.value))
                  )
                    setBankNotes({ ...bankNotes, 5: parseInt(e.target.value) });
                  if (!e.target.value) setBankNotes({ ...bankNotes, 5: 0 });
                }}
              />
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontWeight: 600, fontSize: 15 }}>{i18n.t('coins')}</p>
              <div
                style={{
                  color: isProtocolSaved === false ? '#7268cd' : '#aaa',
                  fontWeight: 700,
                  fontSize: 12,
                  paddingTop: 2,
                  cursor: 'pointer',
                }}
                // eslint-disable-next-line no-return-assign
                onClick={() => {
                  if (isProtocolSaved === false) {
                    setCoins({
                      2: 0,
                      1: 0,
                      0.5: 0,
                      0.2: 0,
                      0.1: 0,
                      0.05: 0,
                      0.02: 0,
                      0.01: 0,
                    });
                  }
                }}
              >
                {i18n.t('itemsDelete')}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '92%',
              }}
            >
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                    }}
                  >
                    2€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['2'] ? coins['2'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(2 * parseInt(e.target.value))
                  )
                    setCoins({ ...coins, 2: parseInt(e.target.value) });
                  if (!e.target.value) setCoins({ ...coins, 2: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                    }}
                  >
                    1€
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                style={{ width: 60 }}
                readOnly={isProtocolSaved === true}
                value={coins['1'] ? coins['1'] : ''}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(parseInt(e.target.value))
                  )
                    setCoins({ ...coins, 1: parseInt(e.target.value) });
                  if (!e.target.value) setCoins({ ...coins, 1: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 3,
                    }}
                  >
                    50Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                style={{ width: 60 }}
                readOnly={isProtocolSaved === true}
                value={coins['0.5'] ? coins['0.5'] : ''}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.5 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.5: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.5: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 3,
                    }}
                  >
                    20Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['0.2'] ? coins['0.2'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.2 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.2: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.2: 0 });
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '92%',
                marginTop: 20,
              }}
            >
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 4,
                    }}
                  >
                    10Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['0.1'] ? coins['0.1'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.1 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.1: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.1: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 7,
                    }}
                  >
                    5Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['0.05'] ? coins['0.05'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.05 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.05: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.05: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 8,
                    }}
                  >
                    2Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['0.02'] ? coins['0.02'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.02 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.02: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.02: 0 });
                }}
              />
              <Input
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Label
                    style={{
                      color: 'white',
                      backgroundColor: '#7268cd',
                      width: 40,
                      textAlign: 'center',
                      paddingLeft: 8,
                    }}
                  >
                    1Ct
                  </Label>
                }
                placeholder="0"
                type="number"
                min={0}
                readOnly={isProtocolSaved === true}
                value={coins['0.01'] ? coins['0.01'] : ''}
                style={{ width: 60 }}
                onChange={(e) => {
                  if (
                    e.target.value < 99999 &&
                    e.target.value > 0 &&
                    !Number.isNaN(0.01 * parseInt(e.target.value))
                  )
                    setCoins({
                      ...coins,
                      0.01: parseInt(e.target.value),
                    });
                  if (!e.target.value) setCoins({ ...coins, 0.01: 0 });
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {isInputSelected === true ? (
          <div style={{ marginTop: 20 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}
              >
                <Form>
                  <div style={{ display: 'flex', gap: 0, alignItems: 'center' }}>
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        paddingLeft: 7,
                        paddingRight: 7,
                        margin: 0,
                        height: 38,
                        borderRadius: 4,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: '#7268cd',
                        border: '1px solid #7268cd',
                        display: 'inline-flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: 'white',
                      }}
                    >
                      {i18n.t('cashBestand')}
                    </p>
                    <CurrencyInput
                      placeholder="0"
                      suffix=""
                      style={{
                        width: 78,
                        height: 38,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderLeft: 'none',
                      }}
                      readOnly={isProtocolSaved === true}
                      disableGroupSeparators
                      allowNegativeValue={false}
                      intlConfig={{ locale: 'de-DE' }}
                      value={actPerfInpVal || ''}
                      onValueChange={(value) => {
                        if (value && parseFloat(value) < 999999 && parseFloat(value) > 0) {
                          const val = parseFloat(value.replace(',', '.'));
                          setActPerfInpVal(value);
                          setActualPerformance(val);
                        }
                        if (!value) {
                          setActPerfInpVal('');
                          setActualPerformance(null);
                        }
                      }}
                    />
                  </div>
                </Form>
                <p style={{ marginLeft: 3, fontWeight: 500, fontSize: 15 }}>€</p>
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  color:
                    (cashTargetIncome() > 0 || isUsingCounted()) && isProtocolSaved === false
                      ? '#7268cd'
                      : '#aaa',
                  fontWeight: 700,
                  fontSize: 12,
                  textAlign: 'right',
                }}
                onClick={() => {
                  if (isProtocolSaved === false) {
                    if (isUsingCounted()) {
                      setActPerfInpVal(0);
                      setActualPerformance(null);
                    } else if (cashTargetIncome() > 0) {
                      setActPerfInpVal(cashTargetIncome() / 100);
                      setActualPerformance(cashTargetIncome() / 100);
                    }
                  }
                }}
              >
                {isUsingCounted() ? i18n.t('itemsDelete') : i18n.t('applyActual')}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  const cashTargetIncome = () => {
    if (zBon.ownerTotalTipsCash && zBon.ownerTotalTipsCash > 0) {
      return zBon.cash + zBon.ownerTotalTipsCash;
    }
    return zBon.cash;
  };

  const calculateBankNotes = () =>
    Object.keys(bankNotes).reduce(
      (sum, bankNote) => sum + bankNotes[bankNote] * parseFloat(bankNote),
      0
    );

  const calculateCoins = () =>
    Object.keys(coins).reduce((sum, coin) => sum + coins[coin] * parseFloat(coin), 0);

  const parseCalculation = () => (
    <>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 14,
          width: '100%',
          marginTop: 10,
          marginBottom: 20,
          padding: 12,
        }}
      >
        <p style={{ fontWeight: 700, fontSize: 16 }}>{i18n.t('kassenabstimmung')}</p>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40 }} />
          <p
            style={{
              fontWeight: 500,
              width: 250,
              color: 'black',
            }}
          >
            {i18n.t('cashInventory')}
          </p>
          <p
            style={{
              fontWeight: 500,
              width: 128,
              textAlign: 'right',
              color: 'black',
            }}
          >
            {isUsingCounted()
              ? asCurrency(actualPerformance || 0)
              : asCurrency(calculateCoins() + calculateBankNotes())}
            €
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              width: 40,
              fontWeight: 600,
              fontSize: 16,
              textAlign: 'center',
              margin: 0,
              color: isProtocolSaved === true && corrections === 0 ? '#aaa' : 'black',
            }}
          >
            +/-
          </p>
          <p style={{ fontWeight: 500, width: 250, margin: 0 }}>{i18n.t('otherCorrections')}</p>
          {withCoins === true && isProtocolSaved === false ? (
            <div
              style={{
                width: 128,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Form>
                <CurrencyInput
                  placeholder="0"
                  suffix=""
                  style={{
                    width: 80,
                    height: 30,
                  }}
                  disableGroupSeparators
                  intlConfig={{ locale: 'de-DE' }}
                  onValueChange={(value) => {
                    if (value && parseFloat(value) < 999999) {
                      const val = parseFloat(value.replace(',', '.'));
                      setCorrections(val);
                    }
                    if (!value) {
                      setCorrections(0);
                      setOtherExplanations('');
                    }
                  }}
                />
              </Form>
              <p style={{ margin: 0, fontWeight: 500 }}>€</p>
            </div>
          ) : (
            <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
              {asCurrency(corrections)}€
            </p>
          )}
        </div>
        {withCoins === true && corrections !== 0 && isProtocolSaved === false ? (
          <TextArea
            placeholder={`${
              withCoins === false
                ? i18n.t('otherExplanations')
                : i18n.t('otherExplanationsTextField')
            }`}
            className="items-modal-textarea"
            defaultValue={otherExplanations || ''}
            readOnly={withCoins === false}
            style={{
              cursor: withCoins === false ? 'default' : 'text',
              border: withCoins === false ? '1px solid #7268cd' : '',
              resize: 'none',
              height: 40,
              marginBottom: 20,
            }}
            onChange={(e) => {
              setOtherExplanations(e.target.value);
            }}
          />
        ) : (
          <></>
        )}
        {(withCoins === false && corrections !== 0) ||
        (isProtocolSaved === true && corrections !== 0) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <p style={{ width: 40, fontWeight: 600, fontSize: 16 }} />
            <p style={{ fontWeight: 500, width: 297, margin: 0 }}>{otherExplanations}</p>
            <p style={{ fontWeight: 500, width: 80, margin: 0 }} />
          </div>
        ) : (
          <></>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p
            style={{
              width: 40,
              fontWeight: 600,
              fontSize: 16,
              textAlign: 'center',
              color:
                isUsingCounted() && isProtocolSaved === true && corrections === 0
                  ? '#aaa'
                  : 'black',
            }}
          >
            =
          </p>
          <p style={{ fontWeight: 500, width: 250 }}>{i18n.t('cashierActualPerf')}</p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {isUsingCounted()
              ? asCurrency((actualPerformance || 0) + corrections)
              : asCurrency(calculateCoins() + calculateBankNotes() + corrections)}
            €
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>-</p>
          <p style={{ fontWeight: 500, width: 250 }}>
            {i18n.t('change3')}{' '}
            <Popup
              on={['hover', 'click']}
              // eslint-disable-next-line prettier/prettier
              trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
              content={i18n.t('protocolChange1')}
              popper={<div style={{ filter: 'none' }} />}
            />
          </p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>{asCurrency(change)}€</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>=</p>
          <p style={{ fontWeight: 500, width: 250 }}>{i18n.t('cashierActualIncome')}</p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {isUsingCounted()
              ? asCurrency((actualPerformance || 0) + corrections - change)
              : asCurrency(calculateCoins() + calculateBankNotes() + corrections - change)}
            €
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>-</p>
          {zBon.ownerTotalTipsCash && zBon.ownerTotalTipsCash > 0 ? (
            <>
              <p style={{ fontWeight: 500, width: 250 }}>
                {i18n.t('cashierTargetIncome')}{' '}
                <Popup
                  on={['hover', 'click']}
                  // eslint-disable-next-line prettier/prettier
                  trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
                  content={
                    i18n.t('cashierTargetIncomeDescription') +
                    (zBon.ownerTotalTipsCash && zBon.ownerTotalTipsCash > 0
                      ? `${asCurrency(zBon.ownerTotalTipsCash / 100)}€`
                      : '0,00€')
                  }
                  popper={<div style={{ filter: 'none' }} />}
                />
              </p>
            </>
          ) : (
            <>
              <p style={{ fontWeight: 500, width: 250 }}>{i18n.t('cashierTargetIncome')} </p>
            </>
          )}
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {`${asCurrency(cashTargetIncome() / 100)}€`}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>=</p>
          <p style={{ fontWeight: 500, width: 250 }}>{i18n.t('cashierDifference')}</p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {isUsingCounted()
              ? asCurrency(
                  normalizeZero(
                    Number(
                      (
                        (actualPerformance || 0) +
                        corrections -
                        change -
                        cashTargetIncome() / 100
                      ).toFixed(2)
                    )
                  )
                )
              : asCurrency(
                  normalizeZero(
                    Number(
                      (
                        calculateCoins() +
                        calculateBankNotes() +
                        corrections -
                        change -
                        cashTargetIncome() / 100
                      ).toFixed(2)
                    )
                  )
                )}
            €
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 14,
          width: '100%',
          marginTop: 10,
          marginBottom: 20,
          padding: 12,
        }}
      >
        <p id="usageArea" style={{ fontWeight: 700, fontSize: 16 }}>
          {i18n.t('usageArea')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <p style={{ width: 40 }} />
          <p style={{ fontWeight: 500, width: 250 }}>{i18n.t('cashierActualPerf')}</p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {isUsingCounted()
              ? asCurrency((actualPerformance || 0) + corrections)
              : asCurrency(calculateCoins() + calculateBankNotes() + corrections)}
            €
          </p>
        </div>
        {expenses.map((expense, index) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>-</p>
              <div style={{ fontWeight: 500, width: 250, display: 'flex', gap: 4 }}>
                <Button
                  basic
                  size="mini"
                  style={{
                    width: 20,
                    minWidth: 20,
                    padding: 0,
                    height: 20,
                    cursor: 'pointer',
                    display: withCoins === true && isProtocolSaved === false ? '' : 'none',
                  }}
                  icon="delete"
                  color="red"
                  onClick={() => {
                    expenses.splice(index, 1);
                    setExpenses([...expenses]);
                  }}
                />
                <p
                  style={{
                    overflow: 'hidden',
                    wordWrap: 'normal',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {expense.title}
                </p>
              </div>
              <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
                {asCurrency(expense.price)}€
              </p>
            </div>
          </>
        ))}
        {withCoins === true && isProtocolSaved === false ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              basic
              size="tiny"
              style={{
                width: 40,
                padding: 0,
                height: 30,
                cursor: 'pointer',
              }}
              icon="add"
              color="green"
              disabled={tmpExpense.title.length === 0 || tmpExpense.price === 0}
              onClick={() => {
                const totalExpenseAmount =
                  expenses.reduce((sum, { price }) => sum + price, 0) + tmpExpense.price;
                const totalPossibleAmount = isUsingCounted()
                  ? (actualPerformance || 0) + corrections
                  : calculateCoins() + calculateBankNotes() + corrections;

                const remainingAmount =
                  parseFloat(totalPossibleAmount) - parseFloat(totalExpenseAmount);
                if (remainingAmount < 0) {
                  setErrorWarningModalOptions({
                    open: true,
                    message: i18n.t('expenseError1'),
                  });
                  return;
                }
                setExpenses([...expenses, tmpExpense]);
                setTmpExpense({ title: '', price: 0 });
                if (inputRef && inputRef.current != null) {
                  inputRef.current.value = 0;
                }
              }}
            />
            <Dropdown
              allowAdditions
              search
              fluid
              selection
              className="expenses-addition-field"
              placeholder={i18n.t('newExpense')}
              style={{
                fontWeight: 500,
                width: 250,
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              additionLabel={<i>{i18n.t('create')}: </i>}
              options={zBonExpenseOptions}
              value={tmpExpense.title}
              onAddItem={(e, { value }) => {
                setZBonExpenseOptions([
                  { key: `${value}n`, text: value, value },
                  ...zBonExpenseOptions,
                ]);
              }}
              onChange={(e, { value }) => {
                setTmpExpense({ ...tmpExpense, title: value });
              }}
            />
            <div style={{ width: 128, display: 'flex', justifyContent: 'flex-end' }}>
              <Form>
                <CurrencyInput
                  onKeyDown={(e) => {
                    if (
                      e.key === ',' &&
                      inputRef &&
                      inputRef.current &&
                      inputRef.current.value.includes(',')
                    ) {
                      e.preventDefault();
                    } else if (e.key === '.') {
                      e.preventDefault();
                    } else if (
                      !((e.key >= '0' && e.key <= '9') || e.key === ',' || e.key === '.') &&
                      e.key !== 'Backspace' &&
                      e.key !== 'ArrowLeft' &&
                      e.key !== 'ArrowRight' &&
                      e.key !== 'Delete' &&
                      e.key !== 'Tab'
                    ) {
                      e.preventDefault();
                    }
                  }}
                  className="items-specialSelections-price"
                  placeholder="0"
                  disableGroupSeparators
                  suffix=""
                  allowNegativeValue={false}
                  intlConfig={{ locale: 'de-DE' }}
                  style={{
                    width: 80,
                    height: 36,
                  }}
                  ref={inputRef}
                  value={inputRef && inputRef.current ? inputRef.current.value : ''}
                  onValueChange={(value) => {
                    if (typeof value === 'string') {
                      const sanitizedValue = value.replace(/,(?=.*,)/g, '');

                      if (parseFloat(sanitizedValue.replace(',', '.')) < 999999) {
                        const val = parseFloat(sanitizedValue.replace(',', '.'));
                        setTmpExpense({ ...tmpExpense, price: val });
                      }
                    } else {
                      setTmpExpense({ ...tmpExpense, price: 0 });
                    }
                  }}
                />
              </Form>
              <p style={{ marginLeft: 0, fontWeight: 500, paddingTop: 5 }}>€</p>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: withCoins === true && isProtocolSaved === false ? 20 : 0,
            marginBottom: 20,
          }}
        >
          <p style={{ width: 40, fontWeight: 600, fontSize: 16, textAlign: 'center' }}>=</p>
          <p style={{ fontWeight: 500, width: 250 }}>
            {i18n.t('remainingChange')}
            <Popup
              on={['hover', 'click']}
              // eslint-disable-next-line prettier/prettier
              trigger={<p style={{marginLeft: 5, width: 20, height: 20, paddingTop: 0, fontFamily: 'serif', border: '1px solid #7268cd', display: 'inline-block', borderRadius: 10, textAlign: 'center', fontSize: 13}}>i</p>}
              content={i18n.t('protocolChange2')}
              popper={<div style={{ filter: 'none' }} />}
            />
          </p>
          <p style={{ fontWeight: 500, width: 128, textAlign: 'right' }}>
            {isUsingCounted()
              ? asCurrency(
                  (actualPerformance || 0) +
                    corrections -
                    expenses.reduce((sum, { price }) => sum + price, 0)
                )
              : asCurrency(
                  calculateCoins() +
                    calculateBankNotes() +
                    corrections -
                    expenses.reduce((sum, { price }) => sum + price, 0)
                )}
            €
          </p>
        </div>
      </div>
    </>
  );

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printErrorZBon${printerDevice.printer.description}`,
    });
  };

  const downloadZBon = async () => {
    try {
      setIsDownloading(true);
      await getZBonPDF(
        token,
        shop._id,
        moment(savedZBon != null ? savedZBon.issueDateStart : date)
          .startOf('day')
          .add(moment.duration(zBonDate.startTime))
          .format(),
        moment(zBonDate.endDate).startOf('day').add(moment.duration(zBonDate.endTime)).format()
      );
      setIsDownloading(false);
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('zBonDownloadError'),
      });
      setIsDownloading(false);
    }
  };

  const printZBon = (withProtocol, withCounted) => {
    if (shop.printers && shop.printers.length > 0) {
      const printableZBon = {
        ...zBon,
        coins,
        bankNotes,
        change,
        corrections,
        expenses,
        otherExplanations,
      };
      if (shop.printers.find((printer) => printer.isMain === true)) {
        const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
        printerDevice.epos.onerror = () => showPrinterError(printerDevice);
        const paperSize =
          shop.printers.find((printer) => printer.isMain === true).paperSize === 80 ? 42 : 30;
        const cashActual = isUsingCounted()
          ? (actualPerformance || 0) + corrections
          : calculateCoins() + calculateBankNotes() + corrections;
        const diff = isUsingCounted()
          ? asCurrency(
              normalizeZero(
                Number(
                  (
                    (actualPerformance || 0) +
                    corrections -
                    change -
                    cashTargetIncome() / 100
                  ).toFixed(2)
                )
              )
            )
          : asCurrency(
              normalizeZero(
                Number(
                  (
                    calculateCoins() +
                    calculateBankNotes() +
                    corrections -
                    change -
                    cashTargetIncome() / 100
                  ).toFixed(2)
                )
              )
            );
        printerDevice.printZBon(
          printableZBon,
          paperSize,
          withProtocol,
          shop.tipsAllowed === true,
          shop.allowDiscounts === true,
          withCounted,
          cashActual,
          shop.address,
          shop.phoneNumber,
          shop.taxId,
          actualPerformance,
          diff
        );
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('printerNoKundenbonError1'),
        });
      }
    }
  };

  const openCashDrawer = () => {
    if (
      shop.printers &&
      shop.printers.length > 0 &&
      shop.printers.find((printer) => printer.isMain === true)
    ) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => console.error('Error opening cash drawer.');
      printerDevice.openDrawer();
    }
  };

  const onSaveProtocol = async () => {
    if (isUsingCounted() === false && isUsingBanknotesAndCoins() === false) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError1') });
      if (document.getElementById('cashInventorySection')) {
        document
          .getElementById('cashInventorySection')
          .scrollIntoView({ behavior: 'smooth', top: 0 });
      }
      return;
    }
    if (expenses.length === 0 && showedExpenseError === false) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('protocolError3'),
        title: i18n.t('warning'),
      });
      if (document.getElementById('usageArea')) {
        document.getElementById('usageArea').scrollIntoView({ behavior: 'smooth', top: 0 });
      }
      setShowedExpenseError(true);
      return;
    }
    if (
      (tmpExpense.title !== '' || tmpExpense.price !== 0) &&
      showedUnsavedExpenseError === false
    ) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError10') });
      if (document.getElementById('usageArea')) {
        document.getElementById('usageArea').scrollIntoView({ behavior: 'smooth', top: 0 });
      }
      setShowedUnsavedExpenseError(true);
      return;
    }
    if (
      change >
      (isUsingCounted()
        ? (actualPerformance || 0) + corrections
        : calculateCoins() + calculateBankNotes() + corrections)
    ) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError5') });
      return;
    }
    if (corrections > 0 && otherExplanations.length === 0) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError6') });
      return;
    }
    const cashNow = isUsingCounted()
      ? (actualPerformance || 0) + corrections - change
      : calculateCoins() + calculateBankNotes() + corrections - change;
    if (cashNow < 0) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError7') });
      return;
    }
    const totalExpenseAmount = expenses.reduce((sum, { price }) => sum + price, 0);
    const cashInventory = isUsingCounted()
      ? (actualPerformance || 0) + corrections
      : calculateCoins() + calculateBankNotes() + corrections;
    if (cashInventory - totalExpenseAmount < 0) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError9') });
      return;
    }
    setWarningModalOptions({
      open: true,
      title: i18n.t('warning'),
      message: `${i18n.t('cashBookSaveWarning')}`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        try {
          const coinsTotal = calculateCoins();
          const bankNotesTotal = calculateBankNotes();
          const cashActual = isUsingCounted()
            ? (actualPerformance || 0) + corrections
            : calculateCoins() + calculateBankNotes() + corrections;
          const cashBook = {
            bankNotes,
            coins,
            coinsTotal,
            bankNotesTotal,
            cashActual,
            change,
            corrections,
            actualRevenue: cashActual - change,
            targetRevenue: cashTargetIncome(),
            cashDifference: normalizeZero((cashActual - change) * 100 - cashTargetIncome()),
            explanations: otherExplanations,
            expenses,
            remainingChange: normalizeZero(
              cashActual - expenses.reduce((sum, { price }) => sum + price, 0)
            ),
          };
          const res = await zBonSaveCashBook(token, shop._id, moment(date).format(), cashBook);
          if (res && res.success) {
            setIsProtocolSaved(true);
            closeWarningModal();
          } else {
            closeWarningModal();
            setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError2') });
          }
        } catch (error) {
          console.error(error);
          closeWarningModal();
          setErrorWarningModalOptions({ open: true, message: i18n.t('protocolError2') });
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  return (
    <>
      <AnimatedModal
        open={open}
        dimmer="blurring"
        size={showDatePicker ? 'mini' : 'tiny'}
        style={{ backgroundColor: '#f5f5f9' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            backgroundColor: '#f5f5f9',
            paddingBottom: 0,
            fontFamily: 'Montserrat',
          }}
        >
          {shop && shop.allowCashBook === true
            ? `${i18n.t('dailySummaryZbon')} & ${i18n.t('dailySummaryCashBook')}`
            : `${i18n.t('dailySummaryZbon')}`}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal()}
            floated="right"
            circular
            className="settings-close"
          />
        </Modal.Header>
        {shop && shop.allowCashBook === true && showDatePicker === false && zBon != null ? (
          <div
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: 6,
              height: 32,
              marginLeft: -4,
              marginBottom: 14,
            }}
          >
            <AnimatedTabs
              tabs={[
                { id: 'zbon-tab', label: i18n.t('dailySummaryZbon') },
                { id: 'cashbook-tab', label: i18n.t('dailySummaryCashBook') },
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onChangeTab={() => {
                if (document.getElementById('cashInventorySection')) {
                  document
                    .getElementById('cashInventorySection')
                    .scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
                if (document.getElementById('general')) {
                  document
                    .getElementById('general')
                    .scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <Modal.Content
          scrolling
          style={{
            paddingLeft: showDatePicker ? 10 : 20,
            paddingRight: showDatePicker ? 10 : 20,
            paddingBottom: showDatePicker ? 10 : 20,
            marginTop: 10,
            paddingTop: 0,
            backgroundColor: '#f5f5f9',
            scrollbarWidth: 'none',
          }}
          className="no-scrollbar"
        >
          {showDatePicker ? (
            <>
              <div
                style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
              >
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('begin')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', width: 100 }}>
                      <ReactDatePicker
                        className="day-picker-zbon"
                        locale="de"
                        disabledKeyboardNavigation
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        selected={date}
                        readOnly
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        placeholder="--"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setZBonDate({ ...zBonDate, startTime: e.target.value });
                          }
                        }}
                        value={zBonDate.startTime || ''}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={`0:00${i18n.t('uhrGerman')}`}
                    icon="undo"
                    style={{ marginTop: 20 }}
                    onClick={() => setZBonDate({ ...zBonDate, startTime: '00:00' })}
                  />
                </div>
                <div style={{ width: 1, height: 80, marginTop: 20, backgroundColor: '#bbb' }} />
                <div>
                  <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 700 }}>
                    {i18n.t('end')}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none' }} onClick={() => setEndDatePickerOpen(true)}>
                      <ReactDatePicker
                        className="day-picker-zbon"
                        wrapperClassName="day-picker-wrapper"
                        dateFormat="dd.MM.yyyy"
                        showPopperArrow={false}
                        placeholderText="--"
                        locale="de"
                        minDate={moment(date).startOf('day').toDate()}
                        maxDate={moment(date).startOf('day').add(1, 'days').endOf('day').toDate()}
                        selected={zBonDate.endDate}
                        calendarStartDay={1}
                        onClickOutside={() => setEndDatePickerOpen(false)}
                        readOnly
                        open={endDatePickerOpen === true}
                        onChange={(date) => {
                          setEndDatePickerOpen(false);
                          setZBonDate({ ...zBonDate, endDate: date, endTime: '' });
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <div style={{ border: 'none', marginTop: 10 }}>
                      <input
                        type="time"
                        placeholder="--"
                        className="day-picker-zbon"
                        style={{ width: 130 }}
                        onChange={(e) => {
                          if (e.target.valueAsDate) {
                            setZBonDate({ ...zBonDate, endTime: e.target.value });
                          }
                        }}
                        value={zBonDate.endTime || ''}
                      />
                    </div>
                  </div>
                  <Button
                    color="teal"
                    fluid
                    content={i18n.t('now')}
                    icon="clock"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setZBonDate({
                        ...zBonDate,
                        endDate: moment().toDate(),
                        endTime: moment().format('HH:mm'),
                      });
                    }}
                    disabled={moment().isAfter(moment(date).endOf('day').add(1, 'days'))}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {zBon === null ? (
                <>
                  <Loader
                    inline="centered"
                    size="medium"
                    active={isLoading}
                    className="bookings-create-loader"
                  />
                </>
              ) : (
                <>
                  <div style={{ paddingTop: 0 }}>
                    {selectedTab === tabs.zbon ? parseZBon() : <></>}
                    {selectedTab === tabs.cashbook && withCoins === true ? parseProtocol() : <></>}
                    {selectedTab === tabs.cashbook ? parseCalculation() : <></>}
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: '#f5f5f9' }}>
          {showDatePicker ? (
            <>
              <Button
                content={i18n.t('createZBon')}
                color="teal"
                disabled={
                  moment(zBonDate.endDate).isValid() === false ||
                  zBonDate.endTime == null ||
                  zBonDate.endTime === '' ||
                  zBonDate.startTime == null ||
                  zBonDate.startTime === '' ||
                  isLoading === true
                }
                loading={isLoading === true}
                onClick={async () => {
                  setIsLoading(true);
                  try {
                    const currentTime = moment(date)
                      .startOf('day')
                      .add(moment.duration(zBonDate.startTime));
                    if (
                      lastZBon != null &&
                      lastZBon.issueDateEnd != null &&
                      currentTime.isBefore(moment(lastZBon.issueDateEnd))
                    ) {
                      setErrorWarningModalOptions({
                        open: true,
                        message:
                          i18n.t('zBonStartTimeEndError1') +
                          moment(lastZBon.issueDateEnd).format(
                            `HH:mm[${i18n.t('uhrGerman')}], DD.MM.YYYY`
                          ),
                      });
                      setZBonDate({
                        ...zBonDate,
                        startTime: '',
                      });
                      setIsLoading(false);
                      return;
                    }
                    const start = moment(date)
                      .startOf('day')
                      .add(moment.duration(zBonDate.startTime))
                      .format();
                    const end = moment(zBonDate.endDate)
                      .startOf('day')
                      .add(moment.duration(zBonDate.endTime))
                      .format();
                    const res = await getUnpaidBookings(token, shop._id, start, end);
                    setIsLoading(false);
                    if (res && res.success) {
                      setZBonWarningModalOptions({ open: true, unpaidBookings: res.bookings });
                    } else {
                      setZBonWarningModalOptions({ open: true, unpaidBookings: [] });
                    }
                  } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                    setZBonWarningModalOptions({ open: true, unpaidBookings: [] });
                  }
                }}
              />
            </>
          ) : (
            <>
              {zBon != null ? (
                <>
                  {withCoins === true && selectedTab === tabs.zbon ? (
                    <>
                      <Button
                        floated="right"
                        icon="print"
                        content={i18n.t('dailySummaryZbon')}
                        color="blue"
                        onClick={() => printZBon(false)}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedTab === tabs.zbon ? (
                    <Button
                      floated="left"
                      icon="download"
                      content={
                        withCoins === true ? i18n.t('appPaySuccess7') : i18n.t('dailySummaryZbon')
                      }
                      color="teal"
                      onClick={() => downloadZBon()}
                      disabled={isDownloading}
                      loading={isDownloading}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedTab === tabs.cashbook && withCoins === false ? (
                    <Button
                      floated="left"
                      icon="download"
                      content={
                        withCoins === true ? i18n.t('appPaySuccess7') : i18n.t('dailySummaryZbon')
                      }
                      color="teal"
                      onClick={() => downloadZBon()}
                      disabled={isDownloading}
                      loading={isDownloading}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedTab === tabs.cashbook && withCoins === true ? (
                    <>
                      {isProtocolSaved === false ? (
                        <Button
                          icon="save"
                          floated="left"
                          content={i18n.t('bookingModalEditSave')}
                          color="teal"
                          disabled={isProtocolSaved === true}
                          onClick={() => onSaveProtocol()}
                        />
                      ) : (
                        <Button
                          floated="left"
                          icon="download"
                          content={
                            withCoins === true
                              ? i18n.t('appPaySuccess7')
                              : i18n.t('dailySummaryZbon')
                          }
                          color="teal"
                          onClick={() => downloadZBon()}
                          disabled={isDownloading}
                          loading={isDownloading}
                        />
                      )}
                      <Popup
                        on={['hover', 'click']}
                        content={i18n.t('cashInventoryError8')}
                        basic
                        position="top right"
                        popper={<div style={{ filter: 'none' }} />}
                        disabled={isProtocolSaved === true}
                        trigger={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <div style={{ display: 'inline-block' }}>
                            <Button
                              style={{ borderRadius: 8 }}
                              floated="right"
                              icon="print"
                              content={i18n.t('countingProtocol')}
                              color="blue"
                              disabled={isProtocolSaved === false}
                              onClick={() => {
                                printZBon(true, isUsingBanknotesAndCoins() ? true : false);
                              }}
                            />
                          </div>
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {withCoins === false ? (
                    <>
                      <Button
                        floated="right"
                        icon="download"
                        content={i18n.t('cash')}
                        color="teal"
                        onClick={() => downloadCashPDF()}
                        disabled={isDownloading}
                        loading={isDownloading}
                      />
                      <Button
                        floated="right"
                        icon="download"
                        content={i18n.t('card')}
                        color="teal"
                        onClick={() => downloadCardPDF()}
                        disabled={isDownloading}
                        loading={isDownloading}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={
          errorWarningModalOptions.title
            ? errorWarningModalOptions.title
            : i18n.t('newPrinterErrorTitle')
        }
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <AnimatedModal dimmer="blurring" size="mini" open={zBonWarningModalOptions.open}>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <p>
            {zBonWarningModalOptions.unpaidBookings &&
            zBonWarningModalOptions.unpaidBookings.length > 0
              ? i18n.t('newPrinterErrorTitle')
              : i18n.t('warning')}
          </p>
        </Modal.Header>
        <Modal.Content style={{ paddingTop: 0 }}>
          {zBonWarningModalOptions.unpaidBookings.length > 0 ? (
            <>
              <p style={{ color: 'black', fontSize: 16, fontWeight: 600 }}>
                {i18n.t('zBonWarning1') +
                  zBonWarningModalOptions.unpaidBookings.length +
                  i18n.t('zBonWarning2')}
              </p>
            </>
          ) : (
            <>
              <p style={{ fontWeight: 600 }}>{i18n.t('zBonSaveWarning1')}</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginBottom: 35,
                  marginTop: 30,
                }}
              >
                <div>
                  <p style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}>
                    {i18n.t('begin')}
                  </p>
                  <p style={{ textAlign: 'center', fontWeight: 700, fontSize: 18 }}>
                    {moment(date).format('DD.MM.YYYY')}
                  </p>
                  <p style={{ textAlign: 'center', fontWeight: 700, fontSize: 18 }}>
                    {moment(date)
                      .startOf('day')
                      .add(moment.duration(zBonDate.startTime))
                      .format(`HH:mm[${i18n.t('uhrGerman')}]`)}
                  </p>
                </div>
                <div
                  style={{ width: 1, backgroundColor: '#7268cd', marginTop: 10, marginBottom: 10 }}
                />
                <div>
                  <p style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}>
                    {i18n.t('end')}
                  </p>
                  <p style={{ textAlign: 'center', fontWeight: 700, fontSize: 18 }}>
                    {moment(zBonDate.endDate).startOf('day').format('DD.MM.YYYY')}
                  </p>
                  <p style={{ textAlign: 'center', fontWeight: 700, fontSize: 18 }}>
                    {moment(zBonDate.endDate)
                      .startOf('day')
                      .add(moment.duration(zBonDate.endTime))
                      .format(`HH:mm[${i18n.t('uhrGerman')}]`)}
                  </p>
                </div>
              </div>
              <p style={{ fontWeight: 600 }}>{i18n.t('zBonSaveWarning4')}</p>
              <p style={{ fontWeight: 600 }}>{i18n.t('zBonSaveWarning2')}</p>
            </>
          )}
        </Modal.Content>
        <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
          {zBonWarningModalOptions.unpaidBookings.length > 0 ? (
            <>
              <Button
                content="OK"
                color="teal"
                floated="right"
                onClick={() => {
                  setZBonWarningModalOptions({ open: false, unpaidBookings: [] });
                  closeModal();
                }}
              />
            </>
          ) : (
            <>
              <Button
                content={i18n.t('itemsDeleteWarningYes')}
                color="teal"
                style={{ paddingLeft: 34, paddingRight: 34 }}
                floated="right"
                onClick={async () => {
                  if (zBonWarningModalOptions.unpaidBookings.length > 0) {
                    setWarningModalOptions({
                      open: true,
                      title: i18n.t('warning'),
                      message: i18n.t('zBonSaveWarning3'),
                      yes: true,
                      onYes: async () => {
                        setZBonWarningModalOptions({ open: false, unpaidBookings: [] });
                        openCashDrawer();
                        await loadData();
                      },
                      no: true,
                      onNo: () => {
                        closeWarningModal();
                        setZBonWarningModalOptions({ open: false, unpaidBookings: [] });
                        closeModal();
                      },
                    });
                  } else {
                    setZBonWarningModalOptions({ open: false, unpaidBookings: [] });
                    openCashDrawer();
                    await loadData();
                  }
                }}
              />
              <Button
                content={i18n.t('appPayCancel')}
                color="red"
                floated="left"
                onClick={() => setZBonWarningModalOptions({ open: false, unpaidBookings: [] })}
              />
            </>
          )}
        </Modal.Actions>
      </AnimatedModal>
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
        amount={warningModalOptions.amount}
      />
    </>
  );
};

export default ZBonScreenModal;
