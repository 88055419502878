import React from 'react';
import { Modal } from 'semantic-ui-react';
import './AnimatedModal.css';

const AnimatedModal = ({ open, children, ...props }) => (
  <Modal
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    open={open}
    className={`${open ? 'modal-fade-in' : 'modal-fade-out'} ${
      props.className || ''
    } animated-modal-general`}
  >
    {children}
  </Modal>
);

export default AnimatedModal;
